<template>
  <div>
    <div class="d-flex justify-space-around">
      <div class="contentWindow">
        <v-window v-model="invitedStepper">
          <v-window-item :value="0">
            <WellComeSectionInvited @invitedStepperSwitcher="invitedStepperSwitcher($event)"/>
          </v-window-item>
          <v-window-item :value="1">
            <RegisterAndGetSessionForMe title="حتما با شماره تلفن خارج از کشور ثبت نام کنید تا هدیه دلاری شما فعال شود"/>
          </v-window-item>
        </v-window>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterAndGetSessionForMe from "@/components/campaign/referal-campaign/forMeSection/registerAndGetSessionForMe";
import WellComeSectionInvited from "@/components/campaign/referal-campaign/invitedSection/wellComeSectionInvited";

export default {
  name: "invitedReferralView",
  components: {WellComeSectionInvited, RegisterAndGetSessionForMe},
  data() {
    return {
      invitedStepper: 0,
    }
  },
  methods: {
    invitedStepperSwitcher(val) {
      this.invitedStepper = val
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px)  and (max-width: 600px){
  .contentWindow{
    width: 100% !important;
  }
}
.contentWindow{
  width: 500px;
  overflow: hidden
}
</style>