<template class="fill-height">
  <div class="fill-height">
    <HeaderLogoArea/>
    <div class="responceArea " :class="classSubmit">
      <div class="d-flex justify-center flex-column fill-height">
        <div class="text-center">
          <img src="@/assets/zeroPayment/logoGreen.png" width="150px" alt="">
        </div>
        <div class="vectorArea">
          <div class="d-flex fill-height justify-space-around flex-column text-center">
            <img src="../../../../assets/zeroPayment/handGetEmail.png" width="100%" alt="">
          </div>
        </div>
        <p class="text-center textArea">.اگر خودت هم قصد شروع دوره مشاوره داری.
          <br>
          برای دریافت هدیه ایمیلت را وارد کن</p>
        <div class="d-flex justify-space-around">
          <div class="inputArea">
            <v-form ref="emailForm">
              <v-text-field v-model="email" :rules="emailRules" reverse style="direction: ltr;" outlined dense  placeholder="Email : "></v-text-field>
            </v-form>
          </div>
        </div>
        <div class="d-flex justify-space-around">
          <v-btn elevation="0" class="saveEmail" @click="saveEmail" :loading="loading">
            تایید و دریافت هدیه
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import HeaderLogoArea from "@/components/campaign/referal-campaign/headerLogoArea";
import {ajaxPost} from "@/utilities";

export default {
  name: "getEmailAfterInviteFriend",
  components: {HeaderLogoArea},
  data(){
    return{
      email:null,
      loading:false,
      classSubmit: null,
    }
  },
  computed: {
    emailRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ];
    }
  },
  methods: {
    redirectToSharePage() {
      EventBus.$emit('numberFromIran')
    },
    saveEmail(){
      if (this.$refs.emailForm.validate()) {
        this.loading = true;
        ajaxPost('https://api.simiaroom.com/api/survey', {
          email: this.email,
          from: "referralCampaign",
        }).then(() => {
          this.sendWebEngageClientData('referralCampaign',{
            email: this.email,
          })
          EventBus.$emit('notify', 'green', 3000, 'ایمیل شما ذخیره شد');
          this.classSubmit = 'submitEmail'
          setTimeout(() => {
              window.location.replace('http://simiaroom.com/')
          },3000)
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    sendWebEngageClientData(eventName, data) {
      window.webengage.track(eventName, data);
    },
  },

}
</script>

<style scoped>
.responceArea {
  /*height: 85vh;*/
  margin-top: 50px;
}

.vectorArea {
  height: 300px;
  background: url("../../../../assets/zeroPayment/pattern.png");
  background-size: cover;
  background-position: center;
}

.textArea {
  font-size: 12px;
  font-weight: 100;
}

.sendToOther {
  width: 180px !important;
  height: 31px !important;
  border-radius: 6px !important;
  background-color: #39b787 !important;
  font-size: 13px !important;
  text-align: center !important;
  color: white !important;
}
.inputArea{
  border-radius: 6px;
  width: 250px !important;
  padding: 0px;
}
.v-text-field--outlined >>> fieldset {
  border: solid 1px #707070 !important;
  border-radius: 6px !important;
}
.saveEmail{
  width: 250px !important;
  height: 31px !important;
  border-radius: 6px !important;
  background-color: #39b787 !important;
  font-size: 12px !important;
  text-align: center ;
  padding-top: 7px ;
  color: white !important;
}
</style>
