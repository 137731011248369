<template>
  <div>
    <template>
      <v-window v-model="inviteFriendStepper" touchless>
        <v-window-item :value="0">
            <LandingInviteFriend @stepSwitcher="stepSwitcher($event)" v-if="inviteFriendStepper === 0"/>
        </v-window-item>
        <v-window-item :value="1">
          <ShareLinkInvitedFriend @stepSwitcher="stepSwitcher($event)"/>
        </v-window-item>
        <v-window-item :value="2">
          <GetEmailAfterInviteFriend/>
        </v-window-item>
      </v-window>
    </template>
  </div>
</template>

<script>
// import GetEmail from "@/components/campaign/referal-campaign/inviteFriendSection/getEmail";
import LandingInviteFriend from "@/components/campaign/referal-campaign/inviteFriendSection/landingInviteFriend";
import ShareLinkInvitedFriend from "@/components/campaign/referal-campaign/invitedSection/shareLinkInvitedFriend";
import GetEmailAfterInviteFriend
  from "@/components/campaign/referal-campaign/inviteFriendSection/getEmailAfterInviteFriend";

export default {
  components: {GetEmailAfterInviteFriend, ShareLinkInvitedFriend, LandingInviteFriend},
  data() {
    return {
      inviteFriendStepper: null,
    }
  },
  mounted() {
    this.inviteFriendStepper = 0;
  },
  methods: {
    stepSwitcher(val) {
      this.inviteFriendStepper = val
    }
  }
}
</script>

<style scoped>

</style>