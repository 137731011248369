<template>

  <div class="preorder-modal d-flex">
    <div id="preorder-modal-container" :style="{'overflow-x': largeSize?'hidden' : 'inherit'}">
      <v-row class="d-flex" style="flex-direction:row">
        <v-col class=" flex-grow-1 smooth-tr center-section d-flex flex-shrink-0" :cols="columnSize"
               :style="`width:${columnWidth}px!important;`">
          <v-card class="login-register text-center  pa-0 ma-0 d-flex justify-center align-center align-content-center"
                  elevation="0">
            <!--            <template v-if="type=='merged'">-->
            <!--              <Temp></Temp>-->
            <!--            </template>-->
            <template>
              <v-window v-model="window" :reverse="this.$store.getters.isRTL" class="flex-grow-1">
                <v-window-item :value="1">
                  <v-card-text class=" mt-8 justify-center">
                    <v-img class="mx-auto" :src="require('@/assets/simialogovertical.png')" height="63" width="110"></v-img>
                  </v-card-text>
                  <LoginComponent @changeWindow="updateWindow"
                                  :GALogin="'ga_login_new_preorder'"
                                  preorderLogin="true"
                                  :subtitle="$t('$vuetify.loginComponent.subTitle')"></LoginComponent>
                </v-window-item>
                <v-window-item :value="2">

                  <SignupComponent @changeWindow="updateWindow"
                                   :GASignup="'ga_signup_new_preorder'"
                                   preorderLogin="true"
                                   @userLoggedInAfterRegister="userLoggedIn"></SignupComponent>

                </v-window-item>
              </v-window>
            </template>

          </v-card>
        </v-col>
        <v-col class="pa-0 ma-0 flex-shrink-0 flex-grow-1 smooth-tr preorder-info-section d-flex "
               :cols="largeSize?6:12"
               :style="`width:${columnWidth}px!important;flex-direction:column;`">

          <div class="flex-shrink-0" v-if="largeSize">
            <div class="mx-auto text-center ">
              <v-img
                  id="preorder-modal-info-image"
                  :src="require('../assets/images/best-soroosh.jpg')"
              ></v-img>
            </div>
          </div>
          <div class="align-center flex-grow-1 align-content-center justify-center d-flex">
            <div :style="{'margin-top': largeSize?'-60px':'0' }" class="flex-grow-1">
              <h2 class="white--text pa-4 mt-4 mr-2">{{$t('$vuetify.loginRegisterPage.title')}}</h2>
              <ul class="preorder-modal-info-section pa-4 pt-1 ">
              <li class="white--text py-1 my-1 body-1 " style="font-family: IRANSans !important;" :class="checkLang == 'fa' ? 'mr-4' : 'ml-4'">{{$t('$vuetify.loginRegisterPage.desc[0]')}}</li>
              <li class="white--text py-1 my-1 body-1 " style="font-family: IRANSans !important;" :class="checkLang == 'fa' ? 'mr-4' : 'ml-4'" >{{$t('$vuetify.loginRegisterPage.desc[1]')}}</li>
              <li class="white--text py-1 my-1 body-1 " style="font-family: IRANSans !important;" :class="checkLang == 'fa' ? 'mr-4' : 'ml-4'"  v-if="isDollarCurrency">{{$t('$vuetify.loginRegisterPage.desc[2]')}}</li>

              </ul>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div id="preorder-modal-background"></div>
  </div>
</template>

<script>
/* eslint-disable */
// import LoginComponent from "./LoginComponent";
// import SignupComponent from "./SignupComponent";
import LoginComponent from '@/components/LoginComponentHome.vue'
import SignupComponent from '@/components/SignupComponentHome.vue'

import {mapGetters} from 'vuex';
import {EventBus} from "@/event-bus";
import Temp from '@/views/Temp'

export default {
  created() {

    window.addEventListener('resize', this.calculateColumnSize)
  },
  mounted() {

    if (this.$route.query.method != undefined) {
      this.window = 1;
    } else {
      this.window = this.defaultWindow;
    }
    this.calculateColumnSize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateColumnSize)
  },
  methods: {
    userLoggedIn(newUser) {
      let user = {
        email: newUser.email,
        password: newUser.password
      };

      this.$store.dispatch("login", user).then(() => {
        this.$store.dispatch('getUserInfo').then(() => {
          this.$store.commit('setLoggedIn');
          EventBus.$emit('userLoggedIn');
        })
      })

    },
    calculateColumnSize() {
      this.innerWidth = window.innerWidth;
    },
    updateWindow(variable) {
      this.window = variable
    }
  },
  props: {
    type: {
      default: 'merged'
    },
    show: {
      default: true
    },
    defaultWindow: {
      default: 1
    }
  },
  components: {
    LoginComponent,
    SignupComponent,
    Temp
  },
  data() {
    return {
      innerWidth: 500,
      dialog: true,
      window: 2,
    }
  },
  computed: {

    isDollarCurrency() {
      return this.currency == 'dollar';
    },
    discountText() {
      return this.isDollarCurrency ? '5 دلاری' : '50,000 تومانی';
    },
    largeSize() {
      return this.innerWidth > 600;
    },
    columnSize() {
      return this.largeSize ? 6 : 12;
    },
    columnWidth() {

      let ratio = .2;
      if (this.innerWidth < 800) {
        ratio = .1;
      } else {
        ratio = .25;
      }

      return (this.innerWidth - ratio * this.innerWidth) * (this.columnSize / 12);
    },
    ...mapGetters({
      'loading': 'loading',
      'loggedIn': 'userIsLoggedIn',
      'platform': 'getPlatform',
      'currency': 'getUserCurrency',
      'snackbarId': 'getSnackbarId'
    }),
    checkLang(){
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    }
  }
}


</script>

<style scoped>
.preorder-modal {

  width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 0;
  background-color: rgb(255, 255, 255);
  /*background-color: rgba(0,0,0,.3);*/
  /*overflow: hidden !important;*/
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  z-index: 2;


}


#preorder-modal-container {
  border-radius: 12px;
  box-shadow: 0 5px 30px -10px rgba(0, 200, 0, .3);
  border: 1px solid rgba(240, 240, 240, 1);
  /*overflow: hidden;*/
  min-height: 90vh;
  background-color: white;
}

.preorder-modal-info-section {
  margin-right: 10px;
}

.preorder-info-section {
  border-right: 1px solid rgba(240, 240, 240, .5);

  /*background-image: linear-gradient(180deg, white 40%, rgba(200, 200, 200, 1) 100%);*/
  background-color: rgb(0, 70, 70);
  /*background-image: rgb(0,0,0,.);*/

  color: black;
  font-weight: lighter;
  font-size: .9em;
}

#preorder-modal-info-image {
  border-top-left-radius: 5px;
  /*margin-top:50px;*/
  /*box-shadow: 0 10px 10px -10px rgba(0, 0, 0, .3);*/

}

.center-section {
  min-height: 89vh !important;
  /*align-items: center;*/
}

.login-register {
  flex-grow: 1;
}

#preorder-modal-background {
  /*background-image:  url('../assets/images/index.png');*/
  background-image: linear-gradient(75deg, rgba(0, 200, 200, .1), rgba(0, 200, 0, .1)), url('../assets/images/index.png');
  /*background-image: linear-gradient(0deg, #1d242d,#1d242d);*/
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: .1;
}


</style>