<template>
  <v-container>
    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
    <v-card class="my-4 elevation-25">
      <CounselorProfileComponent></CounselorProfileComponent>
      <v-card-actions class="mt-5 pa-5 text-left" justify="left">
        <v-spacer></v-spacer>
        <v-btn style="float: left" rounded class="warning px-10" @click="saveProfile">
            {{$t('$vuetify.buttons.submit') }}
        </v-btn>
        <!--                        <v-btn v-show="this.$router.currentRoute.name == 'profile'" class="grey lighten-2 px-10" @click="resetProfile">Reset</v-btn>-->
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import CounselorProfileComponent from '@/components/profiles/components/CounselorProfileComponent.vue';
import {EventBus} from "@/event-bus";

export default {
  data(){
    return {
      items: [
        {
          text: this.$t('$vuetify.dashboard'),
          disabled: false,
          to: `${this.$vuetify.rtl ? "fa" : "en"}/fa/panel/dashboard`,
        },
        {
          text: this.$t('$vuetify.ClientNavItem.profile'),
          disabled: true,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/clients`,
        }
      ]
    }
  },
  components: {
    CounselorProfileComponent
  },
  methods: {
    saveProfile() {
      EventBus.$emit('saveProfileEvent');
    },
    resetProfile() {
      EventBus.$emit('resetProfileEvent');
    }
  }


}
</script>

<style scoped>

</style>
