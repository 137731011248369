<template>
  <v-window v-model="window" :reverse="this.$store.getters.isRTL">
    <v-window-item :value="1" style="">
      <v-card-title class="justify-center" v-if="!preorderLogin">
        <span>
          {{ $t('vuetify.loginComponent.title') }}
        </span>
      </v-card-title>
      <v-card-subtitle>
        {{ subtitle }}
      </v-card-subtitle>
      <v-card-text>
        <v-form class="pa-0" ref="loginForm" @keyup.native.enter="login">
          <v-text-field
              v-model="mutableEmail"
              type="text"
              name="input-10-1"
              :label="$t('$vuetify.loginComponent.form[0]')"
              required
              :rules="emailRules"
              outlined
              class="mb-0"
              append-icon="fa fa-user"
          >
          </v-text-field>
          <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :label="$t('$vuetify.loginComponent.form[1]')"
              class="input-group--focused mb-0"
              @click:append="showPassword = !showPassword"
              required
              :rules="passwordRules"
              outlined
          >
          </v-text-field>
          <div v-if="preorderLogin" style="width: 50%; text-align: center;margin: 0 auto;">
            <v-btn color="red" class="white--text " height="40" @click="login"
                   :disabled="loginDisabled"
                   block
                   style="width: 50%;!important;"
                   :loading="loginDisabled">
              <span style="font-size: 1.3em;">{{ $vuetify.lang.t('$vuetify.loginLabel') }}</span>
            </v-btn>
          </div>

          <v-btn color="primary" rounded @click="login" :disabled="loginDisabled" block :loading="loginDisabled" v-else>
            <span style="font-size: 1.5em;">{{ $vuetify.lang.t('$vuetify.loginLabel') }}</span>
          </v-btn>
        </v-form>
        <v-card-actions class="pt-0 mt-0 mt-10">
          <v-row no-gutters justify="center" align="center">
            <v-col cols="12" sm="6" class="mt-4">
              <span>
                {{ $t('$vuetify.loginComponent.registerLabel') }}
              </span>
              <a class="red--text" @click="verifiedCampagin === true ? changeParentWindow(1) : changeParentWindow(2)">
                {{ $t('$vuetify.loginComponent.registerAction') }}
              </a>
            </v-col>
            <v-col cols="12" sm="6" class="mt-4 mt-0">
              <a class="grey--text text--darken-1 mt-3" @click="window=2">
                {{ $t('$vuetify.loginComponent.resetPassword') }}
              </a>
            </v-col>

          </v-row>
        </v-card-actions>
      </v-card-text>
      <v-card-text class="text-center pt-0 pb-4">

      </v-card-text>
    </v-window-item>
    <v-window-item :value="2">
      <v-card-title class="justify-center">
        <span>{{ $vuetify.lang.t('$vuetify.accountRecovery') }}</span>
      </v-card-title>
      <v-card-subtitle class="justify-center">
        {{ $vuetify.lang.t('$vuetify.accountRecoveryCaption') }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="resetPassword">
          <v-text-field
              @keydown.enter.prevent='resetPassword'
              v-model="emailForResetPassword"
              type="text"
              :label="$vuetify.lang.t('$vuetify.emailLabel')"
              :rules="emailRules"
              @submit.stop="resetPassword"
              outlined
              append-icon="fa fa-user"
          >
          </v-text-field>
          <v-card-text v-show="checkEmail" class="green--text text-center">
            {{ $t('$vuetify.loginComponent.checkEmail') }}
          </v-card-text>
          <v-btn color="primary" rounded @click="resetPassword" :disabled="loginDisabled" block>
            <span>
                          {{ $t('$vuetify.loginComponent.forgetPassword.action') }}
            </span>
          </v-btn>
          <v-card-text class="justify-center mt-4">
            {{ $t('$vuetify.loginComponent.forgetPassword.loginLabel') }}
            <a @click="window=1">
              {{ $t('$vuetify.loginComponent.forgetPassword.loginAction') }}
            </a>
          </v-card-text>
        </v-form>
      </v-card-text>
    </v-window-item>
  </v-window>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      showPassword: false,
      // email: "",
      mutableEmail: '',
      emailForResetPassword: '',
      checkEmail: false,
      // emailRules: [],
      password: "",
      errorMessages: '',
      name: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      formHasErrors: false,
      window: 1,
      loginErr: {
        show: false,
        msg: '',
      },
      loginDisabled: false,
      registerDisabled: false,

    }
  },
  props: {
    GALogin: {
      default: 'ga_login'
    },
    preorderLogin: {
      default: false,
    },
    subtitle: {
      default: 'برای استفاده از پنل کاربری لطفا وارد شوید'
    },
    verifiedCampagin: {
      default: false,
    },
    email: String
  },
  watch: {
    name() {
      this.errorMessages = ''
    },
  },
  computed: {
    passwordRules() {
      return [
        v => !!v || this.$t('$vuetify.Notify.login.password.passwordRequired'),
        v => v.length >= 8 || this.$t('$vuetify.Notify.login.password.minLength')
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ];
    }
  },
  created() {
    this.mutableEmail = this.email;
  },
  mounted() {
  },
  methods: {
    addressCheck() {
      this.errorMessages = this.address && !this.name
          ? 'Hey! I\'m required'
          : ''

      return true
    },
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },
    resetPassword() {
      if (this.$refs.resetPassword.validate()) {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('resetPassword', {email: this.emailForResetPassword}).then((data) => {

          if (data.type === 'SUCCESS') {
            this.checkEmail = true;
          } else {
            this.checkEmail = false;
          }
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      }
    },
    login() {
      const user = {
        email: this.mutableEmail,
        password: this.password
      };

      if (this.$refs.loginForm.validate()) {
        this.loginDisabled = true;
        EventBus.$emit('setLoading', true);
        this.$store.dispatch("login", user).then((resp) => {
          this.$store.dispatch('getUserInfo').then(() => {
            //WebEngage
            window.webengage.user.login(this.$store.getters.getUserInfo.id);
            this.$store.commit('setLoggedIn');
            EventBus.$emit('userLoggedIn');
            let notRedirect = ['preorder', 'expressSession', 'xpreorder', 'mergePreorder', 'ypreorder', 'campaignVerified','Langxpreorder','Langypreorder','Langonboarding'];

            EventBus.$emit('notify', 'green', 3000, this.$t('$vuetify.Notify.loginSuccess'), this.GALogin);
            if (this.$store.getters.getUserInfo.role === 'admin') {
              this.$store.dispatch('getAllDoctors');
            }
            if (!notRedirect.includes(this.$router.currentRoute.name)) this.$router.push({name: 'dashboard'});
          }).finally(() => {
            this.loginDisabled = false;
          })
        }).catch((err) => {
          this.loginDisabled = false;
          // console.log('err', err.response.data);
          // if (err.response.data.statusCode === 401 || err.response.data.status === 400) {
          //     this.loginErr = {
          //         show: true,
          //         msg: 'نام کاربری یا کلمه عبور اشتباه است',
          //     }
          // } else if (500 <= err.response.data.statusCode < 600) {
          //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
          // }
        }).finally(() => {
          EventBus.$emit('setLoading', false);

        });
      }
    },
    changeParentWindow(value) {
      this.$emit('changeWindow', value);
    }
  }
}
</script>

<style scoped>

</style>