/*eslint-disable*/
import Vue from 'vue'
import Vuex from 'vuex'
import {ajaxPost, ajaxGet} from '../../utilities.js';
import {EventBus} from "@/event-bus";
import Axios from "axios";

Vue.use(Vuex);

const state = {
    counselorDashboard: {},
    counselorSessions: [],
    counselorClients: [],
    startSession: {},
    clientStartSession: {}
};
const mutations = {
    setCounselorDashboard(state, data) {
        state.counselorDashboard = data;
        localStorage.setItem('counselorDashboard', data);
    },
    setCounselorSessions(state, sessions) {
        state.counselorSessions = [];
        sessions.forEach(session => {
            state.counselorSessions.push(session);
            session['date'] = session.jdatetime.split(' ')[0];
            session['time'] = session.jdatetime.split(' ')[1];
            session['period'] = '45 دقیقه';
        });
    },
    setCounselorClients(state, clients) {
        state.counselorClients = [];
        clients.forEach(client => {
            state.counselorClients.push(client);
            client['fullname'] = client.firstname + ' ' + client.lastname;
        });
    },
    setStartSession(state, startSession) {
        state.startSession = {...startSession}
    },
    setClientStartSession(state, client) {
        state.clientStartSession = {...client}
    },

};
const actions = {
    counselorGetContacts({rootState}) {
        return ajaxGet(rootState.baseUrl + '/doctor/contacts').then((resp) => {
            return resp;
        })
    },

    counselorGetConversationMessages({rootState,commit}, {id, page}) {
        return ajaxGet(rootState.baseUrl + `/doctor/conversations/${id}/getbyuser?page=${page}`).then((resp) => {
            commit('UPDATE_NEW_MESSAGES', resp.data.newMessages);
            return resp;
        })
    },

    counselorSendConversationMessage({rootState}, {id, payload}) {
        return ajaxPost(rootState.baseUrl + `/doctor/conversations/${id}/messages`, payload).then((resp) => {
            return resp;
        })
    },
    counselorGetResume({commit, state, rootState}) {
        return ajaxGet(rootState.baseUrl + "/doctor/resume", true).then((data) => {
            return data;
        })
    },
    counselorAddToResume({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/doctor/resume", payload).then((data) => {
            return data;
        })
    },
    counselorEditResumeItem({commit, state, rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/doctor/resume/" + data.id, data.payload).then((data) => {
            return data;
        })
    },
    counselorRemoveFromResume({commit, state, rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/doctor/resume/${payload}/delete`).then((data) => {
            return data;
        })
    },
    getCounselorDashboard: ({commit, state, rootState}) => {
        // return new Promise((resolve, reject) => {
        //     ajaxGet(rootState.baseUrl + "/counselor/homepage", true).then((data) => {
        //         console.log('counselor homepage : ', data );
        //         commit('setCounselorDashboard', data.data);
        //         resolve(data);
        //     })
        // })

        return ajaxGet(rootState.baseUrl + "/doctor/dashboard", true).then((data) => {
            return data;
        })
    },
    getCounselorClients: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + "/doctor/clients?page=" + data.page, true).then((data) => {
            return data;
        })
    },
    getCounselorClientSessions: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + `/doctor/clients/${data.clientId}/sessions?page=${data.page}`).then((data) => {
            return data;
        })
    },
    getCounselorSessions: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/doctor/sessions?page=" + data.page, data.options).then((data) => {
            return data;
        })
    },
    counselorPostConsultInfo: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/doctor/times", data).then((data) => {
            return data;
        })
    },
    counselorRemoveTimes: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/doctor/times/delete", data).then((data) => {
            return data;
        })
    },

    saveCounselorProfile: ({commit, state, rootState}, data) => {

        return ajaxPost(rootState.baseUrl + "/doctor/profile", data, true).then((data) => {
            return data;
        })
    },
    doctorJoinSession: ({commit, state, rootState}, sessionId) => {

        return ajaxGet(rootState.baseUrl + `/doctor/sessions/${sessionId}/enter`).then((data) => {
            return data;
        })
    },
    getStartSession: ({commit, state, rootState}, id) => {
        return new Promise((resolve, reject) => {
            ajaxGet(rootState.baseUrl + "/doctor/sessions/" + `${id}`, true).then(data => {
                resolve(data);
            })
        })
    },
    getClientInfo({commit, state, rootState}, id) {

        return ajaxGet(rootState.baseUrl + "/doctor/clients/" + `${id}`, true).then(data => {
            return (data);
        })
    },

    getClientStartSession: ({commit, state, rootState}, id) => {
        commit('setClientStartSession', {});
        return new Promise((resolve, reject) => {
            ajaxGet(rootState.baseUrl + "/counselor/users/getProfile/" + `${id}`, true).then(data => {
                console.log('CLIENT START SESSION: ', data.data);
                commit('setClientStartSession', data.data);
                resolve(data);
            })
        })
    }
};
const getters = {
    getCounselorDashboard: state => state.counselorDashboard,
    getCounselorSessions: state => state.counselorSessions,
    getCounselorClients: state => state.counselorClients,
    getStartSession: state => state.startSession,
    getClientStartSession: state => state.clientStartSession
};
export default {
    state, getters, mutations, actions
}