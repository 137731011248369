/*eslint-disable*/
import Vue from 'vue'
import Vuex from 'vuex'
import {ajaxPost, ajaxGet, ajaxDownload} from '../../utilities.js';
import LS from '../../LocalStorateAPI.js';
import {EventBus} from "@/event-bus";
import Axios from "axios";

Vue.use(Vuex);

const state = {
    cartType: null,
    activeCycle: JSON.parse(localStorage.getItem('activeCycle')),
    orders: [],
    productList: JSON.parse(localStorage.getItem('productList')),
    cartItems: [],
    cart: null,
    sumPrice: 0,
    sessions: [],
    nextSession: {},
    limit: 10,
    page: 1,
    totalPages: 0,
    payableFactor: null,
    tempMobile:null,
};
const mutations = {
    SET_PAYABLE_FACTOR(state, factor) {
        state.payableFactor = factor;
    },
    // setCartType(state, type) {
    //     state.cartType = type;
    // },
    setCart(state, data) {
        state.cart = data;
    },
    setActiveCycle(state, cycle) {
        if (cycle != null) {
            Axios.defaults.headers["X-USER-CYCLE"] = cycle._id;
            LS.set('activeCycle', cycle);
            state.activeCycle = cycle;
        } else {
            localStorage.removeItem('activeCycle');
            state.activeCycle = null;
        }
    },
    // setCycleById(state, cycleId) {
    //     Axios.defaults.headers["X-USER-CYCLE"] = cycleId;
    // },
    // setClientOrders(state, orders) {
    //     state.orders = [];
    //     orders.forEach(order => {
    //         state.orders.push(order);
    //     });
    // },
    // setProductList(state, productList) {
    //     state.productList = productList;
    //     LS.set('productList', productList);
    // },
    // addItemToCart: (state, item) => {
    //     for (let i = 0; i < state.cartItems.length; i++) {
    //         if (state.cartItems[i].title === item.title) {
    //             state.cartItems[i].qty++;
    //             return;
    //         }
    //     }
    //     state.cartItems.push({...item, qty: 1});
    // },
    increaseQuantity: (state, item) => {
        item.qty++;
    },
    decreaseQuantity: (state, item) => {
        if (item.qty > 0) {
            item.qty--;
        }
    },
    removeItem: (state, item) => {
        let cart = state.cartItems;
        cart.splice([cart.indexOf(item)], 1);
        if (cart.length == 0) {
            state.cartType = null;
        }
    },
    // emptyCart: (state) => {
    //     state.cartItems = [];
    //     state.cartType = null;
    // },
    calculateSum: (state, items) => {
        let sum = 0;
        // items.forEach(item => {
        //     sum += item.price * item.qty;
        // });
        state.sumPrice = sum;
    },
    // -----------------------------------------sessions----------------------------------------- //
    'SET_SESSIONS'(state, sessions) {
        state.sessions = [];
        sessions.forEach(session => {
            state.sessions.push(session);
        });
    },
    'SET_NEXT_SESSION'(state, obj) {
        state.nextSession = obj
    },
    'EMPTY_SESSIONS'(state) {
        state.sessions = [];
    },
    'SET_PAGINATE'(state, paginate) {
        state.totalPages = paginate.totalPages;
        state.limit = paginate.limit;
        console.log('success')
    },
    'SET_NEXT_PAGE'(state) {
        state.page += 1;
        console.log(state.page);
    },
    'SET_PREVIOUS_PAGE'(state) {
        state.page -= 1;
    },
    'SET_SPECIFIC_PAGE'(state, page) {
        state.page = page;
    },
    SET_TEMP_MOBILE(state,payload){
        state.tempMobile = payload
    }
};

const actions = {

    userGetContacts({rootState}) {
        return ajaxGet(rootState.baseUrl + '/user/contacts').then((resp) => {
            return resp;
        })
    },

    userGetConversationMessages({rootState, commit}, {id, page}) {
        return ajaxGet(rootState.baseUrl + `/user/conversations/${id}/getbyuser?page=${page}`).then((resp) => {
            commit('UPDATE_NEW_MESSAGES', resp.data.newMessages);
            return resp;
        })
    },

    userSendConversationMessage({rootState}, {id, payload}) {
        return ajaxPost(rootState.baseUrl + `/user/conversations/${id}/messages`, payload).then((resp) => {
            return resp;
        })
    },

    joinSession({rootState}, {code}) {
        return ajaxGet(rootState.baseUrl + `/user/joinsession/${code}`).then((data) => {
            return data;
        })
    },
    getClientFactors({rootState}, {page}) {
        return ajaxGet(rootState.baseUrl + `/user/factors?page=${page}`).then((data) => {
            return data;
        })
    },
    userGetOfflinePaymentMethods({commit, state, rootState}) {
        return ajaxPost(rootState.baseUrl + "/user/paymentmethods/getmethods", {
            paymentType: 'offline'
        }).then((data) => {
            return (data);
        })
    },
    getFactor({commit, state, rootState}, id) {
        return ajaxDownload(rootState.baseUrl + `/user/sessions/${id}/factor`, 'pdf').then((data) => {
            return (data);
        })
    },
    getAllFactors({commit, state, rootState}) {
        return ajaxDownload(rootState.baseUrl + `/user/sessions/allfactors`, 'pdf').then((data) => {
            return (data);
        })
    },
    saveOpenCartOfflineOrder({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/user/saveofflineorder", payload).then((data) => {
            return (data);
        })
    },
    saveFactorOfflineOrder({commit, state, rootState}, {factorId, payload}) {
        return ajaxPost(rootState.baseUrl + `/user/factors/${factorId}/addofflinepayment`, payload).then((data) => {
            return (data);
        })
    },
    saveInfoWithReferer({state}, data) {
        return ajaxPost("https://logger.simiaroom.com/api/v1/save", data, false).then(data => {
            return Promise.resolve(data);
        })
    },
    setPassword({commit, state, rootState}, data) {
        return ajaxPost(rootState.baseUrl + `/changepassword`, data).then((resp) => {
            return (resp);
        })
    },
    getFinancialList({commit, state, rootState}, data) {
        // return new Promise((resolve, reject) => {
        //     ajaxGet(rootState.baseUrl + "/users/order/list", true).then(resp => {
        //         console.log('orders', resp);
        //         const orders = resp.data;
        //         commit('SET_PAGINATE', resp.paginate);
        //         commit('setClientOrders', orders);
        //         resolve(orders);
        //     })
        // })

        return ajaxGet(rootState.baseUrl + "/users/order/list?page=" + data.page, true).then((data) => {
            return data;
        })
    },
    getProductList: ({commit, state, rootState}, payload) => {
        return ajaxGet(rootState.baseUrl + `/package?page=${payload.page}`, true).then(({data}) => {
            return (data);
        })
    },
    searchProducts: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + `/package/search?page=${data.page}`, data.payload).then(({data}) => {
            return (data);
        })
    },
    getCart({commit, rootState, getters}) {
        return ajaxGet(rootState.baseUrl + '/cart' + '?preflight=' + getters.getPreflight).then((resp) => {
            let cart = JSON.parse(JSON.stringify(resp.data[0]));
            commit('setCart', cart);
            return cart;
        })
    },

    validateCoupon({commit, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/validateCoupon', payload).then((resp) => {
            console.log(resp.data);
            commit('setCart', resp.data['cart']);
            return resp;
        })
    },

    cartRemoveItem({commit, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/cart/remove', payload).then((resp) => {
            commit('setCart', resp.data[0]);
            return resp;
        })
    },

    cartRemoveItemAll({commit, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + '/cart/removeall', payload).then((resp) => {
            commit('setCart', resp.data[0]);
            return resp;
        })
    },
    //
    // emptyCart({commit, rootState}) {
    //     return ajaxGet(rootState.baseUrl + '/cart/empty').then((resp) => {
    //         commit('setCart', null);
    //         return;
    //     })
    // },
    emptyCart({commit, rootState}) {
        return ajaxGet(rootState.baseUrl + '/cart/emptyCart').then((resp) => {
            commit('setCart', resp.data.cart);
            return resp;
        })
    },


    addToCart: ({commit, state, getters, rootState}, payload) => {

        let addone = false;
        if (payload.hasOwnProperty('preorder')) {
            addone = true;
        }

        return ajaxPost(rootState.baseUrl + "/cart", {
            package_id: payload.id,
            addone: addone,
            preFlight: getters.getPreflight
        }).then((data) => {
            commit('setCart', data.data[0]);
            return data;
        });
        // return new Promise((resolve, reject) => {
        //
        //     var cart = getters.getCartItems;
        //     let cartType = getters.getCartType;
        //
        //     if (cart.length == 0) {
        //
        //         cartType = payload.currency;
        //         commit('setCartType', cartType);
        //
        //     } else {
        //
        //         if (cartType != payload.currency) {
        //             return reject('no');
        //         }
        //
        //     }
        //
        //     var whichProduct;
        //
        //     var existing = cart.filter(function (item, index) {
        //         if (item._id == payload._id) {
        //             whichProduct = index;
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     });
        //
        //
        //     if (existing.length) {
        //         cart[whichProduct].qty++;
        //     } else {
        //         cart.push({...payload, qty: 1});
        //         // cart.push({
        //         //     payload,
        //         //     qty: 1
        //         // });
        //     }
        //
        //     // commit('updateCart');
        //     resolve();
        // });
    },

    increaseQuantity: ({commit, state}, payload) => {
        commit('increaseQuantity', payload);
    },
    decreaseQuantity: ({commit, state}, payload) => {
        commit('decreaseQuantity', payload);
    },
    // removeItem: ({commit, state}, payload) => {
    //     commit('removeItem', payload);
    // },
    calculateSum: ({commit, state}, items) => {
        commit('calculateSum', items);
    },
    validateVoucher: ({commit, state, rootState}, data) => {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + "/orders/voucher", data, false).then(resp => {
                if (resp.statusCode && resp.statusCode != 200) {
                    reject(resp);
                }
                resolve(resp);
                console.log(resp)
            }).catch(err => {
                if (err.response.status == 401) {
                    console.log('user is not logged in ');
                    this.dispatch('logOut');
                }
                reject(err);
            });
        })
    },
    // -----------------------------------------sessions----------------------------------------- //
    getNextSession: ({commit, state, rootState}) => {
        return ajaxGet(rootState.baseUrl + "/sessions/next", true).then((data) => {
            return data;
        })
    },
    checkUserAvailability({commit, state, rootState}, data) {
        return ajaxPost(rootState.baseUrl + "/checkUser", data);
    },
    getSessions: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/user/sessions/search?page=" + data.page, data.options).then((data) => {
            return data;
        })
    },
    getClientAllSessions: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + "/user/sessions/allexceptrequestsessions?page=" + data.page).then((data) => {
            return data;
        })
    },
    requestedSessions: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + "/user/sessions/requestedsessions?page=" + data.page).then((data) => {
            return data;
        })
    },
    getSessionRequestTimes: ({commit, state, rootState}, data) => {
        return ajaxGet(rootState.baseUrl + `/user/sessions/request/${data.id}/times`, true).then((data) => {
            return data;
        })
    },
    selectSessionRequestTime: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + `/user/sessions/request/${data.id}`, data.payload).then((data) => {
            return data;
        })
    },
    selectMultipleSessionRequestTime: ({commit, state, rootState}, payload) => {
        return ajaxPost(rootState.baseUrl + `/user/sessions/request/setMultipleTimes`, payload).then((data) => {
            return data;
        })
    },
    // pageChangeHandle: ({commit, state}, {value, page}) => {
    //     return new Promise((resolve, reject) => {
    //         ajaxGet(srootStatebaseUrl + `/sessions/history?page=${page}`).then(({data, paginate}) => {
    //             console.log('here', paginate);
    //             commit('SET_SESSIONS', data);
    //             commit('SET_PAGINATE', paginate);
    //             console.log('next', state.sessions);
    //             resolve();
    //         })
    //     })
    // },

    pageChangeHandle: ({commit, state, rootState}, {value, page, url, commitName}) => {
        return new Promise((resolve, reject) => {
            ajaxGet(rootState.baseUrl + `${url}?page=${page}`, true).then(({data, paginate}) => {
                console.log('here', paginate);
                commit(commitName, data);
                commit('SET_PAGINATE', paginate);
                console.log('next', data);
                resolve();
            })
        })
    },
    // pageChangeHandleWithType: ({commit, state, rootState}, {value, page, url, commitName, usertype}) => {
    //     return new Promise((resolve, reject) => {
    //         ajaxGet(rootState.baseUrl + `${url}?page=${page + usertype}`, true).then(({data, paginate}) => {
    //             console.log('here', paginate);
    //             commit(commitName, data);
    //             commit('SET_PAGINATE', paginate);
    //             console.log('next', data);
    //             resolve();
    //         })
    //     })
    // },
    // pageChangeHandleWithStatus: ({commit, state, rootState}, {value, page, url, commitName, status}) => {
    //     return new Promise((resolve, reject) => {
    //         ajaxGet(rootState.baseUrl + `${url}?page=${page + status}`, true).then(({data, paginate}) => {
    //             console.log('here', paginate);
    //             commit(commitName, data);
    //             commit('SET_PAGINATE', paginate);
    //             console.log('next', data);
    //             resolve();
    //         })
    //     })
    // },

    getStartSessionForClient: ({commit, state, rootState}, sessionId) => {
        return ajaxGet(rootState.baseUrl + `/user/sessions/${sessionId}`, true).then(data => {
            return data;
        })
    },
    clientJoinSession: ({commit, state, rootState}, sessionId) => {
        return ajaxGet(rootState.baseUrl + `/user/sessions/${sessionId}/enter`, true).then(data => {
            return data;
        })
    },
    // -----------------------------------------sessions----------------------------------------- //

    sendOrder({commit, state, rootState}, order) {
        return ajaxPost(rootState.baseUrl + "/saveorder", order).then(data => {
            return (data);
            // return data;
        })
    },

    payFactor({commit, state, rootState}, {factorId}) {
        return ajaxGet(rootState.baseUrl + `/payorder/${factorId}`).then(data => {

            return data;
        })
    },
    preorderValidation: ({commit, state, rootState}, routeInfo) => {
        return ajaxGet(rootState.baseUrl + `/orders/preorder/${routeInfo.orderId}/${routeInfo.email}`, true).then((data) => {
            console.log('preorder', data);
            return data;
        })
    },
    getTransactionInformation({commit, state, rootState}, payload) {
        // return ajaxGet(rootState.baseUrl + "/orders/validate?id=" + payload);
        let url = rootState.baseUrl + "/user/validatePurchase/" + payload;
        return ajaxGet(url);
    },
    clientPostConsultInfo: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/user/times", data).then((data) => {
            return data;
        })
    },
    clientRemoveTimes: ({commit, state, rootState}, data) => {
        return ajaxPost(rootState.baseUrl + "/user/times/delete", data).then((data) => {
            return data;
        })
    },

    postConsultInfo({commit, state, rootState}, consultInfo) {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + "/users/savetimebooks", consultInfo, true, {
                headers: {
                    Authorization: "Bearer " + (localStorage.getItem("token") || "")
                }
            })
                .then(resp => {
                    if (resp.statusCode && resp.statusCode != 200) {
                        reject(resp);
                    }
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    postConsultationInfo({commit, state, rootState}, problem) {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + "/users/consultinfo", problem, true).then((data) => {
                console.log(data);
                resolve(data);
            })
        })
    },
    assignConsult({commit, state, rootState}) {
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            ajaxGet(rootState.baseUrl + "/users/consulthas", true).then((data) => {
                commit('setLoading', false);
                resolve(data);
            })
        })
    },
    getCandidateCounselor({commit, state, rootState}) {
        return new Promise((resolve, reject) => {
            ajaxGet(rootState.baseUrl + "/sessions/candidate", true).then((data) => {
                resolve(data);
            })
        })
    },
    saveRequest({commit, state, rootState}, request) {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + "/users/saverequest", request, true).then((data) => {
                console.log(data);
                resolve(data);
            })
        })
    },
    postSessionsBook({commit, state, rootState}, sessionsBook) {
        return new Promise((resolve, reject) => {
            ajaxPost(rootState.baseUrl + "/sessions/book", sessionsBook, true).then((data) => {
                console.log(data);
                resolve(data);
            })
        })
    },
    getCycles({commit, state, rootState}) {
        return ajaxGet(rootState.baseUrl + "/user/cycles", true).then((data) => {
            return (data);
        });
    },
    addNewCycle({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/user/cycles", payload).then((data) => {
            return (data);
        });
    },
    getSessionRequestDoctors({commit, state, rootState}, data) {
        return ajaxGet(rootState.baseUrl + "/user/cycles/" + data.cycleId + "/sessionRequestDoctors", true).then((data) => {
            return (data);
        });
    },
    clientGetSingleFactor({commit, state, rootState}, data) {
        return ajaxGet(rootState.baseUrl + "/user/factors/" + data.factorId).then((data) => {
            return data;
        });
    },
    sendSessionRequest({commit, state, rootState}, payload) {
        return ajaxPost(rootState.baseUrl + "/user/sessions/request", payload, true).then((data) => {
            return data;
        })
    },
    addWalletPaymentRecord({commit, state, rootState}, {factorId, payload}) {
        return ajaxPost(rootState.baseUrl + `/user/factors/${factorId}/addwalletpaymentrecord`, payload).then((data) => {
            return data;
        })
    },
    clientCheckWalletDebt({commit, state, rootState}, {factorId, payload}) {
        return ajaxPost(rootState.baseUrl + `/user/factors/${factorId}/checkwalletdebt`, payload).then((data) => {
            return data;
        })
    },
    removeWalletPaymentRecord({commit, state, rootState}, {factorId, payload}) {
        return ajaxPost(rootState.baseUrl + `/user/factors/${factorId}/removewalletpaymentrecord`, payload).then((data) => {
            return data;
        })
    },
    userChangeCartCurrency({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/cart/changecurrency`, payload).then((data) => {
            return data;
        })
    },
    userApplyGiftCode({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/user/giftcodes`, payload).then((data) => {
            return data;
        })
    },
    getInvitees({rootState, commit}, data) {
        return ajaxGet(rootState.baseUrl + `/affiliates/invitees?page=${data.page}`);
    },
    getWalletRecords({rootState, commit}, data) {
        return ajaxGet(rootState.baseUrl + `/user/walletrecords?page=${data.page}`);
    },
    getPayableFactor({rootState, commit}, data) {
        return ajaxGet(rootState.baseUrl + `/user/getlastpayablefactor`).then((resp) => {
            commit('SET_PAYABLE_FACTOR', resp.data.factor);
        });
    },
    surveyData({rootState, commit}, data) {
        return ajaxPost(rootState.baseUrl + `/user/surveyData`, data).then((data) => {
            return data;
        })
    }
};

const getters = {
    getTempMobile: state => state.tempMobile,
    getCart: state => state.cart,
    getCartType: state => state.cartType,
    getPayableFactor: state => state.payableFactor,
    hasValidActiveCycle: () => {
        let activeCycle;

        try {
            activeCycle = JSON.parse(localStorage.getItem('activeCycle'));
        } catch (e) {
            activeCycle = null;
        }
        if (activeCycle) {
            return activeCycle;
        } else {
            return false;
        }
    },
    getActiveCycle: state => state.activeCycle,
    getOrders: state => state.orders,
    getProductList: state => state.productList,
    getCartItems: state => {
        if (state.cart != null) {
            return state.cart.items;
        } else {
            return [];
        }
    },
    cartQuantity: state => {
        if (state.cart != null) {
            return state.cart.totalQty;
        } else {
            return 0;
        }
    },
    getSumPrice: state => state.sumPrice,
    // -----------------------------------------sessions----------------------------------------- //
    sessions: state => state.sessions,
    nextSession: state => state.nextSession,
    page: state => state.page,
    total: state => state.totalPages
    // -----------------------------------------sessions----------------------------------------- //
};
export default {
    state, getters, mutations, actions
}