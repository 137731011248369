<template class="fill-height">
  <div class="fill-height">
    <HeaderLogoArea/>
    <div class=" responceArea ">

      <div class="d-flex justify-center flex-column fill-height">
        <div class="px-5 py-5">
          <v-card style="border-radius: 20px" elevation="2">
            <div class="d-flex justify-space-around">
              <img src="../../../../assets/zeroPayment/cart.jpg" width="90%"/>
            </div>
          </v-card>
        </div>

        <p class="text-center textArea">این دعوت‌نامه را با یکی از این روش‌ها برای دوستت بفرست</p>
        <div class="referralCampaign-copyLink">
              <v-text-field readonly
                            ref="mylink"
                            @click="copyToClipboard"
                            value="https://app.simiaroom.com/fa/campaign/referral-campaign/invite"
                            style="direction: ltr;"
                            outlined>
              </v-text-field>
          <div class="text-center">
            <v-btn class="sendToOther" @click="copyToClipboard" elevation="0">
              <img src="@/assets/zeroPayment/link.png" width="25" alt="" class="ml-1">
              کپی لینک
            </v-btn>
          </div>
<!--          <input id="invitation-link" type="hidden" value="https://app.simiaroom.com/fa/campaign/referral-campaign/invite" ref="mylink"/>-->
        </div>
        <div class="d-flex mt-3 justify-space-around referralCampaign-whatApp">
          <ShareNetwork
              network="whatsapp"
              :url="'https://app.simiaroom.com/fa/campaign/referral-campaign/invite'"
              title=""
              description=""
              quote=""
              hashtags="simiaroom,simiaservice"
          >
            <v-btn class="sendToOther" @click="invitingFriend" elevation="0">
              <img src="@/assets/zeroPayment/whatsapp.png" width="15" alt="" class="ml-4">
              واتساپ
            </v-btn>
          </ShareNetwork>
        </div>
        <div class="d-flex mt-3 justify-space-around referralCampaign-telegram">
          <ShareNetwork
              network="telegram"
              :url="'https://app.simiaroom.com/fa/campaign/referral-campaign/invite'"
              title=""
              description=""
              quote=""
              hashtags="simiaroom,simiaservice"
          >
            <v-btn class="sendToOther" @click="invitingFriend" elevation="0">
              <img src="@/assets/zeroPayment/telegram.png" width="25" alt="" class="ml-5">
              تلگرام
            </v-btn>
          </ShareNetwork>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import HeaderLogoArea from "@/components/campaign/referal-campaign/headerLogoArea";

export default {
  components: {HeaderLogoArea},
  methods: {

    copyToClipboard() {
      let textToCopy = this.$refs.mylink.$el.querySelector('input');
      textToCopy.select();
      document.execCommand("copy");
      EventBus.$emit('notify', 'green', undefined, 'لینک کپی شد');
      this.invitingFriend()
    },
    redirectToSharePage() {
      EventBus.$emit('numberFromIran')
    },
    invitingFriend() {
      this.$emit('stepSwitcher', 2)
    }
  }
}
</script>

<style scoped>
.responceArea {
  /*height: 85vh;*/
  margin-top: 32px;
}

.vectorArea {
  height: 300px;
  background: url("../../../../assets/zeroPayment/pattern.png");
  background-size: cover;
  background-position: center;
}

.textArea {
  font-size: 12px;
  font-weight: 100;
}

.sendToOther {
  width: 150px !important;
  height: 31px !important;
  border-radius: 6px !important;
  background-color: #39b787 !important;
  /*font-size: 13px !important;*/
  text-align: center !important;
  color: white !important;
}

.titleArea {
  font-size: 14px;
}

.desc {
  width: 70%;
  line-height: 15pt;
}
</style>