<template>
  <div class="registerArea">
    <div class="d-flex justify-space-around flex-column fill-height px-7 py-7">
      <v-card>
        <v-card-text>
          <SignupComponent
              :GASignup="'ga_signup_referralCampaign'"
              preorderLogin="true"
              @userLoggedIn="afterLogin"
              wizard="true"
              :title="title != null ? title : 'ثبت نام کاربر جدید '"
              :onlyRegister="true"
              campaignName="REFERRAL"
          ></SignupComponent>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import SignupComponent from '@/components/SignUpComponentWizard.vue'
export default {
    components:{SignupComponent},
    props: {
      title: {
        require: false,
        default:null,
      }
    },
    methods:{
      afterLogin(){
        this.$router.push({name:'xpreorder',params:{orderId:68}})
      }
    }
}
</script>

<style scoped>
.registerArea{
  /*height: 90vh;*/
  margin-top: 25%;
}
</style>