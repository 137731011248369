const messages = {
    en: {
        $vuetify: {
            videoCall: {
                initError: 'The process of starting the session has encountered a problem. Please try again. If the problem persists, contact support'
            },
            buttons: {
                submit: 'Submit',
                cancel: 'Cancel',
                yes: 'Yes',
                no: 'No',
            },
            doctorPanel: {
                addToResume: 'Add to resume'
            },
            CompletePurchase: "Complete the purchase process",
            or: "or",
            selectMore: "Select other packages",
            gender: {
                male: 'Male',
                female: 'Female',
                other: 'Other',
            },
            education: {
                NoDegree: 'No degree',
                Diploma: 'Diploma',
                AssociateDegree: 'Associate Degree',
                Bachelor: 'Bachelor',
                MA: 'MA',
                PHD: 'P.H.D',
            },
            relationships: {
                single: 'Single',
                married: 'Married',
                inrel: 'In relationship',
                other: 'Other',
            },
            knway: {
                google: "Google search",
                twitter: "twitter",
                instagram: "instagram",
                podcast: "podcast",
                bannerads: "Banner ads",
                friends: "Introduce friends and acquaintances",
                site: "site",
                other: "other",
            },
            currency: {
                rial: 'T',
                dollar: '$',
                dollarCanada: 'Dollar canada',
                dollarAustralia: 'Dollar australia',
                Euro: '€',
                pound: 'pound',
            },
            ClientNavItem: {
                dashboard: "Dashboard",
                sessions: "Sessions",
                buySession: 'Buy Session',
                factors: "Factors",
                wallet: "Wallet",
                invitation: "Invitation",
                basket: "Basket",
                remainingSessions: "Remaining sessions",
                profile: "Profile",
                Messenger: "Messenger",
                calendar: "Calendar",
                clients: "Clients",
                userInformation: "User information",
                awaitSession: "Await Sessions"
            },
            counselorNavItem: {
                dashboard: "Dashboard",
                sessions: "Sessions",
                calendar: "Calendar",
                clients: "Clients"
            },
            clientHomePage: {
                counselingCourses: "Therapy cycles",
                upcomingSessions: "Upcoming sessions",
                messages: "Messages",
                packages: "Packages",
            },
            counselorHomePage: {
                sessionsHeld: "Sessions held",
                upcomingSessions: "Upcoming sessions",
                messages: "Messages",
                NumberOfClients: "Clients",
            },
            counselorCalender: {
                desc: "Please enter all the appropriate times for the next meeting (local time), the more time you enter, the faster the meeting will allow the support team",
                title: "Enter the appropriate times for the counseling session",
                RecordTimes: "Record times",
                LocalTime: "Local time",
                ConfirmAndReturn: "Confirm and return",
                close: "close",
                until: "until the"

            },

            counselorClientList: {
                title: "List of clients",
                patient: 'Patient',
                nextSession: "Next session",
                cycle: 'Cycle',
                cycles: 'Cycles',
                UserInformation: "User information",
                ViewFile: "View file",
                Topic: "Topic",
                desc: "Description   "

            },

            requestSession: "Request a session",
            addCycle: "Add a cycle",
            dashboardPage: {
                requestSessionDescription: "You can request to set your next online session here",
                addCycleDescription: "For each issue you are facing. you can add a therapy cycle and we'll match a best therapist for you"
            },
            sessionsPage: {
                tabs: {
                    confirmSessions: 'Confirmed sessions',
                    pendingSessions: 'Pending sessions',
                    remainingSessions: "Remaining sessions",
                },
                noSession: "No session"
            },
            orderProductPage: {
                hasPendingPayments: " Your offline payment request has been received, as soon as we confirm your payment, you will receive an email. ",
                hints: [
                    'Please choose a therapy package',
                    'Please turn off your VPN'
                ],
                tabs: [
                    'Session',
                    'Question',
                    'Test'
                ],
                noPackage: "No package to purchase",
                loading: "Please be patient",
            },
            factorsPage: {
                PurchasedPackage: "Purchased packages",
                tableHeaders: [
                    'Total amount',
                    'Remaining',
                    'Discount',
                    'Discount code',
                    'Date',
                    'Condition',
                ],
                Payable: "Payable",
                status: [
                    'Invoice',
                    'Paid',
                    'Prepaid',
                ],
                selectedPackage: "Selected Package",
                paymentsHeaders: [
                    'Amount',
                    'Type',
                    'Status',
                    'Date'
                ],
                packageHeader: [
                    'Title',
                    'Number',
                    'Number of sessions',
                    'Amount',
                    'discount',
                    'Final amount',
                ],
                PaymentState: [
                    'Waiting for return from bank',
                    'Canceled',
                    'Paid',
                    'Failed',
                    'Waiting ',
                ],
                PaymentType: [
                    'Online',
                    'Wallet debit',
                    'Offline payment',
                    'Wallet budget',
                ],
                walletMessage: {
                    clearWallet: "Clearing wallet",
                    useWallet: "Use a wallet",
                    RemainingTests: "Remaining tests",
                    RemainingQuestions: "Remaining questions"

                },
                addToBasket: "الحاق به سبد خرید"
            },
            userWallet: {
                title: "Wallet transactions",
                headersTable: [
                    'Type',
                    'Amount',
                    'currency',
                    'Date',
                    'Time',],
                status: [
                    'Charge',
                    'Withdrawal',
                ],
                giftCode: {
                    title: "Gift code",
                    subtitle: "Enter your gift code",
                    placeHolder: "Gift code ",
                    action: "Enter"
                }
            },
            invitationsPage: {
                title: " Invite your friends to Simiaroom using your personal url",
                subtitle: " Share your invitation link with: ",
                copyCode: "Click to copy",
                table: {
                    title: "Invitation",
                    headers: [
                        'Email address',
                        'Your gain',
                        'Status',
                    ],
                    dollar: "Dollar",
                    paymentStatus: [
                        'Waiting for payment',
                        'Done payment'
                    ]
                }
            },
            productGroup: {
                priceSession: "Session",

            },

            clientStepper: {
                header: [
                    'Basic information',
                    'Purchase',
                    'Therapy topic',
                    'Preferred time'
                ],
                continue: "Continue",
                return: "Return",
                close: "Close",
                confirm: "Confirm",
                accept: "Accept",
                basicInformation: {
                    title: "Please enter requested information",
                    name: "First name ",
                    family: "Last name",
                    phone: "Phone number",
                    country: "country",
                    timezone: "time zone"
                },
                addCycleModal: {
                    add: "Add",
                    title: "what type of therapy are you looking for?",
                    heading: "Add new cycle",
                    categories: [
                        'Individual',
                        'couples',
                        'family',
                        'Teen',
                        'Sex therapy',
                        'Psychiatry',
                    ],
                    countChar: "At least 20 characters",
                    categorySession: "Session category",
                    problemDescription: "Problem description",
                    problemDescriptionPlaceHolder: "Please explain your therapist topic briefly",
                    selectCycle: {
                        title: "Select therapy course",
                        selecting: "Add a therapy cycle",
                        confirm: "Session request conformation",
                        action: "Add a new course",
                        issueSession: "Course topic",
                        totalSession: "Number of sessions",
                        request: "Request a session",
                    },
                },
                dataTime: {
                    hint: "Please enter your Preferred times  slot (on time zone) for the coming session. Adding More time slots  helps us to schedule your session faster.",
                    typeCalender: [
                        'Gregorian',
                        'Solar'
                    ],
                    periods: [
                        'Morning',
                        'Noon',
                        'Afternoon',
                        'Night'
                    ],
                    changeTimeZone: "Change Time Zone",
                    changeTimeZoneHint: "Enter preferred times for session after changing the time zone",
                    tables: {
                        header: [
                            'Date',
                            'Times',
                        ],
                    }
                }
            },
            CartComponent: {
                Payments: "Payments",
                title: "Selected packages",
                price: "Amount",
                haveGiftCode: "Have a gift code? Click here",
                headersTable: [
                    'Item',
                    'Unit price',
                    'Number',
                    'Total price',
                    'Remove'
                ],
                clearingWallet: 'Clearing Wallet',
                payByWallet: "Pay By Wallet",
                discountCode: {
                    title: "Discount code",
                    action: "Confirm",
                    errorCode: "Code not valid",
                    acceptCode: "Successfully Confirmed"
                },
                giftCode: {
                    label: "Have a gift code? Click here ",
                    acceptCode: "Successfully Registered"
                },
                invoiceItems: {
                    total: "Total",
                    offer: "Discount",
                    finalPrice: "Final price"
                },
                itemsSuggest: {
                    title: "Suggested packages",
                    maxBuy: "Most popular",
                    bestBuy: "Most efficient",
                    action: "Buy",
                },
            },
            addToBasket: 'Add to cart',
            sessionCard: {
                unknown: "Unknown",
                timeIsUnknown: "time Is Unknown",
                timeUnknown: "time Unknown",
                doctorUnknown: "therapist Unknown",
                cycle: "Therapy course",
                fixed_time: "Session time",
                timezone: "Time zone",
                status: "Status",
                enterSession: "Enter session",
                receiveFactor: "Get receipt",
                statuses: [
                    'Waiting to select the therapist',
                    'Waiting to select your suggested times',
                    'upcoming',
                    'held'
                ]
            },
            loginRegisterPage: {
                title: "Welcome to Simiaroom",
                desc: [
                    'The selection of the consultant and the time of the meetings, after payment, will be done in the matching stage (Match Making) with the help of our experts.',
                    'If you are interested in having a meeting with a specific consultant, let our expert know right away.',
                    'In the case of dissatisfaction we have  Refund guarantee after your first session'
                ]
            },
            loginComponent: {
                title: "Welcome to Simiaroom",
                subTitle: "Login first to book your session",
                form: [
                    'Email address',
                    'Password'
                ],
                registerLabel: "Not registered?",
                registerAction: "Register now",
                resetPassword: "Forget password?",
                forgetPassword: {
                    title: "Password recovery",
                    subTitle: "Enter your email address",
                    action: "Send email",
                    loginLabel: "Have account?",
                    loginAction: "Login"
                },
                checkEmail: "Please check your email",
            },
            signupComponent: {
                title: "Create an account",
                subTitle: "Register first to book your session",
                form: [
                    "Phone number with country code"
                ],
                terms: "Terms and Conditions",
                accept: 'Read the terms and accept',
                inviter: "Inviter code : ",
                action: "Register",
                loginLabel: " Have an account?",
                loginAction: "Login",
                phone: "Phone number",
                registerAction: "Confirm (accept terms and conditions)",
                loginActionOnBoarding: "Log in",
            },
            payableFactor: {
                items: [
                    'You have an invoice ',
                    '',
                    'in the amount of ',
                    ''
                ],
                action: "View"
            },
            action: "View",
            onBoarding: {
                titleLogin: "New user registration",
                next: "Next",
                prev: "Previous",
                stepOne: {
                    title: 'On the way of a better life',
                    subTitle: "before finalizing your registration,  answer to some questions shortly. Your answers will help us  assign you a more compatible therapist and get the highest possible outcome out of your therapy.",
                    start: "Let’s start",
                    skip: 'skip'
                },
                stepTwo: {
                    title: "Choose the counseling topic",
                    subtitle: "This information can be changed in the panel",
                    cycles: [
                        'Individual',
                        'Couples therapy',
                        'Child and teen'
                    ],
                },
                stepThree: {
                    title: "Choose your gender",
                    gender: [
                        'Male',
                        'Female',
                        'Other'
                    ]
                },
                stepFour: {
                    title: 'How old are you?',
                    agePeriods: [
                        'Younger and 18',
                        'Older and 45'
                    ]
                },
                stepFive: {
                    register: "Register",
                    title: "your marital status?",
                    relationships: [
                        'Single',
                        'Married',
                        'In relationship',
                        'Other   '
                    ]
                },
                loginWithDifferentWay: "Sign in by another google",
                loginItems: [
                    'Google',
                    'Facebook'
                ],
                terms: "Terms and Conditions of Simiaroom",
                wizardLogin: [
                    'Register',
                    'login'
                ],
                wizardStepIndicator: {
                    info: "information",
                    pay: "Payment",
                    selectConsulter: "chose time and counselor",
                    register: 'register'
                },
                whoSelectDoctor: {
                    title: "How to choose your therapist?",
                    items: [
                        'I choose',
                        'Let  Simiaroom specialists choose'
                    ],
                },
                selectDoctor: {
                    searchPlaceHolder: 'Consultant name search  ',
                    labelCategory: 'Specialty',
                    all: 'all'
                },
                selectTime:{
                    title:"Choose your preferred times",
                    subTitle:"What hours during the week are suitable for holding your meeting?"
                },
                testimonial: {
                    sweden: {
                        title: 'Sima from Sweden',
                        desc: "I'm so glad I found you and I was able to get help from you and Psychologist in a situation where I could not trust anyone. I feel much better now and I really thank you."
                    },
                    usa: {
                        title: 'Ibrahim from America',
                        desc: "My outlook on life changed after therapy. Many of the things that were painful are no more, and that was because of the vision I got in therapy. Thank you and your advice."
                    },
                    germany: {
                        title: 'Delnaz from Germany',
                        desc: "I had bad therapy experience and I knew how much it could hurt and I was afraid to start again. Until my friend introduced you and you do not know how much I thank you for being well.\n"

                    },
                    usaTwo: {
                        title: 'Shadi from America',
                        desc: "Many thanks to Simiaroom and his therapists. I have not really seen this system anywhere that when they see that you do not get the desired result with a therapist, they change your therapist to get the best result."
                    },
                    canada: {
                        title: 'محمدحسین از کانادا',
                        desc: '    پلتفرمی نبود که من رزومه مشاوراش رو واسه پیدا کردن مشاور نگشته باشم. تا این که به سایت شما رسیدم و دیدم هر مشاوری که هست هم رزومه معتبری داره، هم تجربه‌ زیاد. به خاطر همین به شما اعتماد کردم و از این اعتماد هم راضی‌ام.'
                    },
                    netherlands: {
                        title: "Artemis from Netherlands",
                        desc: 'I really did not have a particular problem. But after I immigrated, I felt something was wrong and my self-esteem was low. That\'s why I looked for a good therapist and found your site. Everything is fine now and I am very grateful to my therapist and Simiaroom.'
                    }
                }

            },

            preOrderPage: {
                titleBox: "Payment and booking consultation time",
                hint: "If you are in Iran, turn off your VPN",
                payDollar: "Pay",
                payRial: 'Pay',
                pay:'Pay',
                testimonial: {
                    America: {
                        name: "Reza from the US",
                        content: "Despite my initial feeling, my online therapy in SimiaRoom was held greatly, and it was totally worth it to reschedule my plan to undergo the therapy sessions and put in the time. I am greatly satisfied with everything."
                    },
                    germany: {
                        name: 'Seema from Germany',
                        content: 'I got familiar with my therapist on your website and I am truly thankful. Another thing is that the fee was much lower than that of other therapists who are not as great experts in this field (ADHD)'
                    },
                    Sweden: {
                        name: "Eleena from Sweden",
                        content: "I have been sharing your website with many of my friends because I believe we all need to have therapy. Our mental health, specially after immigration, is so important."
                    },
                    spain: {
                        name: "Anahita from Spain",
                        content: "There are many psychotherapists/Psychologists, but good ones are not easily found. I believe in SimiaRoom by my heart because they have selected the best experts who are all well-known and up to date."
                    },
                },
                preorderHint: [
                    'All online counseling sessions are secure via video communication. Each online counseling session takes 45 minutes.\n'
                ],
                options: [
                    'Persian language and Iranian culture',
                    'Issuing insurance receipts',
                    'Safe payment',
                    'Refund guarantee',
                ],
                mainPreorderOptions: [
                    'All online counseling sessions are secure via video communication. Each online counseling session takes 45 minutes.',
                    'Possibility of providing payment confirmation to insurance companies to receive the cost of counseling sessions (Iranians abroad)',
                    'Your consultant will be selected by the specialized team of Simiaroom according to the subject of your consultation. Therefore, after payment, enter the subject of your consultation clearly.',
                    'After paying and registering the order to book an appointment, we will contact you.',
                    'For information about the price and to book an appointment with psychiatrists ',
                    ' follow the collection of the page related to these specialists.',
                    'The good news is that for the first session, you can pay ',
                    'off your order by entering the firstorder discount code.'
                ],
                doctors: {
                    bina: "Dr.bina",
                    salimi: "Dr.salimi",
                    bolhari: "Dr.bolhari"
                }

            },
            footerDashboard: {
                title: [
                    "If you face any problem, please send a message to us in WhatsApp or Telegram",
                    ""
                ],
                copyWrite: "Online therapy",
                appLinks: {
                    android: ' Android patient version',
                    doctor: ' Android therapist version  ',
                    iosDoctor: ' iOS therapist version  ',
                    iosPatient: ' iOS patient version',
                    windows: ' windows version',
                    mac: ' mac version',
                    macM1: ' mac m1 version'
                },
            },
            logout: "Log out",
            total: "Total",
            count: "Number",
            doctor: "Therapist",
            showAll: "View all",
            yourWallet: "Your wallet",
            showAccount: "Account",
            dashboard: 'Dashboard',
            navAssignConsult: 'Sessions details',

            // form error msg
            dataIterator: {
                rowsPerPageText: 'Items per page:',
                pageText: '{0}-{1} of {2}',
            },
            emailLabel: 'Email',
            requiredInputRule: 'Required',
            passwordLabel: 'Password',
            phoneLabel: 'Phone',
            passwordConfirmationLabel: 'Confirm Password',
            emailSignUpCaption: 'This is the email you will use to login to your Simiaroom account',
            passwordSignUpCaption: 'Please enter a password for your account',
            signUpLabel: "Sign Up",
            loginLabel: 'Login',
            loginOrSignup: 'Login | Sign Up',
            nextLabel: 'Next',
            backLabel: 'Back',
            accountRecoveryCaption: 'Enter Your Email To Recover Your Account',
            accountRecovery: 'Recover your account',
            forgotPasswordText: 'Forgot Password?',
            setAPassword: 'Set a Password',
            navDashboard: 'Dashboard',
            navSessionsLabel: 'Sessions',
            navClientsLabel: 'Clients',
            navCounselorsLabel: "Counselors",
            closeDialogLabel: 'Close',
            userStepOne: 'Buy Package',
            userStepTwo: 'Enter Information',
            userStepThree: 'Submit Problem',
            userStepFour: 'Submit Times',
            userOrders: "Orders",
            navProducts: 'products',
            navCalendar: 'Calendar',
            navََََََََََََََAssignConsult: 'Assign Consult',
            navHomeLabel: 'Home',
            navAboutUsLabel: 'About us',
            navLoginSignupLabel: 'Login | Sign up',
            profileInformationTitle: 'Basic Information',
            profileFirstname: 'Name',
            profileLastname: 'Last name',
            profileGender: 'Gender',
            profileCivilStatus: 'Marital Status',
            profileEmail: 'Email',
            profileMobile: 'Mobile',
            profilePhone: 'Phone',
            profileBirthDate: 'Birth Date',
            profileAge: 'Age',
            profileEdu: 'Education',
            profileKnway: 'How did you hear about us',
            profileKnwayOther: 'How...?',
            profileTZ: 'Time Zone',
            profileLocationTitle: 'Location Information',
            profileTimeTitle: 'Time Information',
            profileCountry: 'Country',
            profileCity: 'City',
            profileJob: 'Job (optional)',
            profileExplanation: 'Explanation',
            profileSupportTitle: 'َََAdminَََ',
            profileSpeciality: 'Speciality',
            profileResume: 'Resume',
            timezones: 'Timezone',
            searchLabel: 'Search',
            dateLabel: 'Date',
            timeLabel: 'Time',
            productLabel: 'Product',
            priceLabel: 'Price',
            nextSessionLabel: 'Next Session',
            sessionsListLabel: 'Sessions List',
            consultTopicLabel: 'Consult Topic',
            problemStatementLabel: 'explain your problem...',

            // form error msg
            emailErrRequired: 'Email is required',
            emailErrValid: 'E-mail must be valid',
            passwordErrRequired: 'Password is required',
            fillIsRequired: 'Required',


            saveSuccessfullyMsg: 'Successfully Saved',
            saveErrorMsg: 'Something went wrong!',
            warningSelectDateMsg: 'Select your date, Please.',
            err500: 'Please check your internet connection or try again later',
            falseUsePass: 'Incorrect Username or Password',
            checkEmail: 'A verification link has been sent to your email account',
            notReservable: 'This time cannot be reserved.s',
            noTimeChosen: 'No time selected',
            chooseCounselor: 'Please choose your therapist.',
            alertChangeTZ: 'You are changing your time zone. your session will be based on the new time zone.',
            packagesLZero: 'Purchase a package first to register for more sessions',
            dataTable: {
                itemsPerPageText: 'items in page',
                sortBy: 'Sort by'
            },

            noDataText: 'No data text',

            emptyCart: 'Your cart is empty!',
            errorCode_mobile: 'mobile   ',
            errorCode_name: '',
            errorCode_family: '',
            errorCode_birth_date: '',
            zeroPayment: {
                codeSent: "The code has been sent.",
                phoneAcceptedPast: "This phone number has been already confirmed.",
                wrongNumber: "The phone number is wrong.",
                waiting: "Please wait.",
                phoneFromIran: "This phone number belongs to Iran.",
                cantConfirm: "The Number is not verifiable.",
                wrongCode: "Please enter the right code.",
                phoneRequired: "Phone number is required.",
                notif: [
                    "The verification code has been sent to you.",
                    "Enter the 6-digit code in the form below to activate the account.",
                    "Wrong phone number?"
                ],
                confirm: "Submit",
                checkCode: "Checking code",
                untilNextSend: "Re-sending code in :",
                reSend: "Resend code",
                successSetSession: {
                    title: "Your session has been submitted successfully on the system.",
                    desc: "Complete your account information in the dashboard and book your therapy session.",
                    action: "Complete account information."
                },
                failedSetSession: {
                    title: "You are not eligible to receive the package."
                },
            },
            addNewCycle: {
                title: "Add a new therapy course.",
                titleSession: "Topic of the therapy session",
                titleDesc: "Explain problems.",
                placeHolderDecs: "Briefly, explain the issues you wish to be discussed during the therapy session.",
                errors: {
                    selectIsRequired: "Selecting this field is required.",
                    minChar: "Must contain at least 20 characters"
                },
                confirm: "submit",
                close: "close",
            },
            RemainingSessions: {
                title: "My packages",
                action: "Purchase a new package",
                count: [
                    'count',
                    'public'
                ],
                doctor: "with",
                noSession: {
                    title: "You do not have any packages.",
                    action: "buy"
                }
            },
            wallet: {
                total: [
                    'your wallet value is',
                    ''
                ]
            },
            invitations: {
                message: [
                    'The total value charged in your wallet is:',
                    ''
                ]
            },

            assignconsult: {
                successMessage: "The transaction has been successfully completed.",
                failedMessage: "Failed transaction",
                code: "Tracking code",
            },
            Notify: {
                loginFailed: "Your username or password is incorrect",
                doctorTime: [
                    'You cannot set the time for more than',
                    ''
                ],
                successfully: "Successfully submitted.",
                successfullyV2: "The progress has been successfully completed.",
                successfullyWithRedirect: "Successfully submitted; moving to dashboard",
                successfullyRequest: 'Your request has been successfully submitted.',
                successDateTime: "Your information has been successfully registered, we will contact you within the next 24 hours",
                changePassword: "Password has been successfully updated",
                packageComponent: "Please select a payment currency for packages.",
                changeStatus: 'Please select one of the suggested hours for your session.',
                DateTime: {
                    selectDateFirst: "Please select your desired date, first.",
                    selectDay: "Please select at least one day and the time.",
                    success: "Your suggested times have been successfully registered.",
                },
                clientSessionRequest: 'Please select one of the meeting times.',
                loginSuccess: "You have successfully logged in.",
                signupComponent: {
                    phoneNumberError: "Phone number is not valid",
                    success: "Registration has been successfully done.",
                    emailError: "This email has already been used.",
                    passwordError: "Enter the password carefully.",
                    passwordLengthError: "The minimum password length is 8 characters.",
                    rePasswordRequired: "Confirm password is required.",
                    rePasswordCheck: 'Confirm password is required.',
                    phoneRequired: "Phone number is required.",
                    countrySelected: "Select the country code",
                    passwordRequired: "password repeat is required",
                    notSamePassword: 'passwords do not match',
                },
                login: {
                    password: {
                        passwordRequired: "password is required",
                        minLength: "The minimum password length is 8 characters"
                    }
                },
                acceptTerms: "Accepting “Terms and Conditions” is required.",
                calender: {
                    successDelete: "Successfully deleted."
                },
                error: 'An error has occurred.',
                connectionError: "Please check your connection.",
                addNewCycle: {
                    oneSession: "The minimum number of sessions is one.",
                    notEnough: "Not enough session numbers",
                },
                clientDashboard: {
                    selectTime: "Please select a time block",
                    selectOne: "Please select an option.",
                    success: "Your session time preferences have been successfully submitted.",
                    selectTimespan: "Please select at least one time block"
                },
                ExperssComponent: {
                    selectOne: "please select one of the time blocks.",
                    successSelect: "Your suggested/preferred times have been successfully submitted."
                },
                CartComponent: {
                    enterCode: "Enter the discount code.",
                    needLogin: "Please login or sign up to purchase."
                },
                cycle: {
                    needPackage: "You have no packages to request."
                },
                sessionCountRules: {
                    countRequired: "Enter the number of sessions",
                    minCount: "The number of sessions must be at least 1",
                    buyError: "The number of sessions requested must be less than the number of sessions you purchased"
                }
            },
            invoicePayable: {
                title: 'Note, your payment has not been completed.',
                description: [
                    'gift code',
                    '$ 5',
                    '50 thousand Tomans',
                    'You will only be valid for the next 24 hours'
                ],
                action: "return to cart",
            },
            Messenger: {
                show: "Show messenger",
                search: "search",
                start: "date of first message",
                end: "Previous messages",
                writer: "message",
                selectGap: "Choose a conversation"
            },
            offlinePayment: {
                title: "Choose your payment method",
                close: "Close",
                action: "Record payment",
                desc: "Deposit the cost of your consulting package to the above account and after payment, enter the payment information in the following fields, and wait for the confirmation of the financial department and the call from Simiaroom.",
                paymentDate: "Date of payment",
                currency: "Currency",
                PaymentAmount: "Amount",
                return: "Return to payment methods"
            },
            startSession: {
                heading: "Counseling course",
                title: "Topic",
                desc: "Description",
                passed: {
                    zero: [
                        'Up to another ',
                        'Your other consultation with',
                        'begins. Please make sure the quality of your internet speed before starting the session.',
                    ],
                    one: [
                        'Meeting with',
                        'Is being held.',
                    ],
                    two: [
                        'Meeting with',
                        'Held.'
                    ]
                }
            },
            setSession: {
                title: "Choose from consultant times",
                loading: "Choosing from time please wait consultant",
                titleSelect: "Choose from time to time Select your desired time Wait Consultant",
                emptyState: "Your consultant does not have any free time at the moment, please wait until the consultant's time is up and register the time later.",
                save: "save"
            },
            changeState: {
                dateSession: "Date of the meeting",
                hourSession: "Meeting hours",
                state: {
                    session_requested: 'Session request',
                    doctor_assigned: 'The doctor is set',
                    date_fixed: 'Time is set',
                    used: 'Held'
                }
            },
        },
    },

    fa: {
        $vuetify: {
            videoCall: {
                initError: 'فرایند شروع جلسه با مشکل مواجه شده است. لطفا یک بار دیگرر اقدام کنید. در صورت عدم رفع مشکل با پشتیبانی تماس بگیرید'
            },
            buttons: {
                submit: 'ثبت',
                cancel: 'لغو',
                yes: 'بله',
                no: 'خیر',
            },
            doctorPanel: {
                addToResume: 'افزودن به رزومه'
            },
            or: "یا",
            selectMore: "انتخاب سایر بسته ها",
            relationships: {
                single: 'مجرد',
                married: 'متاهل',
                inrel: 'شریک عاطفی',
                other: 'موارد دیگر',
            },
            education: {
                NoDegree: 'فاقد مدرک',
                Diploma: 'دیپلم',
                AssociateDegree: 'فوق دیپلم',
                Bachelor: 'لیسانس',
                MA: 'فوق لیسانس',
                PHD: 'دکتری',
            },
            knway: {
                google: "جست و جو در  گوگل",
                twitter: "توییتر",
                instagram: "اینستاگرام",
                podcast: "پادکست",
                bannerads: "تبلیغات بنری",
                friends: "معرفی دوستان و آشنایان",
                site: "سایت",
                other: "موارد دیگر",
            },
            gender: {
                male: 'زن',
                female: 'مرد',
                other: 'موارد‌دیگر',
            },
            currency: {
                rial: 'تومان',
                dollar: 'دلار',
                dollarCanada: 'دلار کانادا',
                dollarAustralia: 'دلار استرالیا',
                Euro: 'یورو',
                pound: 'پوند',
            },
            ClientNavItem: {
                dashboard: "داشبورد",
                sessions: "جلسات",
                buySession: 'خرید بسته ',
                factors: "فاکتور‌‌ها",
                wallet: "کیف پول ",
                invitation: "دعوت از دوستان",
                basket: "سبدخرید",
                remainingSessions: "جلسات باقی مانده",
                profile: "حساب کاربری",
                Messenger: "پیامرسان",
                calendar: "تقویم",
                clients: "مراجعان",
                userInformation: "اطلاعات کاربر",
                awaitSession: "جلسات در انتظار تایید"

            },
            counselorNavItem: {
                dashboard: "داشبورد",
                sessions: "جلسات",
                calendar: "تقویم",
                clients: "مراجعان"
            },
            clientHomePage: {
                counselingCourses: "دوره‌های مشاوره  ",
                upcomingSessions: "جلسات پیش رو",
                messages: "پیام‌ها",
                packages: "بسته‌ها",
            },
            counselorHomePage: {
                sessionsHeld: "جلسات برگزار شده",
                upcomingSessions: "جلسات پیش رو",
                messages: "پیام‌ها",
                NumberOfClients: "تعداد مراجعین"
            },


            counselorCalender: {
                desc: "لطفا همه زمانهای مناسب برای برگزاری جلسه آینده (به زمان محلی ) را وارد نمایید، هرچه تعداد بیشتری  زمان وارد کنید، امکان برگزاری سریع تر جلسه را به تیم پشتیبانی میدهید.",
                title: "وارد کردن زمانهای مناسب برای جلسه مشاوره",
                RecordTimes: "ثبت زمان‌ها",
                LocalTime: "زمان محلی:",
                ConfirmAndReturn: "تایید و بازگشت",
                close: "بستن",
                until: "تا"
            },

            counselorClientList: {
                title: "لیست مراجعان",
                patient: 'مراجعه کننده',
                nextSession: "جلسه بعدی",
                cycle: 'دوره مشاوره',
                cycles: 'دوره های مشاوره',
                UserInformation: "اطلاعات کاربر",
                ViewFile: "مشاهده پرونده",
                Topic: "موضوع",
                desc: "شرح"
            },

            dataIterator: {
                rowsPerPageText: 'گزینه در صفحه:',
                pageText: '{0}-{1} از {2}',
            },
            requestSession: "درخواست جلسه ",
            addCycle: "افزودن دوره مشاوره",
            dashboardPage: {
                requestSessionDescription: "شما می توانید در تاریخ و زمان مورد نظرتان درخواست جلسه نمایید <br/> برای درخواست جلسه باید حتما بسته ای خریداری شده باشد",
                addCycleDescription: "شما می توانید دوره ی مشاوره مورد نظرتان را درخواست داده  و  <br/> در صورت نیاط توضیحاتی برای بررسی توسط کارشناسان ما اضافه نمایید"
            },
            sessionsPage: {
                tabs: {
                    confirmSessions: 'جلسات تایید شده',
                    pendingSessions: 'جلسات در انتظار تایید',
                    remainingSessions: "جلسات باقی مانده",
                },
                noSession: "جلسه ای وجود ندارد"
            },
            orderProductPage: {
                hasPendingPayments: "درخواست پرداخت آفلاین شما ثبت گردید. نتیجه از طریق ایمیل به اطلاع شما خواهد رسید.",
                hints: [
                    'لطفا برای برگزاری جلسه، بسته مورد نظر خود را انتخاب نمایید.',
                    'پیش از ثبت سفارش از خاموش بودن فیلترشکن خود اطمینان داشته باشید.'
                ],
                tabs: [
                    'جلسه',
                    'سوال',
                    'تست'
                ],
                noPackage: "بسته ای برای خرید وجود ندارد",
                loading: "لطفا صبر کنید",
            },
            factorsPage: {
                PurchasedPackage: "بسته های خریداری شده",
                tableHeaders: [
                    'مبلغ فاکتور',
                    'باقی مانده',
                    'تخفیف فاکتور',
                    'کد تخفیف',
                    'تاریخ',
                    'وضعیت',
                ],
                Payable: "قابل پرداخت",
                status: [
                    'پیش فاکتور',
                    'پرداخت شده',
                    'پیش پرداخت',
                ],
                selectedPackage: "بسته های انتخاب شده",
                paymentsHeaders: [
                    'مبلغ',
                    'نوع',
                    'وضعیت',
                    'تاریخ'
                ],
                packageHeader: [
                    'عنوان',
                    'تعداد',
                    'تعداد جلسات',
                    'مبلغ',
                    'تخفیف محصول',
                    'مبلغ نهایی',
                ],
                PaymentState: [
                    'در انتظار بازگشت از بانک',
                    'لغو شده',
                    'پرداخت شده',
                    'رد شده',
                    'در انتظار',
                ],
                PaymentType: [
                    'آنلاین',
                    'بابت بدهی کیف پول',
                    'پرداخت آفلاین',
                    'پرداخت از طریق کیف پول',
                ],
                walletMessage: {
                    clearWallet: "تسویه کیف پول",
                    useWallet: "استفاده از کیف پول",
                    RemainingTests: " تست های باقی مانده",
                    RemainingQuestions: "سوالات باقی مانده"

                }
            },
            userWallet: {
                title: "تراکنش های کیف پول",
                headersTable: [
                    'نوع',
                    'مبلغ',
                    'ارز',
                    'تاریخ',
                    'ساعت',
                ],
                status: [
                    'شارژ',
                    'کسر',
                ],
                giftCode: {
                    title: "ورود کد هدیه",
                    subtitle: "در صورتی که کد هدیه دارید، آنرا وارد کنید",
                    placeHolder: "کد هدیه ",
                    action: "اعمال"
                }
            },
            invitationsPage: {
                title: "با ارسال لینک زیر به دوستان خود، آنها را به خانواده سیمیا دعوت نمایید.",
                subtitle: "همچنین می توانید با استفاده از شبکه های اجتماعی زیر، لینک را به اشتراک بگذارید.",
                copyCode: "برای کپی کلیک کنید",
                table: {
                    title: "افراد دعوت شده",
                    headers: [
                        'ایمیل',
                        'دریافتی شما',
                        'وضعیت',
                    ],
                    dollar: "دلار",
                    paymentStatus: [
                        'پرداخت انجام نشده',
                        'پرداخت انجام شده'
                    ]
                }
            },
            productGroup: {
                priceSession: "جلسه",

            },

            clientStepper: {
                header: [
                    'اطلاعات پایه',
                    'خرید بسته',
                    'موضوع مشاوره',
                    'ثبت زمان پیشنهادی'
                ],
                continue: "بعدی",
                return: "بازگشت",
                close: "بستن",
                confirm: "تایید",
                accept: "ثبت",
                basicInformation: {
                    title: "لطفا اطلاعات زیر را وارد نمایید.",
                    name: "نام ",
                    family: "نام خانوادگی ",
                    phone: "تلفن همراه (با کد کشور)",
                    country: "کشور",
                    timezone: "محدود زمانی"
                },
                addCycleModal: {
                    add: "افزودن",
                    title: "موضوع مشاوره و شرح مشکل خود را وارد نمایید",
                    countChar: "حداقل 20 کاراکتر",
                    categories: [
                        'فردی',
                        'خانواده و زوج',
                        'خانواده',
                        'کودک و نوجوان',
                        'سکس تراپی',
                        'روانپزشکی',
                    ],
                    categorySession: "موضوع مشاوره",
                    problemDescription: "شرح مساله",
                    problemDescriptionPlaceHolder: "به اختصار موضوع مورد نظر خود را برای مشاور شرح دهید.",
                    selectCycle: {
                        title: "انتخاب دوره مشاوره",
                        selecting: "لطفا دوره مشاوره خود را انتخاب نمایید",
                        confirm: "تایید درخواست جلسه",
                        action: "افزودن دوره جدید",
                        issueSession: "موضوع دوره :",
                        totalSession: "تعداد کل جلسات",
                        request: "درخواست جلسه",
                    },
                },
                dataTime: {
                    hint: "لطفا همه زمانهای مناسب برای برگزاری جلسه آینده (به زمان محلی ) را وارد نمایید، هرچه تعداد بیشتری زمان وارد کنید، امکان برگزاری سریع تر جلسه را به تیم پشتیبانی میدهید.",
                    typeCalender: [
                        'تقویم میلادی',
                        '              تقویم شمسی'
                    ],
                    periods: [
                        'صبح',
                        'ظهر',
                        'عصر',
                        'شب'
                    ],
                    changeTimeZone: "تغییر زمان محلی",
                    changeTimeZoneHint: "با تغییر زمان محلی، باید زمانهای مناسب برگزاری جلسه را مجددا وارد کنید",
                    tables: {
                        header: [
                            'تاریخ',
                            'ساعت ها',
                        ],
                    }
                }
            },
            CartComponent: {
                Payments: "پرداخت ها",
                title: "بسته های انتخاب شده",
                price: "مبلغ",
                haveGiftCode: "کد هدیه دارید؟ اینجا کلیک کنید",
                headersTable: [
                    'آیتم',
                    'قیمت واحد',
                    'تعداد',
                    'قیمت کل',
                    'حذف'
                ],
                clearingWallet: 'تسویه کیف پول',
                payByWallet: "کسر از کیف پول",
                discountCode: {
                    title: "کد تخفیف",
                    action: "اعمال",
                    errorCode: "کد نامعتبر می باشد",
                    acceptCode: "با موفقیت اعمال شد"
                },
                giftCode: {
                    label: "کد هدیه دارید؟ اینجا کلیک کنید",
                    acceptCode: "با موفقیت به ثبت رسید"
                },
                invoiceItems: {
                    total: "جمع کل",
                    offer: "تخفیف",
                    finalPrice: "مبلغ نهایی"
                },
                itemsSuggest: {
                    title: "بسته‌های پیشنهادی",
                    maxBuy: "بیشترین خرید",
                    bestBuy: "انتخاب به صرفه",
                    action: "خرید",
                },
            },
            addToBasket: 'افزودن به سبد خرید',
            sessionCard: {
                unknown: "نا مشخص",
                timeIsUnknown: "زمان : نامشخص",
                timeUnknown: "زمان نامشخص",
                doctorUnknown: "مشاور ثبت نشده",
                cycle: "دوره مشاوره",
                fixed_time: "زمان برگزاری ",
                timezone: "به وقت",
                status: "وضعیت",
                enterSession: "ورود به جلسه",
                receiveFactor: "دریافت فاکتور",
                statuses: [
                    'در انتظار تعیین مشاور',
                    'در انتظار انتخاب زمان از طرف شما',
                    'در انتظار برگزاری',
                    'برگزار شده'
                ]
            },
            loginRegisterPage: {
                title: "به سیمیاروم خوش آمدید",
                desc: [
                    'انتخاب مشاور و زمان برگزاری جلسات پس از پرداخت، در مرحله جورسازی (Match Making) با کمک کارشناسان ما صورت خواهد گرفت',
                    'اگر علاقمندید با مشاور خاصی جلسه داشته باشید، در همان مرحله به کارشناس ما اعلام کنید.',
                    'ضمانت بازگشت وجه در صورت نارضایتی از جلسه ی اول'
                ]
            },
            loginComponent: {
                title: "به سیمیاروم خوش آمدید",
                subTitle: "برای خرید لطفا ابتدا وارد شوید",
                form: [
                    'آدرس ایمیل',
                    'کلمه عبور'
                ],
                registerLabel: "ثبت نام نکرده اید؟",
                registerAction: "ثبت نام کنید",
                resetPassword: "رمز عبور را فراموش کرده اید؟",
                forgetPassword: {
                    title: "بازیابی حساب کاربری",
                    subTitle: "برای بازیابی حساب کاربری، ایمیل خود را وارد کنید.",
                    action: "ارسال ایمیل",
                    loginLabel: "حساب کاربری دارید؟",
                    loginAction: "وارد شوید"
                },
                checkEmail: "ایمیل خود را چک کنید",
            },
            signupComponent: {
                title: "ایجاد حساب",
                subTitle: "لطفا اطلاعات زیر را وارد نمایید",
                form: [
                    "تلفن همراه (با کد کشور)"
                ],
                terms: "شرایط و ضوابط",
                accept: 'را خوانده و میپذیرم.',
                inviter: "کد معرف شما :",
                action: "ثبت نام",
                loginLabel: " حساب کاربری دارید؟",
                loginAction: "وارد شوید",
                phone: "تلفن همراه",
                registerAction: "تاییـد (شرایط و قوانین را قبول دارم)",
                loginActionOnBoarding: "ورود با حساب کاربری",
            },
            payableFactor: {
                items: [
                    'شما یک فاکتور',
                    'قابل پرداخت',
                    'به مبلغ',
                    'دارید'
                ],
                action: "مشاهده"
            },
            onBoarding: {
                titleLogin: "ثبت نام کاربر جدید",
                next: "بعدی",
                prev: "قبلی",
                stepOne: {
                    title: 'در مسیر یک حال بهتر',
                    subTitle: "پاسخ به چند سوال کوتاه جهت آشنایی بیشتر مشاور با شما",
                    start: "پاسخ میدهم",
                    skip: "تمایلی  ندارم"
                },
                stepTwo: {
                    title: "موضوع مشاوره خود را انتخاب کنید",
                    subtitle: "در صورت نیاز می توانید اطلاعات خود را در پنل کاربری تغییر دهید.",
                    cycles: [
                        'فردی',
                        'رابطه و زوج درمانی',
                        'کودک و نوجوان'
                    ],
                },
                stepThree: {
                    title: "جنسیت خود را انتخاب کنید",
                    gender: [
                        'مرد',
                        'زن',
                        'موارد دیگر'
                    ]
                },
                stepFour: {
                    title: 'چند سال دارید؟',
                    agePeriods: [
                        'کمتر از 18',
                        'بیشتر از 45'
                    ]
                },
                stepFive: {
                    title: "وضعیت تاهل شما چگونه است؟",
                    relationships: [
                        'مجرد',
                        'متاهل',
                        'شریک عاطفی',
                        'موارد دیگر'
                    ]
                },
                loginWithDifferentWay: "ورود یا ثبت نام با روش دیگر",
                loginItems: [
                    'حساب گوگل',
                    'حساب فیسبوک'
                ],
                terms: "شرایط و قوانین سیمیاروم",
                wizardLogin: [
                    'ثبت نام',
                    'ورود'
                ],
                wizardStepIndicator: {
                    info: "مشخصات",
                    register: "ثبت نام",
                    pay: "پرداخت",
                    selectConsulter: "انتخاب زمان و مشاور"
                },
                whoSelectDoctor: {
                    title: "چگونه تراپیست شما انتخاب شود؟",
                    items: [
                        'خودم انتخاب میکنم',
                        'متخصصان سیمیاروم انتخاب کنند',
                    ]
                },
                selectDoctor: {
                    searchPlaceHolder: 'جستجوی نام مشاور',
                    labelCategory: 'تخصص',
                    all: 'همه'
                },
                selectTime:{
                    title:"زمان‌های ترجیحی خود را انتخاب کنید",
                    subTitle:" چه ساعاتی در طول هفته برای برگزاری جلسه شما مناسب است؟"
                },
                testimonial: {
                    sweden: {
                        title: 'سیما از سوئد',
                        desc: "من خیلی خوشحالم که پیداتون کردم و تونستم تو شرایطی که به کسی نمی‌تونستم اعتماد کنم، از شما و آقای دکتر کمک گرفتم. الان حالم خیلی بهتره و واقعا ازتون ممنونم."
                    },
                    usa: {
                        title: 'ابراهیم از آمریکا',
                        desc: "دید من به زندگی بعد از تراپیم عوض شد. خیلی چیزهایی که زجر آور بودن دیگه نیستن و این به‌خاطر نگاهی بود که تو تراپی به دست آوردم. ممنون از شما و مشاوراتون."
                    },
                    germany: {
                        title: 'دلناز از آلمان',
                        desc: "    من تجربه تراپی بد رو داشتم و می‌دونم چقدر می‌تونه به آدم بیشتر آسیب بزنه و از شروع مجدد می‌ترسیدم. تا این که دوستم شما رو معرفی کرد و نمی‌دونید چقدر ممنونتونم به خاطر حال خوب الانم."

                    },
                    usaTwo: {
                        title: 'شادی از آمریکا',
                        desc: "خیلی ممنون از سیمیاروم و مشاوراش. من واقعا این سیستم رو هرجایی ندیدم که وقتی ببینن با مشاورت به نتیجه دلخواه نمی‌رسی، مشاور رو تغییر بدن تا بهترین نتیجه رو بگیری‌."
                    },
                    canada: {
                        title: 'محمدحسین از کانادا',
                        desc: '    پلتفرمی نبود که من رزومه مشاوراش رو واسه پیدا کردن مشاور نگشته باشم. تا این که به سایت شما رسیدم و دیدم هر مشاوری که هست هم رزومه معتبری داره، هم تجربه‌ زیاد. به خاطر همین به شما اعتماد کردم و از این اعتماد هم راضی‌ام.'
                    },
                    netherlands: {
                        title: "آرتمیس از هلند",
                        desc: '    من واقعا مشکل خاصی نداشتم؛ اما بعد مهاجرتم احساس می‌کردم یه چیزی کمه و اعتماد به نفسم پایین اومده بود. به خاطر همین دنبال یه تراپیست خوب گشتم و سر از سایت شما درآوردم.  الان همه چی عالیه و من از مشاورم و سیمیاروم خیلی ممنونم.'
                    }
                },

            },
            preOrderPage: {
                titleBox: "پرداخت و رزرو وقت مشاوره",
                hint: "توجه: اگر از ایران سفارش خود را ثبت می کنید، پیش از ثبت سفارش از خاموش بودن فیلترشکن خود اطمینان داشته باشید.",
                payDollar: "پرداخت",
                payRial: 'پرداخت شبکه شتاب',
                pay:'پرداخت',
                testimonial: {
                    America: {
                        name: "رضا از آمریکا",
                        content: "                علی رغم تصور اولیه‌م، تراپی آنلاین من در سیمیاروم با کیفیت خیلی خوبی برگزار شد و واقعا می‌ارزید که برنامم رو هماهنگ کنم و وقت بذارم. من خیلی راضی بودم."
                    },
                    germany: {
                        name: 'سیما از آلمان',
                        content: '                من از طریق وبسایت شما با دکترم که متخصص ADHD هستن، آشنا شدم و واقعا ممنونم. نکته دیگه این که هزینه‌ای که از من دریافت شد خیلی کمتر از هزینه روانشناسای دیگه بود که تخصصشون هم کمتره.'
                    },
                    Sweden: {
                        name: "الینا از سوئد",
                        content: "                من واقعا آدرس وبسایت شما رو به خیلی از دوستانم دارم می‌دم چون به نظرم همه ما به روانشناس باید مراجعه کنیم و سلامت روانمون به خصوص بعد مهاجرت خیلی مهمه."
                    },
                    spain: {
                        name: "آناهیتا از اسپانیا",
                        content: "                روانشناس که خیلی زیاده ولی روانشناس خوب خیلی کمه. من خیلی سیمیاروم رو قبول دارم چون واقعا دکترهای متخصص رو گلچین کرده و همه نام آشنا و آپدیت هستن."
                    }
                },
                options: [
                    'مشاورانی با زبان و فرهنگ ایرانی',
                    'امکان دریافت رسید بیمه برای ایرانیان خارج از کشور',
                    'پرداخت امن بین‌المللی',
                    'ضمانت بازگشت وجه در صورت نارضایتی از جلسه اول',
                ],
                mainPreorderOptions: [
                    ' تمامی جلسات مشاوره آنلاین بصورت ارتباط تصویری امن می باشد. زمان هر جلسه مشاوره آنلاین ۴۵دقیقه است.',
                    ' امکان ارائه ی تاییدیه ی پرداخت به موسسات بیمه برای دریافت هزینه ی جلسات مشاوره (ایرانیان خارج از کشور)',
                    'مشاور شما توسط تیم تخصصی سیمیآروم و با توجه به موضوع مشاوره شما انتخاب می شود. بنابراین پس از پرداخت، موضوع مشاوره تان را بصورت واضح وارد نمایید.',
                    'پس از پرداخت و ثبت سفارش جهت رزرو وقت، با شما تماس خواهیم گرفت.',
                    'جهت اطلاع از قیمت و رزرو وقت با روانپزشکان مجموعه ',
                    'از صفحه ی مربوط به این متخصصان اقدام نمایید.',
                    'خبر خوب اینکه، برای اولین جلسه، با وارد کردن کد تخفیف firstorder میتوانید سفارشتون رو با',
                    'تخفیف پرداخت کنید.'
                ],
                doctors: {
                    bina: "دکتر بینا",
                    salimi: "دکتر سلیمی",
                    bolhari: "دکتر بوالهری"
                }
            },
            footerDashboard: {
                title: [
                    ' در صورت بروز مشکل در فرایند ثبت سفارش، با پشتیبانی سیمیاروم در واتس اپ یا تلگرام به شماره',
                    'پیام ارسال فرمایید'
                ],
                copyWrite: "مشاوره روانشناسی آنلاین",
                appLinks: {
                    android: 'دانلود نسخه اندروید کاربر',
                    doctor: 'دانلود نسخه مشاور',
                    iosDoctor: 'دانلود اپ IOS مشاور',
                    iosPatient: 'دانلود اپ IOS مراجعه کننده',
                    windows: 'دانلود نسخه ویندوز',
                    mac: 'دانلود نسخه مک',
                    macM1: 'دانلود نسخه مک m1'
                },
            },
            logout: "خروج",
            total: "مجموع",
            count: "تعداد",
            doctor: "دکتر",
            showAll: "مشاهده همه",
            yourWallet: "کیف پول شما",
            showAccount: "حساب کاربری",
            dashboard: 'داشبورد',
            emailLabel: 'ایمیل',
            requiredInputRule: 'الزامی',
            passwordLabel: 'کلمه عبور',
            phoneLabel: 'تلفن همراه',
            passwordConfirmationLabel: 'تکرار کلمه عبور',
            emailSignUpCaption: 'از این ایمیل برای ورود به حساب کاربری خود استفاده خواهید کرد.',
            passwordSignUpCaption: 'لطفا برای حساب خود یک کلمه عبور وارد نمایید',
            signUpLabel: "ثبت نام",
            loginLabel: 'ورود',
            loginOrSignup: 'ورود | ثبت نام',
            nextLabel: 'ادامه',
            backLabel: 'بازگشت',
            accountRecoveryCaption: 'برای بازیابی حساب کاربری، ایمیل خود را وارد کنید.',
            accountRecovery: 'بازیابی حساب کاربری',
            forgotPasswordText: 'بازیابی رمز عبور',
            setAPassword: 'کلمه عبور خود را وارد کنید',
            navDashboard: 'داشبورد',
            navSessionsLabel: 'جلسات',
            navClientsLabel: 'مراجعان',
            navCounselorsLabel: "مشاوران",
            closeDialogLabel: 'بستن',
            userStepOne: 'خرید بسته',
            userStepTwo: 'تکمیل اطلاعات',
            userStepThree: 'شرح موضوع مشاوره',
            userStepFour: 'ثبت زمانهای ترجیحی',
            userOrders: "سفارشات",
            navProducts: 'خرید بسته',
            navCalendar: 'تقویم',
            navAssignConsult: 'جزئیات جلسات مشاوره',
            navHomeLabel: 'خانه',
            navAboutUsLabel: 'درباره ما',
            navLoginSignupLabel: 'ورود | ثبت نام',
            profileInformationTitle: 'اطلاعات شخصی',
            profileFirstname: 'نام',
            profileLastname: 'نام خانوادگی',
            profileGender: 'جنسیت',
            profileCivilStatus: 'وضعیت تاهل',
            profileEmail: 'ایمیل',
            profileMobile: 'تلفن همراه',
            profilePhone: 'تلفن ثابت',
            profileBirthDate: 'تاریخ تولد',
            profileAge: 'سن',
            profileEdu: 'مدرک تحصیلی',
            profileKnway: 'نحوه آشنایی',
            profileKnwayOther: 'از چه طریقی با سیمیا آشنا شدید؟',
            profileTZ: 'محدوده زمانی',
            profileLocationTitle: 'اطلاعات مکانی',
            profileTimeTitle: 'اطلاعات زمانی',
            profileCountry: 'کشور',
            profileCity: 'شهر',
            profileJob: 'شغل (اختیاری)',
            profileExplanation: 'توضیحات',
            profileSupportTitle: 'عنوان پشتیبانی',
            profileSpeciality: 'نوع تخصص',
            profileResume: 'رزومه',
            timezones: 'زمان محلی',
            searchLabel: 'جستجو',
            dateLabel: 'تاریخ',
            timeLabel: 'ساعت',
            productLabel: 'محصول',
            priceLabel: 'قیمت',
            nextSessionLabel: 'جلسه پیش‌رو',
            sessionsListLabel: 'لیست جلسات',
            consultTopicLabel: 'موضوع مشاوره',
            problemStatementLabel: 'شرح مختصر مشکل...',

            // form error msg
            emailErrRequired: 'پر کردن  فیلد ایمیل الزامی است',
            emailErrValid: 'یک ایمیل معتبر وارد کنید',
            passwordErrRequired: 'پر کردن  فیلد کلمه عبور الزامی است',
            fillIsRequired: 'پر کردن این فیلد الزامی است',

            saveSuccessfullyMsg: 'با موفقیت ثبت شد',
            saveErrorMsg: 'متاسفانه خطایی رخ داده است!',
            warningSelectDateMsg: 'لطفاروز مورد نظر خود را انتخاب کنید',
            err500: 'لطفااتصال خود به اینترنت را بررسی نموده، در صورت عدم رفع مشکل با پشتیبانی تماس بگیرید',
            falseUsePass: 'نام کاربری یا کلمه عبور اشتباه است',
            checkEmail: 'ثبت نام با موفقیت انجام شد، جهت تکمیل حساب ایمیل خود را چک کنید',
            notReservable: 'این زمان قابل رزرو نمی باشد',
            noTimeChosen: 'هیچ زمانی انتخاب نشده است',
            chooseCounselor: 'لطفا ابتدا مشاور را انتخاب نمایید',
            alertChangeTZ: 'شما در حال تغییر زمان محلی خود می باشید. دقت داشته باشید تمامی زمانهای جلسات بر اساس این زمان محلی نمایش داده میشود',

            dataTable: {
                itemsPerPageText: 'ایتم در صفحه',
                sortBy: 'مرتب سازی بر اساس'
            },
            dataFooter: {
                pageText: 'صفحه'
            },
            noDataText: 'دیتایی یافت نشد',
            emptyCart: 'سبد خرید شما خالی است!',
            packagesLZero: 'برای ثبت جلسه بیشتر، ابتدا بسته خریداری نمایید',
            errorCode_mobile: 'موبایل',
            errorCode_name: '',
            errorCode_family: '',
            errorCode_birth_date: '',

            //trans
            invoicePayable: {
                title: 'توجه، پرداخت شما تکمیل نشده است.',
                description: [
                    'کد هدیه',
                    '5 دلاری',
                    '50 هزار تومانی',
                    ' شما تنها تا 24 ساعت آینده معتبر خواهد بود'
                ],
                action: "به سبد خرید",
            },
            //trans

            //new
            zeroPayment: {
                codeSent: "کد برای شما ارسال شد",
                phoneAcceptedPast: "شماره موبایل شما قبلا تایید شده است",
                wrongNumber: "شماره تماس درست نیست",
                waiting: "لطفا چند دقیقه صبر کنید",
                phoneFromIran: "این شماره مربوط به ایران است",
                cantConfirm: "شماره قابل تایید نمی باشد",
                wrongCode: "لطفا کد را به درستی وارد نمایید",
                phoneRequired: "وارد کردن تلفن الزامیست",
                notif: [
                    "پیامکی همراه با کد فعال سازی برای شما ارسال شد",
                    "برای فعال سازی، کد ۶ رقمی را در فرم زیر وارد کنید",
                    "شماره اشتباه است؟"
                ],
                confirm: "ثبت",
                checkCode: "بررسی کد فعالسازی",
                untilNextSend: " تا ارسال کد بعدی :",
                reSend: "ارسال مجدد کد",
                successSetSession: {
                    title: "جلسه شما در سیستم ثبت شد",
                    desc: " از طریق داشبرد اطلاعات حساب کاربری خود را کامل کرده و رزرو جلسه مشاوره خود را نهایی کنید",
                    action: "تکیمل اطلاعات حساب کاربری"
                },
                failedSetSession: {
                    title: "شما واجد شرایط دریافت بسته نمی باشید"
                },
            },
            addNewCycle: {
                title: "افزودن دوره مشاوره جدید",
                titleSession: "موضوع مشاوره",
                titleDesc: "شرح مساله",
                placeHolderDecs: "به اختصار موضوع مورد نظر خود را برای مشاور شرح دهید.",
                errors: {
                    selectIsRequired: "انتخاب این فیلد الزامیست",
                    minChar: "حداقل 20 کاراکتر"
                },
                confirm: "ثبت",
                close: "بستن",
            },
            RemainingSessions: {
                title: "بسته های من",
                action: "خرید بسته جدید",
                count: [
                    'تعداد',
                    'عمومی'
                ],
                doctor: "با دکتر",
                noSession: {
                    title: "شما هیچ بسته ای ندارید",
                    action: "خرید کنید"
                }
            },
            CompletePurchase: "تکمیل فرایند خرید",
            wallet: {
                total: [
                    'شارژ کیف پول شما ',
                    'می باشد'
                ]
            },
            invitations: {
                message: [
                    'تا کنون مبلغ',
                    'در کیف پول شما شارژ شده است.'
                ]
            },
            assignconsult: {
                successMessage: "پرداخت با موفقیت انجام شد",
                failedMessage: "پرداخت با شکست مواجه شد",
                code: "شماره پیگیری",
            },
            Notify: {
                loginFailed: "نام کاربری یا کلمه عبور شما اشتباه می باشد",
                doctorTime: [
                    'شما نمیتوانید بیشتر از',
                    ' ساعت انتخاب کنید'
                ],
                successfully: "با موفقیت به ثبت رسید",
                successfullyV2: "عملیات با موفقیت انجام شد.",
                successfullyWithRedirect: "با موفقیت به ثبت رسید، به صفحه داشبورد منتقل میشوید",
                successfullyRequest: 'درخواست شما با موفقیت به ثبت رسید',
                successDateTime: "اطلاعات شما با موفقیت ثبت شد، تا 24 ساعت آینده با شما تماس خواهیم گرفت",
                changePassword: "کلمه عبور با موفقیت تغییر یافت",
                packageComponent: "لطفا حداقل یک ارز پرداختی برای بسته انتخاب نمایید",
                changeStatus: 'لطفا یکی از زمانهای مشاور را انتخاب نمایید',
                DateTime: {
                    selectDateFirst: "لطفا ابتدا تاریخ مورد نظر را انتخاب نمایید",
                    selectDay: "لطفا حداقل یک روز و زمانهای آن را انتخاب نمایید",
                    success: "زمانهای پیشنهادی شما با موفقیت به ثبت رسیدند",
                },
                clientSessionRequest: 'لطفا یکی از زمانهای مشاور را انتخاب نمایید',
                loginSuccess: "با موفقیت وارد شدید",
                signupComponent: {
                    phoneNumberError: "شماره تلفن معتبر نیست.",
                    success: "ثبت نام با موفقیت انجام شد",
                    emailError: "ایمیل وارد شده تکراری است",
                    passwordError: "کلمه عبور را با دقت وارد کنید",
                    passwordLengthError: "حداقل طول کلمه عبور 8 کاراکتر می باشد",
                    rePasswordRequired: "پر کردن فیلد تکرار کلمه عبور الزامیست",
                    rePasswordCheck: 'کلمه عبور و تکرار آن یکسان نمی باشند',
                    phoneRequired: "وارد کردن تلفن الزامیست",
                    countrySelected: "کد کشور را انتخاب نمایید",
                    passwordRequired: "پر کردن فیلد تکرار کلمه عبور الزامیست",
                    notSamePassword: 'کلمه عبور و تکرار آن یکسان نمی باشند',
                },
                login: {
                    password: {
                        passwordRequired: "وارد کردن کلمه عبور الزامیست",
                        minLength: "حداقل طول کلمه عبور 8 کاراکتر می باشد"
                    }
                },
                acceptTerms: "موافقت با شرایط و ضوابط الزامیست.",
                calender: {
                    successDelete: "با موفقیت حذف شد"
                },

                error: 'خطایی رخ داده است',
                connectionError: "لطفا اینترنت خود را بررسی کنید",
                addNewCycle: {
                    oneSession: "حداقل تعداد جلسات 1 می باشد.",
                    notEnough: "تعداد جلسات شما کافی نیست.",
                },
                clientDashboard: {
                    selectTime: "لطفا یکی از زمانهای مشاور را انتخاب نمایید",
                    selectOne: "لطفا یک گزینه را انتخاب نمایید",
                    success: "زمان های شما با موفقیت به ثبت رسیدند",
                    selectTimespan: "لطفا حداقل یک بازه را انتخاب نمایید"
                },
                ExperssComponent: {
                    selectOne: "لطفا یکی از زمانهای مشاور را انتخاب نمایید",
                    successSelect: "زمانهای پیشنهادی شما با موفقیت به ثبت رسیدند"
                },
                CartComponent: {
                    enterCode: "کد تخفیف را وارد نمایید",
                    needLogin: "برای خرید ابتدا وارد شوید یا ثبت نام کنید"
                },
                cycle: {
                    needPackage: "شما بسته ای برای درخواست جلسه ندارید، ابتدا خرید کنید"
                },
                sessionCountRules: {
                    countRequired: "تعداد جلسات را وارد نمایید",
                    minCount: "تعداد جلسات حداقل باید 1 باشد",
                    buyError: "تعداد جلسات درخواستی باید از جلسات خریداری شده شما کمتر باشد"
                }
            },
            Messenger: {
                show: "نمایش پیامرسان",
                search: "جستجو",
                start: "ابتدای پیام ها",
                end: "پیامهای پیشین",
                writer: "نوشتن پیام",
                selectGap: "یک گفتگو انتخاب نمایید"
            },
            offlinePayment: {
                title: "شیوه پرداخت خود را انتخاب کنید",
                close: "بستن",
                action: "ثبت پرداخت",
                desc: "هزینه بسته مشاوره خود را به حساب فوق واریز نمایید و پس از پرداخت، اطلاعات پرداخت را در فیلد های زیر وارد کنید، و منتظر تایید بخش مالی و تماس از طرف سیمیاروم باشید.",
                paymentDate: "تاریخ پرداخت",
                currency: "ارز پرداختی",
                PaymentAmount: "مبلغ پرداختی",
                return: "بازگشت به شیوه های پرداخت"
            },
            startSession: {
                heading: "دوره مشاوره",
                title: "موضوع",
                desc: "شرح",
                passed: {
                    zero: [
                        'تا',
                        'دیگر مشاوره شما با',
                        'آغاز می شود. لطفا پیش از شروع جلسه، از کیفیت سرعت اینترنت خود اطمینان حاصل فرمایید.'
                    ],
                    one: [
                        'جلسه با',
                        'در حال برگزاری می باشد.'
                    ],
                    two: [
                        'جلسه با',
                        'برگزار شده است.'
                    ]
                }
            },
            setSession: {
                title: "انتخاب از بین زمان های مشاور",
                loading: "لطفا منتظر باشید",
                titleSelect: "زمان مورد نظر خود را انتخاب نمایید",
                emptyState: "مشاور شما در حال حاضر زمان خالی ندارد، لطفا تا خالی شدن زمان مشاور صبر کنید و بعدا اقدام به ثبت زمان نمایید",
                save: "ذخیره"
            },
            changeState: {
                dateSession: "تاریخ جلسه",
                hourSession: "ساعت جلسه",
                state: {
                    session_requested: 'درخواست جلسه',
                    doctor_assigned: 'دکتر ست شده',
                    date_fixed: 'زمان ست شده',
                    used: 'برگزار شده'
                }
            },
        },
    }

}

export default messages;
