/*eslint-disable*/
import axios from 'axios';
import {ajaxGet, ajaxPost} from '../../utilities.js';
import LS from '../../LocalStorateAPI';
import {EventBus} from "@/event-bus";
import login from "@/views/Login";

const auth = {
    state: {
        profile: {},
        role: localStorage.getItem('role'),
        loggedIn: LS.get('loggedIn'),
        // token: LS.get('token'),
        token: localStorage.getItem('token'),
        isLoggedIn: false
    },
    mutations: {
        setUser(state, data) {
            state.role = data.role;
            state.isLoggedIn = true;
            LS.set('isLoggedIn', true);
            LS.set('role', data.role);
        },

        userLoggedIn(state) {

        },
        setToken(state, token) {
            state.token = token.token;
            LS.set('token', token.token);
            axios.defaults.headers.common['Authorization'] = token.token;
        },
        setLoggedIn(state) {
            state.loggedIn = true;
            LS.set('loggedIn', true);
        }
    },
    actions: {
        checkEmailExistence({commit, rootState}, payload) {
            return ajaxPost(rootState.baseUrl + "/check-email-existence", payload, false);
        },
        login({commit, rootState}, payload) {
            return ajaxPost(rootState.baseUrl + "/login", payload, false).then(({data}) => {
                commit('setToken', {token: 'Bearer ' + data.token});
                return Promise.resolve();
            }).catch(() => {
                return Promise.reject();
            })
        },
        sendOtp({rootState}) {
            return ajaxPost(rootState.baseUrl + '/user/sendOtpCode', {})
        },
        confirmNewPhoneNumber({state, rootState}, code) {
            return ajaxPost(rootState.baseUrl + '/user/profile', {
                mobile: code,
            })
        },
        checkOtp({rootState}, otp) {
            return ajaxPost(rootState.baseUrl + '/user/submitOtpCode', {
                otp: otp,
            })
        },
        processForVerifiedReward({rootState}) {
            return ajaxGet(rootState.baseUrl + '/user/process-verified-reward')
        }
    },
    getters: {
        getAccessToken: state => state.token,

    }
}
export default auth;