<template>
  <div>
    <v-app>

      <div :class="loader ? 'loaderBackArea' : ''">
        <v-fade-transition>
          <div class="loaderArea" v-if="loader">
            <div class="backLoader"></div>
            <div class="loaderSpinner">
              <v-progress-linear color="primary" indeterminate/>
            </div>
          </div>
        </v-fade-transition>
        <div :class="classForLoader">
          <router-view></router-view>
        </div>
      </div>

      <component :is="$vuetify.rtl?'Hackit':''"></component>
    </v-app>
  </div>
</template>

<script>
/*eslint-disable*/
// import Hackit from ;
import {mapGetters} from "vuex";

const Hackit = () => import ( "./components/Hackit");
import {EventBus} from "@/event-bus";

export default {
  components: {Hackit},
  created() {


    this.setDirection();
    EventBus.$on('languageIsChanged', () => {
      this.setDirection();
    });
  },
  data() {
    return {
      // loader: true,
    }
  },
  mounted() {
    EventBus.$on('setLoading', (val) => {
      this.$nextTick().then(() => {
        this.$store.commit('setLoading', val)
        this.load
      })

      // console.log(this.$store.getters.loading , 'xxxxxxxxxx')
      // this.loader = this.$store.getters.loading
      // if (val) {
      //   setTimeout(() => {
      //     this.loader = false
      //   }, 6000)
      // }
    })
  },
  computed: {
    classForLoader() {
      return this.loader ? 'blurBackGround' : 'blurBackGroundNone'
    },
    ...mapGetters({
      loader: 'loading'
    })
  },
  methods: {
    setDirection() {
      this.$vuetify.rtl = this.$store.getters.getDirection === 'rtl';
    },
    setLoading(loading) {
      this.$store.commit('setLoading', loading);
    },
  },
};
</script>
<style>
@import "./assets/styles/main.css";

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/eot/IRANSansWeb(FaNum)_Light.eot");
  src: url('./assets/fonts/eot/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype');
  src: url('./assets/fonts/woff2/IRANSansWeb(FaNum)_Light.woff2') format('woff2');
  src: url('./assets/fonts/woff/IRANSansWeb(FaNum)_Light.woff') format('woff');
  src: url('./assets/fonts/ttf/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/eot/IRANSansWeb(FaNum)_Medium.eot");
  src: url('./assets/fonts/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype');
  src: url('./assets/fonts/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2');
  src: url('./assets/fonts/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff');
  src: url('./assets/fonts/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/eot/IRANSansWeb(FaNum)_Bold.eot");
  src: url('./assets/fonts/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype');
  src: url('./assets/fonts/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2');
  src: url('./assets/fonts/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff');
  src: url('./assets/fonts/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
}

* {
  font-family: 'Roboto', 'Arial';
}

@media (min-width: 200px ) and (max-width: 700px) {

  .loaderSpinner {
    position: absolute;
    top: 48% !important;
    background: white;
    width: 90% !important;
    padding: 15px;
    border-radius: 12px;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 12px -5px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
}

.back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}

.loaderArea {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 10000;
}


.loaderSpinner {
  position: absolute;
  top: 40%;
  background: white;
  width: 500px;
  padding: 15px;
  border-radius: 12px;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 12px -5px rgba(0, 0, 0, 0.3);
}

.blurBackGround {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  transition: all 0.1s ease;
}

.blurBackGroundNone {
  transition: all 0.3s ease;
}
.loaderBackArea{
  height: 100vh;
  overflow: hidden;
  position: relative;
}
</style>