<template class="fill-height">
  <div class="fill-height">
    <HeaderLogoArea/>
    <div class=" responceArea ">
      <div class="d-flex justify-center flex-column fill-height">
        <div class="text-center">
          <img src="../../../../assets/zeroPayment/logoGreen.png" width="150px" alt="">
        </div>
        <div class="vectorArea">
          <div class="d-flex fill-height justify-space-around flex-column text-center">
            <img src="../../../../assets/zeroPayment/map.png" width="100%" alt="">
          </div>
        </div>
        <p class="text-center textArea">
          این فراخوان مخصوص ایرانیان مهاجر است
          <br>
          تو هم می‌توانی با ارسال این هدیه، به دوستانت
          <br>
          .در سراسر دنیا  کمک کنی
        </p>
        <div class="d-flex justify-space-around">
          <div class="sendToOther" @click="redirectToSharePage">
            دعوت و ارسال دعوت‌نامه
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import HeaderLogoArea from "@/components/campaign/referal-campaign/headerLogoArea";

export default {
  components: {HeaderLogoArea},
  methods: {
    redirectToSharePage() {
      EventBus.$emit('numberFromIran')
    }
  }
}
</script>

<style scoped>
.responceArea{
  /*height: 85vh;*/
  margin-top: 50px;
}
.vectorArea{
  height:300px;
  background: url("../../../../assets/zeroPayment/pattern.png");
  background-size: cover;
  background-position: center;
}
.textArea{
  font-size: 12px;
  font-weight: 100;
}
.sendToOther{
  width: 180px;
  height: 31px;
  border-radius: 6px;
  background-color: #39b787;
  font-size: 12px;
  text-align: center;
  padding-top: 7px;
  color: white;
}
</style>