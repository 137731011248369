<template>
  <div>
    <div class="headerBanner">
      <div class="d-flex space-around align-content-center text-center">
        <div style="width: 100%">
          <a href="https://simiaroom.com"><img src="@/assets/zeroPayment/logo.png" class="mt-4 logoSimia"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {

}

.logoSimia {
  width: 100px;
}

.headerBanner {
  background: #4669b1;
  height: 50px;
}

</style>