<template class="fill-height">
  <div class="fill-height">
    <HeaderLogoArea/>
    <div class=" responceArea ">
      <div class="d-flex justify-center flex-column fill-height">

        <div class="vectorArea">
          <div class="d-flex fill-height justify-space-around flex-column text-center">
            <img src="@/assets/zeroPayment/celeb.png" width="100%" alt="">
          </div>
        </div>
        <div class="desc">
          <p class="text-center textArea">
            تو دعوت شدی...
          </p>
          <p>
            به قدم گذاشتن در مسیر حال بهتر
            برات.
            <br>
            از مشاوران حرفه‌ای و باتجربه‌  سیمیاروم وقت مشاوره، گرفتم. چون دوستت دارم و برام مهم هستی.
          </p>
          <p style="font-weight: 300">
          یک جلسه رایگان در دو جلسه اول، هدیه من به توست.
          </p>
        </div>
        <div class="form-area text-center">
          <div class="d-flex justify-space-around referralCampaign-invitedClient" >
            <v-btn class="submitPhoneNumber" @click="goToRegisterPage" elevation="0">
              ثبت‌نام و دریافت هدیه
            </v-btn>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import HeaderLogoArea from "@/components/campaign/referal-campaign/headerLogoArea";
import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";

export default {
  components: {HeaderLogoArea},
  data(){
    return{
      phone:null
    }
  },
  methods: {
    redirectToSharePage() {
      EventBus.$emit('numberFromIran')
    },
    invitingFriend(){
      this.$emit('invitedStepperSwitcher',1)
    },
    goToRegisterPage(){
      this.invitingFriend()
    }
  },
  computed: {
    phoneRules() {
      return [
        input => {
          let phone = input.replaceAll(' ', '');
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber) {
            if (!phoneNumber.isValid()) {
              return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
            } else {
              return true;
            }
          } else {
            return this.$t('$vuetify.Notify.signupComponent.phoneRequired');
          }
        }
      ]
    },
  },
  watch: {
    phone() {
      if (this.phone.startsWith('00')) {
        this.phone = this.phone.substr(2);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('098')) {
        this.phone = this.phone.substr(1);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('09')) {
        this.phone = this.phone.substr(1);
        this.phone = '+98' + this.phone;
      }

      this.phone = new AsYouType().input(this.phone);
    }
  }
}
</script>

<style scoped>
.form-area{
  font-size: 12px;
}
.responceArea{

  margin-top: 30px;
  /*height: 85vh;*/
}
.vectorArea{
  height:300px;
  background: url("../../../../assets/zeroPayment/pattern.png");
  background-size: cover;
  background-position: center;
}
.textArea{
  font-size: 14px;
  font-weight: 400;
}
.inputArea{
  border-radius: 6px;
  width: 180px;
  padding: 0px;
}
.sendToOther{
  width: 180px !important;
  height: 31px !important;
  border-radius: 6px !important;
  background-color: #39b787 !important;
  font-size: 13px !important;
  text-align: center !important;
  color: white !important;
}
.submitPhoneNumber{
  width: 180px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #39b787 !important;
  font-size: 12px !important;
  text-align: center ;
  padding-top: 7px ;
  color: white !important;
  margin-top: -10px;
}
.v-text-field--outlined >>> fieldset {
  border: solid 1px #707070 !important;
  border-radius: 6px !important;
  height: 40px !important;
}
.desc{
  padding: 0px 70px 0px 70px ;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
}
</style>