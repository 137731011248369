<template>
  <div>
    <v-btn @click="showIt">xxx</v-btn>

    <v-dialog v-model="loading" max-width="500" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear
              active
              indeterminate
              query
              rounded
              stream
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";

export default {
  mounted() {
    console.log(this.loading)
    this.localLoading = this.loading;
  },
  methods: {
    showIt() {
      this.$store.commit('setLoading', true);
    }
  },
  data() {
    return {
      localLoading: false
    }
  },
  computed: {
    ...mapGetters(['loading'])
  },
  watch: {
    loading() {
      this.$nextTick().then(() => {
        this.localLoading = this.loading;
      });
    }
  }

}
</script>

<style scoped>

</style>