<template>
  <div>
    <v-window v-model="step" :reverse="this.$store.getters.isRTL">
      <v-window-item :value="1">
        <v-card-title class="justify-center">
          <span>
            {{ $t('$vuetify.signupComponent.title') }}
          </span>
        </v-card-title>
        <v-card-subtitle>
          {{ $t('$vuetify.signupComponent.subTitle') }}
        </v-card-subtitle>
        <v-card-text>
          <v-form ref="signupform" @keyup.native.enter="register">
            <v-text-field
                @keydown.enter.prevent='register'
                v-model="mutableEmail"
                type="email"
                name="email"
                :label="$vuetify.lang.t('$vuetify.emailLabel')"
                required
                outlined
                :hint="$vuetify.lang.t('$vuetify.emailSignUpCaption')"
                :rules="emailRules"
                class="pa-0 ma-0">
            </v-text-field>
            <v-row dense>
              <v-col cols="12">
                <div :style="`text-align: ${$vuetify.rtl?'right':'left'}!important;font-weight: normal;`"
                     :class="{'red--text':validating && phoneError}">{{ $t('$vuetify.signupComponent.form[0]') }}
                </div>
                <v-text-field
                    append-icon="phone"
                    type="tel"
                    name="tel"
                    v-model="phone"
                    outlined
                    reverse
                    :rules="phoneRules"
                    style="direction: ltr;"
                    placeholder="example : +15551234567"
                >
                </v-text-field>

                <!--                                <v-autocomplete-->
                <!--                                    hide-details-->
                <!--                                    id="ccode"-->
                <!--                                    @keydown.enter.prevent='register'-->
                <!--                                    placeholder="کد کشور"-->
                <!--                                    v-model="selectedCountry"-->
                <!--                                    :items="countries"-->
                <!--                                    outlined-->
                <!--                                    :filter="phoneFilter"-->
                <!--                                    item-text="title"-->
                <!--                                    return-object-->
                <!--                                    required-->
                <!--                                    :rules="requiredRule"-->
                <!--                                >-->
                <!--                                  <template v-slot:item="{item}">-->
                <!--                                    <country-flag :country='item.shortCode'></country-flag>-->
                <!--                                    <span>{{ item.title }}</span>-->
                <!--                                  </template>-->
                <!--                                  <template v-slot:selection="{item}">-->
                <!--                                    <v-spacer></v-spacer>-->
                <!--                                    <span style="direction: ltr">{{ item.code }}</span>-->
                <!--                                    &lt;!&ndash;<country-flag :country='item.shortCode' size='normal'></country-flag>&ndash;&gt;-->
                <!--                                  </template>-->
                <!--                                </v-autocomplete>-->
                <!--                              </v-col>-->
                <!--                              <v-col cols="8" dense>-->
                <!--                                <v-text-field-->
                <!--                                    order="2"-->
                <!--                                    prepend-inner-icon="phone"-->
                <!--                                    type="text"-->
                <!--                                    single-line-->
                <!--                                    v-model="phone"-->
                <!--                                    :label="$vuetify.lang.t('$vuetify.phoneLabel')"-->
                <!--                                    class="text-left"-->
                <!--                                    style="text-align: left;direction: ltr;"-->
                <!--                                    outlined-->
                <!--                                    placeholder="ex : (555) 123 4567"-->
                <!--                                    :rules="mobileRules"-->
                <!--                                >-->
                <!--                                  &lt;!&ndash;<template v-slot:prepend>&ndash;&gt;-->
                <!--                                  &lt;!&ndash;&ndash;&gt;-->
                <!--                                  &lt;!&ndash;</template>&ndash;&gt;-->
                <!--                                </v-text-field>-->
              </v-col>
            </v-row>
            <v-text-field

                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                :type="showPass ? 'text' : 'password'"
                v-model="password"
                name="password"
                :label="$vuetify.lang.t('$vuetify.passwordLabel')"
                outlined
                :rules="passwordRules"

            ></v-text-field>
            <v-text-field
                :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConfirmPass = !showConfirmPass"
                :type="showConfirmPass ? 'text' : 'password'"
                v-model="confirmPassword"
                :label="$vuetify.lang.t('$vuetify.passwordConfirmationLabel')"
                outlined
                :rules="passwordConfirmRules"
            ></v-text-field>
            <!--<span class="caption grey&#45;&#45;text text&#45;&#45;darken-1">-->
            <!--{{$vuetify.lang.t('$vuetify.passwordSignUpCaption')}}-->
            <!--</span>-->
            <!--<vue-phone-number-input-->
            <!--no-validator-state-->
            <!--:translations="translations"-->
            <!--no-use-browser-locale-->
            <!--clearable-->
            <!--v-model="phone">-->
            <!--</vue-phone-number-input>-->
            <template v-if="inviter!=null">
              <div class="px-2">
                {{ $t('$vuetify.signupComponent.inviter') }}
                <v-chip small class="blue white--text">{{ inviter }}</v-chip>
              </div>
            </template>


            <v-checkbox v-model="privacyPolicy" class="ma-0 text-center">
              <template v-slot:label>
                <div class="font-weight-regular">
                  <a
                      target="_blank"
                      style="text-decoration: none;"
                      href="https://simiaroom.com/%D8%B4%D8%B1%D8%A7%DB%8C%D8%B7-%D8%B6%D9%88%D8%A7%D8%A8%D8%B7-%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87/"
                      @click.stop
                  >
                    {{ $t('$vuetify.signupComponent.terms') }}
                  </a>
                  {{ $t('$vuetify.signupComponent.accept') }}
                </div>
              </template>
            </v-checkbox>
          </v-form>
          <div v-if="preorderLogin" style="width: 50%; text-align: center;margin: 0 auto;">
            <v-btn
                :loading="btnLoading"
                :disabled="btnLoading"
                color="red"
                class="white--text"
                height="40"
                @click="register"
                block
            >
              {{ $t('$vuetify.signupComponent.action') }}
            </v-btn>
          </div>
          <v-btn
              v-else
              :loading="btnLoading"
              :disabled="btnLoading"
              color="primary"
              @click="register"
              block
              rounded
          >
            <span style="font-size: 1.3em;">
                 {{ $t('$vuetify.signupComponent.action') }}
            </span>
          </v-btn>
        </v-card-text>
        <v-card-text class="justify-center mt-0 mb-0 pb-2 pt-0">
          {{ $t('$vuetify.signupComponent.loginLabel') }}
          <a @click="verifiedCampagin === true ? changeParentWindow(2) : changeParentWindow(1)" class="red--text">
            {{ $t('$vuetify.signupComponent.loginAction') }}
          </a>
        </v-card-text>

        <!--<v-card-text v-show="registerErr.show" class="red&#45;&#45;text text-center">{{ registerErr.msg }}</v-card-text>-->
      </v-window-item>
    </v-window>
  </div>
  <!--</v-card>-->
</template>
<script>
/*eslint-disable*/
import {parsePhoneNumberFromString, AsYouType} from 'libphonenumber-js'
import CountryFlag from 'vue-country-flag';
import {EventBus} from "@/event-bus";
// import Tracker from '../tracker';
import {countriesCodes} from '@/countriesCodes';

export default {
  data() {
    return {
      validating: false,
      phoneError: false,
      btnLoading: false,
      registered_on: new Date(),
      selectedCountry: null,
      countries: countriesCodes,
      inviter: null,
      privacyPolicy: false,
      phone: '',
      password: "",
      confirmPassword: "",
      showPass: false,
      showConfirmPass: false,
      registerErr: {
        show: false,
        msg: '',
      },

      mutableEmail: '',
    }
  },

  props: {
    GASignup: {
      default: 'ga_signup'
    },
    preorderLogin: {
      default: false
    },
    email: String,

    step: {
      type: Number,
      default: 1
    },
    verifiedCampagin: {
      default: false,
    },
    // inviter: {
    //     default: null
    // }
  },

  created() {
    // this.countries = countryCodes;
    this.mutableEmail = this.email;
    this.inviter = this.$store.getters.getInviter;
  },

  watch: {
    phone() {
      // let p = this.phone;

      // if (this.phone.startsWith('00')) {
      //     this.phone = this.phone.substr(2);
      //     this.phone = '+' + this.phone;
      // }
      //
      // if (this.phone.startsWith('098')) {
      //     this.phone = this.phone.substr(1);
      //     this.phone = '+' + this.phone;
      // }
      //
      // if (this.phone.startsWith('09')) {
      //     this.phone = this.phone.substr(1);
      //     this.phone = '+98' + this.phone;
      // }

      // if (this.phone.startsWith(0)) {
      //   this.phone = this.phone.replace(0, '');
      // }
      //
      // let x = new AsYouType().input(this.selectedCountry.code + this.phone);
      // x = x.replace(this.selectedCountry.code, '');
      // this.phone = x;


      if (this.phone.startsWith('00')) {
        this.phone = this.phone.substr(2);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('098')) {
        this.phone = this.phone.substr(1);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('09')) {
        this.phone = this.phone.substr(1);
        this.phone = '+98' + this.phone;
      }

      this.phone = new AsYouType().input(this.phone);
    }
  },
  components: {
    CountryFlag
  },
  filters: {},
  computed: {
    phoneRules() {
      return [
        input => {
          let phone = input.replaceAll(' ', '');
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber) {
            if (!phoneNumber.isValid()) {
              this.phoneError = true;
              return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
            } else {
              this.phoneError = false;
              return true;
            }
          } else {
            this.phoneError = true;
            return this.$t('$vuetify.Notify.signupComponent.phoneRequired');
          }
        }
      ]
    },
    requiredRule() {
      return [
        v => !!v || this.$t('$vuetify.Notify.signupComponent.countrySelected')
      ];
    },
    passwordRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
        v => v.length >= 8 || this.$t('$vuetify.Notify.signupComponent.passwordLengthError')
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ];
    },
    mobileRules() {
      return [
        v => !!v || this.$t('$vuetify.Notify.signupComponent.phoneRequired'),
      ];
    },
    passwordConfirmRules() {
      return [
        v => !!v || this.$t('$vuetify.Notify.signupComponent.passwordRequired'),
        v => (v === this.password) ||  this.$t('$vuetify.Notify.signupComponent.notSamePassword')
      ];
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return this.$vuetify.lang.t('$vuetify.signUpLabel')
        case 2:
          return this.$vuetify.lang.t('$vuetify.setAPassword')
        default:
          return 'Account created'
      }
    },
  },
  methods: {
    isCodeAutoComplete(event) {
      return event.target.id === 'ccode';
    },
    phoneFilter(item, queryText, itemText) {

      if (item == null) {
        return false;
      }
      if (item.header) {
        return false;
      }

      const hasValue = val => val != null ? val : '';

      const text = hasValue(itemText);
      const code = hasValue(item.code);
      const query = hasValue(queryText);

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || code.indexOf(query.toString().toLowerCase()) > -1;
    },
    register(event) {
      if (this.isCodeAutoComplete(event)) {
        return;
      }
      this.validating = true;
      if (this.$refs.signupform.validate()) {
        // let phone = this.selectedCountry.code + this.phone.replace(' ', '');
        let phone = this.phone.replaceAll(' ', '');
        const phoneNumber = parsePhoneNumberFromString(phone);
        if (phoneNumber) {
          if (!phoneNumber.isValid()) {
            EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.signupComponent.phoneNumberError'));
            return;
          }
        } else {
          EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.signupComponent.phoneNumberError'));
          return;
        }

        if (!this.privacyPolicy) {
          EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.acceptTerms'));
          return;
        }

        if (this.confirmPassword === this.password) {

          let newUser = {
            email: this.mutableEmail,
            password: this.password,
            password_confirmation: this.confirmPassword,
            mobile: phoneNumber.number,
          };

          if (this.inviter != null) {
            newUser.inviter = this.inviter;
          }
          // if(this.$router.currentRoute.name == 'preorder') localStorage.setItem('preorder', this.$route.params.orderId);
          EventBus.$emit('setLoading', true);
          this.btnLoading = true;
          this.$store.dispatch('register', newUser).then((data) => {
            // EventBus.$emit('userLoggedIn');
            this.$store.dispatch('removeInviter');
            EventBus.$emit('notify', 'green', 3000, this.$vuetify.lang.t('$vuetify.Notify.signupComponent.success', this.GASignup));

            this.$emit('successfullyRegistered');
            if (['preorder', 'xpreorder', 'mergePreorder', 'ypreorder', 'campaignVerified','Langxpreorder','Langypreorder','Langonboarding'].includes(this.$router.currentRoute.name)) {
              this.$emit('userLoggedInAfterRegister', newUser);
            } else {
              this.$store.commit('setSnackbar', {show: true, type: 'checkEmail'});
            }
            window.webengage.user.login(data.data.userId);
            setTimeout(() => {
              window.webengage.user.setAttribute("registered_on", this.registered_on);
              window.webengage.user.setAttribute("we_email", this.mutableEmail);
              window.webengage.user.setAttribute("we_phone", phoneNumber.number);
              window.webengage.track('registered_on', {
                date: this.registered_on
              });
            }, 100);

            // new Tracker("REGISTRATION", data.data.userId).dispatch()
          }).catch((err) => {
            this.btnLoading = false;
            console.log('sign up err', err.response.status);
            if (err.response.status) {
              this.registerErr = {
                show: true,
                msg: this.$t('$vuetify.Notify.signupComponent.emailError')
              }
            }
            // else if (500 <= err.response.s < 600) {
            //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
            // }
          }).finally(() => {
            this.btnLoading = false;
            EventBus.$emit('setLoading', false);
          })
        } else if (this.confirmPassword !== this.password) {
          this.registerErr = {
            show: true,
            msg: this.$t('$vuetify.Notify.signupComponent.passwordError')
          }
        }
      } else {
        return;
      }


      // if (this.$el.nodeName.toLowerCase() === "form") {
      //     this.$el.onsubmit = cb;
      // } else {
      //     const forms = this.$el.getElementsByTagName("form");
      //     for (let i = 0; i < forms.length; i++) {
      //         const form = forms[i];
      //         if (form) form.onsubmit = cb;
      //     }
      // }


    },
    back() {
      this.step--;
      this.registerErr = {
        show: false,
        msg: ''
      }
    },
    changeParentWindow(value) {
      this.$emit('changeWindow', value);
    }
  },
}
</script>
<style>
/*#k-phone-input .text-field-outlined-dense-label-position-x {*/
/*  color: red !important;*/
/*}*/
</style>