<template>
  <div>
    <v-window v-model="step" :reverse="this.$store.getters.isRTL">
      <v-window-item :value="1">
          <p>
            {{ title }}
          </p>
        <v-card-text class="pb-1">
          <v-form ref="signupform" @keyup.native.enter="register" :class="classTagManager">
            <v-row dense>
              <v-col cols="12" dense>
                <v-text-field
                    style="font-size: 15px;"
                    @keydown.enter.prevent='register'
                    v-model="mutableEmail"
                    type="email"
                    name="email"
                    color="#20b770"
                    required
                    prepend-inner-icon="far fa-envelope"
                    :label="$vuetify.lang.t('$vuetify.emailLabel')"
                    :rules="emailRules"
                    class="pa-0 ma-0">
                </v-text-field>
                <v-text-field
                    style="font-size: 1em;direction: ltr"
                    append-icon="fas fa-mobile-alt"
                    type="tel"
                    name="tel"
                    v-model="phone"
                    :rules="phoneRules"
                    :label="$t('$vuetify.signupComponent.phone')"
                >
                </v-text-field>
                <v-text-field
                    style="font-size: 1em;"
                    :append-icon="showPass ? 'far fa-eye' : 'far fa-eye-slash'"
                    :prepend-inner-icon="'mdi-lock-outline'"
                    @click:append="showPass = !showPass"
                    :type="showPass ? 'text' : 'password'"
                    v-model="password"
                    name="password"
                    :label="$vuetify.lang.t('$vuetify.passwordLabel')"
                    :rules="passwordRules"
                ></v-text-field>

                <v-text-field
                    style="font-size: 1em;"
                    :append-icon="showConfirmPass ? 'far fa-eye' : 'far fa-eye-slash'"
                    :prepend-inner-icon="'mdi-lock-outline'"
                    @click:append="showConfirmPass = !showConfirmPass"
                    :type="showConfirmPass ? 'text' : 'password'"
                    v-model="confirmPassword"
                    :label="$vuetify.lang.t('$vuetify.passwordConfirmationLabel')"
                    :rules="passwordConfirmRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <template v-if="inviter!=null">
              <div class="px-2">
                {{ $t('$vuetify.signupComponent.inviter') }}
                <v-chip small class="blue white--text">{{ inviter }}</v-chip>
              </div>
            </template>

          </v-form>
          <div style="width: 100%; text-align: center;margin: 0 auto;">
            <v-btn
                :loading="btnLoading"
                :disabled="btnLoading"
                color="#20b770"
                class="white--text mt-2"
                height="50"
                @click="register"
                block
                elevation="0"
                style="border-radius: 10px;!important;"
            >
              {{ $t('$vuetify.signupComponent.registerAction') }}

            </v-btn>
          </div>
        </v-card-text>
        <template v-if="! onlyRegister">
          <v-card-text style="font-size:.5em;" class="justify-center mt-0 mb-0 pb-2 pt-0 text-center">
          <span style="color: #6d6d6d">
                          {{ $t('$vuetify.signupComponent.loginLabel') }}
          </span>
            &nbsp;
            <a @click="changeParentWindow(1)"
               style="color: #20b770">
              {{ $t('$vuetify.signupComponent.loginActionOnBoarding') }}
            </a>
          </v-card-text>
        </template>

        <!--<v-card-text v-show="registerErr.show" class="red&#45;&#45;text text-center">{{ registerErr.msg }}</v-card-text>-->
      </v-window-item>
    </v-window>
  </div>
  <!--</v-card>-->

</template>
<script>
/*eslint-disable*/
import {parsePhoneNumberFromString, AsYouType} from 'libphonenumber-js'
import CountryFlag from 'vue-country-flag';
import {EventBus} from "@/event-bus";
// import Tracker from '../tracker';
import {countriesCodes} from '@/countriesCodes';

export default {
  data() {
    return {
      validating: false,
      phoneError: false,
      btnLoading: false,
      registered_on: new Date(),
      selectedCountry: null,
      countries: countriesCodes,
      inviter: null,
      privacyPolicy: false,
      phone: '',
      password: "",
      confirmPassword: "",
      showPass: false,
      showConfirmPass: false,
      classTagManager:'',
      registerErr: {
        show: false,
        msg: '',
      },
      mutableEmail: '',
    }
  },

  mounted() {
    let tempPhone = this.$store.getters.getTempMobile
    if (tempPhone != null){
      this.phone = tempPhone
    }
  },
  props: {
    wizard: {
      default: false
    },
    dense: {
      default: false
    },
    title: {
      default: 'ایجاد حساب کاربری'
    },
    GASignup: {
      default: 'ga_signup'
    },
    preorderLogin: {
      default: false
    },
    email: String,

    step: {
      type: Number,
      default: 1
    },
    onlyRegister: {
      type: Boolean,
      default: false,
      require: false,
    },
    campaignName:{
      type:String,
      require: false,
      default:null
    }
    // inviter: {
    //     default: null
    // }
  },

  created() {

    // this.countries = countryCodes;
    this.mutableEmail = this.email;
    this.inviter = this.$store.getters.getInviter;
  },

  watch: {
    phone() {
      // let p = this.phone;

      // if (this.phone.startsWith('00')) {
      //     this.phone = this.phone.substr(2);
      //     this.phone = '+' + this.phone;
      // }
      //
      // if (this.phone.startsWith('098')) {
      //     this.phone = this.phone.substr(1);
      //     this.phone = '+' + this.phone;
      // }
      //
      // if (this.phone.startsWith('09')) {
      //     this.phone = this.phone.substr(1);
      //     this.phone = '+98' + this.phone;
      // }

      // if (this.phone.startsWith(0)) {
      //   this.phone = this.phone.replace(0, '');
      // }
      //
      // let x = new AsYouType().input(this.selectedCountry.code + this.phone);
      // x = x.replace(this.selectedCountry.code, '');
      // this.phone = x;


      if (this.phone.startsWith('00')) {
        this.phone = this.phone.substr(2);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('098')) {
        this.phone = this.phone.substr(1);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('09')) {
        this.phone = this.phone.substr(1);
        this.phone = '+98' + this.phone;
      }

      this.phone = new AsYouType().input(this.phone);
    }
  },
  components: {
    CountryFlag
  },
  filters: {},
  computed: {
    phoneRules() {
      return [
        input => {
          let phone = input.replaceAll(' ', '');
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber) {
            if (!phoneNumber.isValid()) {
              this.phoneError = true;
              return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
            } else {
              this.phoneError = false;
              return true;
            }
          } else {
            this.phoneError = true;
            return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
          }
        }
      ]
    },
    requiredRule() {
      return [
        v => !!v || this.$t('$vuetify.Notify.signupComponent.countrySelected')

      ];
    },
    passwordRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
        v => v.length >= 8 || this.$t('$vuetify.Notify.signupComponent.passwordLengthError')
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ];
    },
    mobileRules() {
      return [
        v => !!v || this.$t('$vuetify.requiredInputRule'),
      ];
    },
    passwordConfirmRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.Notify.signupComponent.rePasswordRequired'),
        v => (v === this.password) || this.$vuetify.lang.t('$vuetify.Notify.signupComponent.rePasswordRequired')
      ];
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return this.$vuetify.lang.t('$vuetify.signUpLabel')
        case 2:
          return this.$vuetify.lang.t('$vuetify.setAPassword')
        default:
          return 'Account created'
      }
    },
  },
  methods: {
    isCodeAutoComplete(event) {
      return event.target.id === 'ccode';
    },
    phoneFilter(item, queryText, itemText) {

      if (item == null) {
        return false;
      }
      if (item.header) {
        return false;
      }

      const hasValue = val => val != null ? val : '';

      const text = hasValue(itemText);
      const code = hasValue(item.code);
      const query = hasValue(queryText);

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || code.indexOf(query.toString().toLowerCase()) > -1;
    },
    register() {

      // if (this.isCodeAutoComplete(event)) {
      //   return;
      // }
      this.validating = true;
      if (this.$refs.signupform.validate()) {
        // let phone = this.selectedCountry.code + this.phone.replace(' ', '');
        let phone = this.phone.replaceAll(' ', '');
        const phoneNumber = parsePhoneNumberFromString(phone);
        if (phoneNumber) {
          if (!phoneNumber.isValid()) {
            EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.signupComponent.phoneNumberError'));
            return;
          }
        } else {
          EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.signupComponent.phoneNumberError'));
          return;
        }

        // if (!this.privacyPolicy) {
        //   EventBus.$emit('notify', 'red', undefined, 'موافقت با شرایط و ضوابط الزامیست.');
        //   return;
        // }

        if (this.confirmPassword === this.password) {

          let newUser = {
            email: this.mutableEmail,
            password: this.password,
            password_confirmation: this.confirmPassword,
            mobile: phoneNumber.number,
          };
          if (this.campaignName != null){
            Object.assign(newUser,{
              state:this.campaignName
            })
          }

          if (this.inviter != null) {
            newUser.inviter = this.inviter;
          }
          // if(this.$router.currentRoute.name == 'preorder') localStorage.setItem('preorder', this.$route.params.orderId);
          EventBus.$emit('setLoading', true);
          this.btnLoading = true;
          this.$store.dispatch('register', newUser).then((data) => {
            this.$store.dispatch('removeInviter');
            EventBus.$emit('notify', 'green', 3000, this.$t('$vuetify.Notify.signupComponent.success'), this.GASignup);
            if (this.campaignName ==! null){
              this.classTagManager = this.campaignName+"_campaignRegister"
            }

            this.$emit('successfullyRegistered');

            if (['ypreorder', 'preorder', 'xpreorder', 'mergePreorder', 'Langxpreorder', 'Langypreorder', 'Langonboarding'].includes(this.$router.currentRoute)) {
              this.$emit('userLoggedInAfterRegister', newUser);
            } else {
              this.$store.commit('setSnackbar', {show: true, type: 'checkEmail'});
            }
            window.webengage.user.login(data.data.userId);
            setTimeout(() => {
              window.webengage.user.setAttribute("registered_on", this.registered_on);
              window.webengage.user.setAttribute("we_email", this.mutableEmail);
              window.webengage.user.setAttribute("we_phone", phoneNumber.number);
              window.webengage.track('registered_on', {
                date: this.registered_on
              });
            }, 100);

            this.$store.dispatch('login', {
              email: this.mutableEmail,
              password: this.password
            }).then(() => {
              this.$store.dispatch('getUserInfo').then(() => {
                this.$store.commit('setLoggedIn');
                this.$emit('userLoggedIn');
              });
            })
            // new Tracker("REGISTRATION", data.data.userId).dispatch()
          }).catch((err) => {
            this.btnLoading = false;
            console.log('sign up err', err.response.status);
            if (err.response.status) {
              this.registerErr = {
                show: true,
                msg: this.$t('$vuetify.Notify.signupComponent.emailError')
              }
            }
            // else if (500 <= err.response.s < 600) {
            //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
            // }
          }).finally(() => {
            this.btnLoading = false;
            EventBus.$emit('setLoading', false);

          })
        } else if (this.confirmPassword !== this.password) {
          this.registerErr = {
            show: true,
            msg: this.$t('$vuetify.Notify.signupComponent.passwordError')
          }
        }
      } else {
        return;
      }

    },
    back() {
      this.step--;
      this.registerErr = {
        show: false,
        msg: ''
      }
    },
    changeParentWindow(value) {
      this.$emit('changeWindow', value);
    }
  },
}
</script>
<style>
/*#k-phone-input .text-field-outlined-dense-label-position-x {*/
/*  color: red !important;*/
/*}*/

/*.v-text-field.v-input--has-state > .v-input__control > .v-input__slot::before {*/
/*  border-color: #b9b9b9;*/
/*}*/
/*.theme--light.v-text-field > .v-input__control > .v-input__slot::before {*/
/*  border-color: #b9b9b9;*/
/*}*/


.v-label {
  font-size: 14px;
  line-height: 1;
  min-height: 8px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: aqua;
}
</style>