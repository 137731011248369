/*eslint-disable*/
// import MergedLoginPreOrder from "../components/MergedLoginPreOrder";

import MainLandingReferralView from "@/views/campaign/Referal-campaign/MainLandingReferralView";
import invitedReferralView from "@/views/campaign/Referal-campaign/invitedReferralView";
import campaignLayout from "@/views/campaign/campaignLayout";
const zoomComponent = () => import('@/views/zoom/zoomComponent');

const MainLayout = () => import ( '../views/layouts/MainLayout.vue');
const PanelLayout = () => import ( '../views/layouts/PanelLayout.vue');
const Home = () => import ( '../views/Home');
const Dashboard = () => import ( '../views/common/Dashboard.vue');
const Messenger = () => import ( '../views/common/Messenger.vue');
const Sessions = () => import ( '../views/common/Sessions');
const JoinSessionView = () => import ( '../views/common/JoinSessionView');
const StartSession = () => import ( "../views/common/StartSession");
const Clients = () => import ( '../views/common/Clients.vue');
const ClientSubmittedOrPartialCart = () => import ( '../views/client/clientSubmittedOrPartialCart');
const Orders = () => import ( '../views/common/Orders.vue');
const Profile = () => import ( '../views/common/Profile.vue');
const Calendar = () => import ( '../views/common/Calendar.vue');
const clientPricing = () => import ( "../views/client/clientPricing");
const ClientFactors = () => import ( "../views/client/ClientFactors");
const clientCart = () => import ( "../views/client/clientCart");
const userWallet = () => import ( "../views/client/userWallet");
const AssignConsult = () => import ( "../views/common/AssignConsult");
const Cycles = () => import ( "../views/client/Cycles");
const Wallet = () => import ( "../views/client/Wallet");
const Login = () => import ( '../views/Login.vue');
const ResetPassword = () => import ( '../views/ResetPassword.vue');
const ClientProfile = () => import ( "../components/clients/ClientProfile");
const EmailVerify = () => import ( "../components/EmailVerify");
const ExpressSession = () => import ( "../views/client/ExpressSession");
const Temp = () => import ( '../views/Temp');
const SocialLoginRedirect = () => import ( '../views/SocialLoginRedirect');
const AwaitingSessions = () => import ( "../views/common/AwaitingSessions");
const ClientInvitations = () => import ( '../views/client/ClientInvitations');
const PreOrder = () => import("../components/MainPreorder");
const NewPreOrder = () => import("../components/PreOrder");
const V3PreOrder = () => import("../components/V3PreOrder");
const VerifiedCampaign = () => import("../components/VerifiedCampaign");
const OnBoarding = () => import("../views/OnBoarding_old");
// const OnBoarding_new = () => import("../views/OnBoarding");
const OnBoarding_refactor = () => import("../views/OnBoarding_refactor");
// import Requests from "../views/admin/Requests";
// import Counselors from '../views/admin/Counselors.vue'
// import Financial from '../viespainws/admin/Financial.vue'
// import SessionDetails from '../views/admin/SessionDetails.vue'
// import AdminSessionRequests from '../views/admin/SessionRequests'
// import AdminAssignment from '../views/admin/AdminAssignment'
// import CounselorProfile from "../views/admin/CounselorProfile";
// import AddNewClient from "../views/admin/AddNewClient";
// import AdminManageCoupons from "../views/admin/AdminManageCoupons";
// import AdminSingleFactor from "../views/admin/AdminSingleFactor";
// import AdminManagePackages from "../views/admin/AdminManagePackages";
// import AdminAddNewCoupons from "../views/admin/AdminAddNewCoupons";
// import AdminAddNewPackages from "../views/admin/AdminAddNewPackages";
// import ClientDetail from "../views/admin/ClientDetail";
// import UserCalendar from "../views/admin/UserCalendar";
// import AdminClientCalendarPage from "@/views/admin/adminClient/AdminClientCalendarPage.vue";
// import AdminClientCalendar from "@/components/Admin/calendar/calendar.vue";
// import clientProfile from "../components/profiles/clientProfile";
// import PreOrder from "../components/PreOrder";
// import AdminFinancialSection from "../views/admin/AdminFinancialSection";
// import AdminFinancialTransactions from "../views/admin/AdminFinancialTransactions";
// import AdminFinancialAddNewPayment from "../views/admin/AdminFinancialAddNewPayment";
// import adminFinancialRecords from "../views/admin/adminFinancialRecords";
// import AdminPaymentMethods from "../views/admin/AdminPaymentMethods";
// import AdminFinancialRefund from "../views/admin/financials/AdminFinancialRefund";
// import AdminLogger from "../views/admin/AdminLogger";
// import AdminExpressEntry from "../views/admin/adminClient/AdminExpressEntryPage";
// import AdminSepidarReports from '../views/admin/Sepidar/AdminSepidarReports'
// import AdminSepidarPaymentReports from '../views/admin/Sepidar/AdminSepidarPaymentReports'
// import AdminSepidarUserReports from '../views/admin/Sepidar/AdminSepidarUserReports'
// import AdminSepidarSessionReports from '../views/admin/Sepidar/AdminSepidarSessionReports'

const clientRole = 'customer';
const doctorRole = 'counselor';

const panelRoutes = [



    {
        path: '/',
        redirect: 'dashboard',
    },
    {
        path: 'invitations',
        name: 'clientInvitations',
        component: ClientInvitations,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole],
            name:"invitation"
        }
    },
    // {
    //     path: 'wallet',
    //     name: 'clientInvitations',
    //     component: ClientInvitations,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [clientRole],
    //     }
    // },
    {
        // path: '/',
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole, doctorRole, 'admin'],
            name: 'dashboard'
        }
    },
    {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
            requiresGuest: true
        }
    },
    // {
    //     path: 'onboarding',
    //     name: 'onboarding',
    //     component: OnBoarding,
    //     meta: {
    //         // requiresGuest: true
    //     }
    // },


    // {
    //     path: 'emailverify',
    //     name: 'emailverify',
    //     component: EmailVerify,
    // },
    {
        path: 'assignconsult',
        name: 'assignconsult',
        component: AssignConsult,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole]
        }
    },
    {
        path: 'cycles',
        name: 'cycles',
        component: Cycles,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole]
        }
    },
    {
        path: 'purchased-packages',
        name: 'wallet',
        component: Wallet,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole],
            name: 'remainingSessions'
        }
    },
    {
        path: 'order/submit',
        name: 'preOrder',
        component: Login,
        meta: {
            // requiresGuest: true
        }
    },
    {
        path: 'order/products',
        name: 'products',
        component: clientPricing,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole],
            name: 'buySession'
        }
    },
    {
        path: 'factors',
        name: 'clientFactors',
        component: ClientFactors,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole],
            name: 'factors'
        }
    },

    {
        path: 'factors/:factorId',
        name: 'clientSingleFactor',
        component: ClientSubmittedOrPartialCart,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole],
            name: 'basket'
        }
    },
    {
        path: 'order/cart',
        name: 'clientCart',
        component: clientCart,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole],
            name: 'basket'
        }
    },
    {
        path: 'wallet',
        name: 'userMonetaryWallet',
        component: userWallet,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole],
            name:"wallet"
        }
    },
    {
        path: 'orders',
        name: 'orders',
        component: Orders,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole, 'admin']
        }
    },
    // {
    //     path: 'orders/:factorId',
    //     name: 'singleOrder',
    //     component: AdminSingleFactor,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'admin-factors',
    //     name: 'adminFactors',
    //     component: AdminFactors,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'finance',
    //     name: 'adminFinancial',
    //     component: Financial,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'preorder/:orderId',
    //     name: 'preorder',
    //     component: PreOrder
    // },
    {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole, doctorRole, 'admin'],
            name: 'profile'
        }
    },
    {
        path: 'clients',
        name: 'clients',
        component: Clients,
        meta: {
            requiresAuth: true,
            requiredRoles: [doctorRole, 'admin'],
            name: 'clients'
        }
    },
    // {
    //     path: 'nocycle',
    //     name: 'nocycle',
    //     component: Clients,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'notime',
    //     name: 'notime',
    //     component: Clients,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'sessionrequests',
    //     name: 'adminSessionRequests',
    //     component: AdminSessionRequests,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'assignment/user/:userId/cycle/:cycleId/request/:requestId',
    //     name: 'adminAssignment',
    //     component: AdminAssignment,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'clients/:clientId',
    //     name: 'clientDetail',
    //     component: ClientDetail,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    {
        path: 'clients/:clientId/profile',
        name: 'clientProfile',
        component: ClientProfile,
        meta: {
            requiresAuth: true,
            requiredRoles: [doctorRole, 'admin'],
            name: 'userInformation'
        }
    },
    {
        path: 'messenger',
        name: 'messenger',
        component: Messenger,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole, doctorRole, 'admin'],
            name:"Messenger",
        }
    },
    // {
    //     path: 'clients/:clientId/calendar',
    //     name: 'clientCalendar',
    //     component: UserCalendar,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     // path: 'clients',
    //     // component: Login,
    //     // meta: {
    //     //     requiresAuth: true,
    //     //     requiredRoles: ['admin', doctorRole]
    //     // },
    //     // children: [
    //     //
    //     //
    //     // ]
    // },
    // {
    //     path: 'counselors',
    //     name: 'counselors',
    //     component: Counselors,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'counselors/add-counselor',
    //     name: 'addCounselors',
    //     component: CounselorProfile,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'add-client',
    //     name: 'addNewClient',
    //     component: AddNewClient,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'coupons',
    //     name: 'coupons',
    //     component: AdminManageCoupons,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'express-entry',
    //     name: 'express-entry',
    //     component: AdminExpressEntry,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },

    // {
    //     path: 'finance',
    //     name: 'finance',
    //     component: AdminFinancialSection,
    //     redirect: {
    //         name: "adminTransactions"
    //     },
    //     children: [
    //         {
    //             path: 'transactions',
    //             name: 'adminTransactions',
    //             component: AdminFinancialTransactions,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         }
    //
    //     ]
    // },
    //         }, {
    //             path: 'records',
    //             name: 'adminFinancialRecords',
    //             component: adminFinancialRecords,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         },
    //         {
    //             path: 'paymentmethods',
    //             name: 'adminPaymentMethods',
    //             component: AdminPaymentMethods,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         },
    //         {
    //             path: 'add-payment',
    //             name: 'adminAddPayment',
    //             component: AdminFinancialAddNewPayment,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         },
    // {
    //     path: 'refunds',
    //     name: 'adminFinancialRefund',
    //     component: AdminFinancialRefund,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    //     ]
    // },

    // {
    //     path: 'reports',
    //     name: 'reports',
    //     component: AdminSepidarReports,
    //     redirect: {
    //         name: "adminSepidarReports"
    //     },
    //     children: [
    //         {
    //             path: 'payments',
    //             name: 'adminSepidarPayments',
    //             component: AdminSepidarPaymentReports
    //             ,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         }, {
    //             path: 'sessions',
    //             name: 'adminSepidarSessions',
    //             component: AdminSepidarSessionReports,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         },
    //         {
    //             path: 'users',
    //             name: 'adminSepidarUsers',
    //             component: AdminSepidarUserReports,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         },
    //         {
    //             path: 'add-payment',
    //             name: 'adminAddPayment',
    //             component: AdminFinancialAddNewPayment,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         },
    //         {
    //             path: 'refunds',
    //             name: 'adminFinancialRefund',
    //             component: AdminFinancialRefund,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiredRoles: ['admin']
    //             }
    //         },
    //     ]
    // },
    // {
    //     path: 'packages',
    //     name: 'packageManagement',
    //     component: AdminManagePackages,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'add-coupons',
    //     name: 'addNewCoupons',
    //     component: AdminAddNewCoupons,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'add-packages',
    //     name: 'addNewPackage',
    //     component: AdminAddNewPackages,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'logger',
    //     name: 'adminLogger',
    //     component: AdminLogger,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },

    // {
    //     path: 'counselors/:counselorId/profile',
    //     name: 'counselorProfile',
    //     component: CounselorProfile,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'counselors/:counselorId/calendar',
    //     name: 'counselorCalendar',
    //     component: UserCalendar,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'clients/:clientId/calendar',
    //     name: 'adminClientCalendar',
    //     component: AdminClientCalendarPage,
    //     // name: 'clientCalendar',
    //     // component: UserCalendar,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'counselors',
    //     children: [
    //
    //
    //
    //     ]
    // },
    {
        path: 'sessions',
        name: 'sessions',
        component: Sessions,
        meta: {
            requiresAuth: true,
            requiredRoles: [doctorRole, 'admin', clientRole],
            name: 'sessions',
        }
    },
    {
        path: 'awaiting/sessions',
        name: 'awaiting_sessions',
        component: AwaitingSessions,
        meta: {
            requiresAuth: true,
            requiredRoles: [doctorRole, 'admin', clientRole],
            name: 'awaitSession'
        }
    },
    // {
    //     path: 'sessions/:date',
    //     name: 'dateSessions',
    //     component: Sessions,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },
    // {
    //     path: 'requests',
    //     name: 'requests',
    //     component: Requests,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },

    {
        path: 'sessions/start/:sessionId',
        name: 'startsession',
        component: StartSession,
        meta: {
            requiresAuth: true,
            requiredRoles: [doctorRole, 'admin', clientRole]
        }
    },
    // {
    //     path: 'sessions/:sessionId/details',
    //     name: 'adminSessionDetails',
    //     component: SessionDetails,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: ['admin']
    //     }
    // },

    // {
    //     path: 'sessions',
    //     // component: Login,
    //     children: [
    //
    //     ]
    // },
    // {
    //     path: 'sessions/:id',
    //     name: 'sessionInfo',
    //     component: Login,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [doctorRole, 'admin', clientRole]
    //     }
    // },
    // {
    //     path: 'sessions/:id/feedback',
    //     name: 'sessionFeedback',
    //     component: Login,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [doctorRole, clientRole]
    //     }
    // },
    // {
    //     path: 'sessions/reserve',
    //     name: 'reserveSession',
    //     component: Login,
    //     meta: {
    //         requiresAuth: true,
    //         requiredRoles: [clientRole]
    //     }
    // },
    {
        path: 'calendar',
        name: 'calendar',
        component: Calendar,
        meta: {
            requiresAuth: true,
            requiredRoles: [doctorRole, clientRole],
            name: 'calendar'
        }
    },
    {
        path: 'cycle/info',
        name: 'cycleInfo',
        component: Login,
        meta: {
            requiresAuth: true,
            requiredRoles: [clientRole]
        }
    }


];

const landingPages = [

];

const campaignPages = [
    {
        path:'referral-campaign',
        component:MainLandingReferralView,
    },
    {
        path: 'referral-campaign/invite',
        component: invitedReferralView,
    }
]

const routes = [
    // {
    //     path: "/:lang",
    //     redirect: {
    //         name: "login"
    //     }
    // },
    // {
    //     path: "/:lang",
    //     // redirect: to => {
    //     //     if(!to.path.includes('emailverify')) {
    //     //         return {name: 'login'}
    //     //     }else {
    //     //         return {name: 'emailverify'}
    //     //     }
    //     // }
    // },
    {
        path: '/password/reset/:token',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/emailverify/:token',
        name: 'emailverify',
        component: EmailVerify,
    },
    {
        path: '/preorder/:orderId',
        name: 'preorder',
        component: PreOrder
    },
    {
        path: '/preorder/sign/:orderId',
        name: 'xpreorder',
        component: NewPreOrder
    },
    {
        path: '/purchase/sign/:orderId',
        name: 'ypreorder',
        component: V3PreOrder
    },

    // {
    //     path: '/preorder/merge/:orderId',
    //     name: 'mergePreorder',
    //     component: MergedLoginPreOrder
    // },
    {
        path: '/expresssession',
        name: 'expressSession',
        component: ExpressSession
    },
    {
        path: '/join-session/:sessionCode',
        name: 'joinSession',
        component: JoinSessionView,
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/assignconsult',
        name: 'preassignconsult',
        component: AssignConsult
    },
    {
        path: '/temppath',
        name: 'temppath',
        component: Temp,
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        component: OnBoarding,
    },
    {
        path: '/social-login',
        name: 'socialLoginRedirect',
        component: SocialLoginRedirect,
    },
    {
        path: '/:lang',
        name: 'lang',
        component: MainLayout,
        children: [
            {
                path: '/',
                redirect: '/fa/panel/login'
            },
            {
                path: 'panel',
                name: 'panel',
                component: PanelLayout,
                children: panelRoutes
            },
            {
                path: 'sessions/enter',
                name: 'videoCall',
                component:zoomComponent,
                meta:{
                    needCallInfo:true,
                }
            },




            {
                path: 'campaign',
                name: "campaign",
                component:campaignLayout,
                children: campaignPages,
            },
            {
                path: '/',
                name: 'home',
                component: Home,
                children: landingPages
            },
            //labeling test for pages who has not extends :lang
            {
                path: 'onboarding',
                name: 'Langonboarding',
                component: OnBoarding,
            },
            {
                path: 'onboarding_new',
                name: 'Langonboarding_new',
                component: OnBoarding_refactor,
            },
            {
                path: 'preorder/sign/:orderId',
                name: 'Langxpreorder',
                component: NewPreOrder
            },
            {
                path: 'purchase/sign/:orderId',
                name: 'Langypreorder',
                component: V3PreOrder
            },
            {
                path: 'special-campaign',
                name: 'campaignVerified',
                component: VerifiedCampaign
            },
            //labeling test for pages who has not extends :lang

        ]
    },

];

export default routes