<template>
  <div>
    <HeaderLogoArea/>
    <div class="header">
      <div class="heroHeader">
        <div class="d-flex justify-space-around">
          <div>
            <span style="font-weight: 500;font-size: 12pt !important;">تبریک به تو! </span>
            <br>
            این خیلی ارزشمند است که به فکر حال خوب خودت هستی
          </div>
        </div>
        <br>
      </div>
      <div class="d-flex justify-space-around">
        <img src="@/assets/zeroPayment/toHandForMe.png" class="handVector" alt="">
      </div>
    </div>
    <div>
      <div class="subTitles">
        <div class="d-flex justify-space-around">
          <div>
            <p style="font-weight: 300">
              تو قدم اول را برداشته‌ای؛ قدم بعدی، کمک گرفتن از یک مشاور متخصص و باتجربه است.
              ما برای همراهی در این مسیر، ۲ هدیه ۲۵دلاری برای تو در نظر گرفته‌ایم که در دو
              جلسه اول قابل استفاده است.
            </p>
            <p class="" style="font-size: 10px">
              (با پرداخت هزینه یک جلسه، دو جلسه مشاوره برگزار خواهد شد)
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="form-area text-center">
      <p>شماره تلفن خود را وارد کنید</p>
      <div class="d-flex justify-space-around">
        <div class="inputArea">
          <v-form ref="tellPhone" @keydown.enter="submitPhoneNumber" @submit.prevent="submitPhoneNumber">
            <v-text-field :rules="phoneRules" v-model="phone" reverse style="direction: ltr;" outlined dense clearable
                          placeholder="+15551234557" @keydown.enter="submitPhoneNumber"></v-text-field>
          </v-form>
        </div>
      </div>
      <div class="d-flex justify-space-around">
        <v-btn class="submitPhoneNumber" @click="submitPhoneNumber" elevation="0">
          تایید و دریافت هدیه
        </v-btn>
      </div>

    </div>
    <div class="note">
      <p class="titleArea" style="font-size: 14px;">
        به این نکات مهم دقت کن:
      </p>
      <div class="contentArea">
        <div class="item">
          <div class="d-flex">
            <div>
              <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
            </div>
            <p style="font-size: 12px;">
              هدیه‌ای که به دوستت می‌دهی، کمک گرفتن از یک مشاور متخصص و باتجربه است.
            </p>
          </div>
          <div class="item">
            <div class="d-flex">
              <div>
                <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
              </div>
              <p style="font-size: 12px;">
                ما برای دوستت ۲ هدیه ۲۵دلاری در نظر گرفته‌ایم که در دو جلسه اول مشاوره قابل استفاده
                است. (به عبارتی با پرداخت هزینه یک جلسه، دو جلسه مشاوره برای او برگزار خواهد شد.)
              </p>
            </div>

          </div>
          <div class="item">
            <div class="d-flex">
              <div>
                <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
              </div>
              <p style="font-size: 12px;">
                این جلسه با مشاوران اصلی سیمیاروم برگزار می‌شود، یعنی بهترین مشاوران فارسی زبان که
                رزومه تمام آن‌ها را می‌توانی در صفحه مشاوران سیمیاروم ببینی.
              </p>
            </div>
          </div>
          <div class="item">
            <div class="d-flex">
              <div>
                <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
              </div>
              <p style="font-size: 12px;">
                هزینه‌های جلسه به طور کامل به مشاور شما پرداخت می‌شود و ایشان هیچ اطلاعی از تخفیف
                جلسه تو ندارد، بنابراین کوچک‌ترین تغییری در کیفیت مشاوره ایجاد نمی‌شود.
              </p>
            </div>
          </div>
          <div class="item">
            <div class="d-flex">
              <div>
                <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
              </div>
              <p style="font-size: 12px;">
                اولین جلسه درمان اغلب صرف شنیدن حرف‌های مراجعه کننده می‌شود تا مشاور بتواند مشکل را شناسایی و طرح درمان
                را
                مشخص کند. پس توصیه می‌کنیم حتما در دو جلسه اول شرکت کنی.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="text-center">
      <v-btn text small @click="refreshPage">بازگشت</v-btn>
    </div>
    <div class="footer">

    </div>
  </div>
</template>

<script>
import HeaderLogoArea from "@/components/campaign/referal-campaign/headerLogoArea";
import {AsYouType, parsePhoneNumber, parsePhoneNumberFromString} from "libphonenumber-js";

export default {
  components: {HeaderLogoArea},
  data() {
    return {
      phone: null,
    }
  },
  methods: {
    refreshPage() {
      window.location.reload()
    },
    submitPhoneNumber() {
      if (this.$refs.tellPhone.validate()) {
        let country = parsePhoneNumber(this.phone).country
        if (country == "IR") {
          this.$emit('stepperHandler', 1);
        } else {
          this.$store.commit('SET_TEMP_MOBILE', this.phone)
          this.$emit('stepperHandler', 2)
        }
      }
    }
  },
  computed: {
    phoneRules() {
      return [
        input => {
          if (input != null) {
            let phone = input.replaceAll(' ', '');
            const phoneNumber = parsePhoneNumberFromString(phone);
            if (phoneNumber) {
              if (!phoneNumber.isValid()) {
                return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
              } else {
                return true;
              }
            } else {
              return this.$t('$vuetify.Notify.signupComponent.phoneRequired');
            }
          } else {
            return 'وارد کردن تلفن الزامیست'
          }
        }
      ]
    },
  },
  watch: {
    phone() {
      if (this.phone != null) {
        if (this.phone.startsWith('00')) {
          this.phone = this.phone.substr(2);
          this.phone = '+' + this.phone;
        }

        if (this.phone.startsWith('098')) {
          this.phone = this.phone.substr(1);
          this.phone = '+' + this.phone;
        }

        if (this.phone.startsWith('09')) {
          this.phone = this.phone.substr(1);
          this.phone = '+98' + this.phone;
        }

        this.phone = new AsYouType().input(this.phone);
      }
    }
  }

}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {

}

.heroHeader {
  padding: 40px !important;

  font-size: 12px !important;
}

.handVector {
  margin-top: -50px !important;
}

.subTitles {
  padding: 0px 80px 0px 80px !important;
  text-align: center;
  font-size: 12px !important;
  font-weight: 300;
  margin-top: -25px;
  color: rgba(0, 0, 0, 0.84);
  line-height: 20px;

}

.handVector {
  margin-top: -40px !important;
  width: 600px !important;
}

.note {
  padding: 10px;
  margin: 35px;
}

.note .titleArea {
  font-size: 10px;
  text-align: center;
}

.contentArea .item p {
  font-size: 10px;
  font-weight: 300;
}

.footer {
  margin-top: 10px !important;
}

.form-area {
  font-size: 12px;
}

.inputArea {
  border-radius: 6px;
  width: 180px;
  padding: 0px;
}

.heroHeader {
  padding: 100px 100px 200px 100px;

  text-align: center;
  color: rgba(0, 0, 0, 0.84);
  font-weight: 300;
}

.header {
  background: url("../../../../assets/zeroPayment/pattern.png");
  background-size: cover;
  background-position: center;
}

/*.subTitles {*/
/*  padding: 0px 200px 0px 200px;*/
/*}*/

.handVector {
  width: 100%;
  margin-top: -200px;
}

.note {
  background: rgba(230, 230, 230, 0.5);
  border-radius: 10px;
}

.submitPhoneNumber {
  width: 180px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #39b787 !important;
  font-size: 12px !important;
  text-align: center;
  padding-top: 7px;
  color: white !important;
  /*margin-top: -20px;*/
}

.footer {
  background: #39b787;
  width: 100%;
  height: 30px;
  margin-top: 60px;
}

.v-text-field--outlined >>> fieldset {
  border: solid 1px #707070 !important;
  border-radius: 6px !important;
  /*height: 40px !important;*/

}
</style>