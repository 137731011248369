<template>
  <div class="d-flex justify-space-around">
    <div class="contentWindow">
      <v-window v-model="windowHandler" touchless>
        <v-window-item :value="0" transition="scroll-x-transition">
          <MainLandingReferral @windowSwitcher="windowSwitcher($event)"/>
        </v-window-item>
        <v-window-item :value="1" transition="scroll-y-transition">
          <InviteFriendJourney/>
        </v-window-item>
        <v-window-item :value="2" transition="scroll-y-transition">
          <ForMeJourney/>
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script>
import MainLandingReferral from "@/components/campaign/referal-campaign/MainLandingReferral";
import {EventBus} from "@/event-bus";
import InviteFriendJourney from "@/components/campaign/referal-campaign/inviteFriendJourney";
import ForMeJourney from "@/components/campaign/referal-campaign/forMeJourney";
export default {
  components: {ForMeJourney, InviteFriendJourney, MainLandingReferral},
  name:'mainLandingReferralView',
  data(){
    return{
      windowHandler:0,
    }
  },
  methods:{
    windowSwitcher(val){
      this.windowHandler = val
    }
  },
  mounted() {
    EventBus.$on('numberFromIran',() => {
      this.windowHandler = 1
    })
  }
}
</script>

<style scoped>
@media (min-width: 200px)  and (max-width: 600px){
  .contentWindow{
    width: 100% !important;
  }
}
.contentWindow{
  width: 500px;
  overflow: hidden
}
</style>