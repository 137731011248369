  <template>
  <div>
    <HeaderLogoArea/>
    <div class="header">
      <div class="d-flex space-around">
        <div class="heroHeader">

          <div class="desc">
            رفتن پیش مشاور روانشناس (تراپیست) یک تصمیم مهم است و می‌تواند با ترس و تردید بسیاری همراه باشد. خیلی از ما
            به همین دلیل هیچ‌وقت تراپی را شروع نکرده‌ایم، با اینکه واقعا به آن نیاز داریم.


          </div>
          <div class="desc">
            مثل هر تجربه جدیدی، بودن یک دوست و رفیق همپا در کنار فرد، نگرانی از شروع این راه را کم می‌کند و انگیزه را
            افزایش می‌دهد. ما در حال حاضر یا خودمان به مشاوره نیاز داریم، یا در بستگان و دوستان خود افرادی را
            داریم که به کمک یک مشاور حرفه‌ای نیاز دارند تا حال‌شان بهتر شود.
          </div>
          <h2 style="margin: 10px 0px;">دوست داری به چه کسی کمک کنی؟</h2>
          <div class="actionArea">
            <div class="container">
              <div class="d-flex justify-center">
                <!--                  <div>-->
                <div class="toMe white--text referralCampaign-formeBtn" @click="windowSwitcher(2)">
                  به خودم
                </div>
                <div class="toOther white--text mr-lg-10 referralCampaign-inviteFriendBtn" @click="windowSwitcher(1)">
                  به دیگران
                </div>
                <!--                  </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="@/assets/zeroPayment/MaskGroup.png" class="handVector mt-0" alt="">
    </div>
    <v-container>
      <div class="simiaroom mx-10  text-center">
        <h2>
          سیمیاروم؛ اتاق آنلاین روانشناسی
        </h2>

        <div class="d-flex space-around mt-4">
          <p class="desc" style="font-size: 13px!important;">
            سیمیاروم اولین کلینیک آنلاین روان‌شناسی به زبان فارسی است که از ۴ سال پیش، با گزینش برترین مشاوران ایرانی و
            ایجاد دسترسی آسان به آنان از سراسر دنیا، به معتبرترین سرویس آنلاین روان‌شناسی برای ایرانیان مقیم خارج از
            کشور تبدیل شده است.
          </p>
        </div>
      </div>
    </v-container>
    <v-container class="">
      <div class="statistics mx-10 text-center ">
        <p class="titleStatistics">سیمیاروم تا امروز </p>
        <v-row>
          <v-col cols="6" lg="3" class="text-center">
            <div class="d-flex space-around">
              <div class="vectorArea">
                <img src="@/assets/zeroPayment/back-in-time.png">
              </div>
            </div>
            <div class="content">
              <span class="l-font-size-10">برگزاری</span>
              <br>

              <span class="count ">35000</span>
              <span class="font-weight-bold l-font-size-10">ساعت مشاوره</span>
            </div>
          </v-col>
          <v-col cols="6" lg="3" class="text-center">
            <div class="d-flex space-around">
              <div class="vectorArea">
                <img src="@/assets/zeroPayment/support.png">
              </div>
            </div>
            <div class="content">
              <span class="l-font-size-10">ارائه خدمات به</span>
              <br>
              <span class="count ">6000</span>
              <span class="font-weight-bold l-font-size-10">مراجعه‌کننده</span>
            </div>
          </v-col>
          <v-col cols="6" lg="3" class="text-center">
            <div class="d-flex space-around">
              <div class="vectorArea">
                <img src="@/assets/zeroPayment/rating.png">
              </div>
            </div>
            <div class="content">
              <span class="l-font-size-10">کسب‌رضایت</span>
              <br>

              <span class="count ">۹۵</span>
              <span class="font-weight-bold l-font-size-10">درصدی مراجعان</span>
            </div>
          </v-col>
          <v-col cols="6" lg="3" class="text-center">
            <div class="d-flex space-around">
              <div class="vectorArea">
                <img src="@/assets/zeroPayment/Layer.png">
              </div>
            </div>
            <div class="content">
              <span class="l-font-size-10">              همکاری با</span>
              <br>
              <span class="count ">50</span>
              <span class="font-weight-bold l-font-size-10">مشاور برتر ایرانی</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div class="footer">
    </div>
  </div>
</template>

<script>
import HeaderLogoArea from "@/components/campaign/referal-campaign/headerLogoArea";

export default {
  name: "mainLandingReferral ",
  components: {HeaderLogoArea},
  methods: {
    windowSwitcher(val) {
      this.$emit('windowSwitcher', val)
    }
  }

}
</script>
<style scoped>

.toMe {
  width: 40% !important;
  /*height: 30px !important;*/
  font-size: 13px !important;
  border-radius: 20px !important;
  padding: 7px !important;

  background: #39b787;
  text-align: center;
  z-index: 999;
}

.toMe:hover {
  cursor: pointer;
}

.toOther:hover {
  cursor: pointer;
}

.toOther {
  width: 40% !important;
  /*height: 30px !important;*/
  font-size: 13px !important;
  border-radius: 20px !important;
  margin-right: 10px;
  padding: 7px !important;
  background: #4a61ad;
  text-align: center;
  z-index: 999;
}

.heroHeader > h2 {
  font-size: 15px !important;
  margin-top: -10px !important;
}

.heroHeader p.desc {
  font-size: 10px !important;
}

.heroHeader .actionArea {
  margin-top: -10px !important;
}

.handVector {
  margin-top: -50px !important;
}

.simiaroom {
  padding: 20px 20px 15px 20px !important;
  border-radius: 10px !important;
}

.simiaroom h2 {
  font-size: 13px !important;
}

.simiaroom .subTitle {
  font-size: 8px !important;
}

.simiaroom .desc {
  margin-top: -10px !important;
  font-size: 8px !important;
  width: 100% !important;
  line-height: 1.6 !important;
}

.vectorArea {
  width: 50px !important;
  height: 50px !important;
  padding: 8px !important;
}

.vectorArea img {
  width: 100% !important;
}

.content {
  font-size: 8px !important;
}

.content .count {
  color: #4669b1;
  font-size: 12px !important;
  font-weight: bolder;
}

.content .subTitle {
  font-size: 8px !important;
  text-align: justify !important;
}

.footer {
  margin-top: 10px !important;
}

.titleStatistics {
  font-size: 12px !important;
}

.header {

  background: url("../../../assets/zeroPayment/pattern.png");
  background-size: cover;
  background-position: center;
}


.d-flex {
  display: flex;
}

.space-around {
  justify-content: space-around;
}

.heroHeader {
  text-align: center;
  width: 800px;
  margin-top: 20px;
}

.heroHeader > h2 {
  font-size: 30px;
  color: #4a61ad;
  margin-top: 30px;
}

.heroHeader .desc {
  font-size: 12px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.84);
  line-height: 2;
  margin-top: 20px;
  padding: 0px 20px;
}

.heroHeader p.subDesc {
  font-size: 15px;
  color: rgba(74, 97, 173, 0.84);
  line-height: 2;
}

.heroHeader .actionArea > h2 {
  font-size: 30px;
  margin-top: 30px;
}

.toOther {
  /*background: #4a61ad;*/
  /*width: 250px;*/
  /*height: 70px;*/
  /*text-align: center;*/
  /*padding: 20px 20px;*/
  /*font-size: 20px;*/
  /*border-radius: 10px;*/
  /*z-index: 999;*/
}

.toMe {

}

.handVector {
  width: 100%;
  margin-top: -200px;
  z-index: 0;
}

.simiaroom {
  background: rgba(230, 230, 230, 0.5);
  border-radius: 20px;
  padding: 30px 30px 15px 30px;
}

.simiaroom h2 {
  font-size: 30px;
}

.simiaroom .subTitle {
  color: #646464;
}

.simiaroom .desc {
  width: 70%;
  font-size: 18px;
  line-height: 30px;
  font-weight: 200;
}

.titleStatistics {
  color: #464646;
  font-size: 30px;
}

.vectorArea {
  background: #d0e1f0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 20px;
  text-align: center;
}

.vectorArea img {
  width: 100%;
}

.content {
  font-size: 15px;
}

.content .count {
  color: #4669b1;
  font-size: 35px;
  font-weight: bolder;
}

.content .subTitle {
  font-size: 25px;
  text-align: justify !important;
}

.footer {
  background: #39b787;
  width: 100%;
  height: 30px;
  margin-top: 60px;
}

.l-font-size-10 {
  font-size: 10px;
}
</style>