<template>
    <v-container class="overflow-x-hidden">
        <v-form ref="profileForm">
            <v-row class="align-right text-right" style="position: relative">
                <v-col cols="12" lg="12" class="text-right">
                    <v-avatar width="150" height="150">
                        <!--                        <img src='./../../../assets/avatar-default.png' v-if="isNew || !userInfo.avatar"/>-->
                        <img
                            style="object-fit: cover"
                            :src="imgUrl"
                            alt="avatar"
                        >
                    </v-avatar>
                </v-col>
                <v-col class="addImg">
                    <input ref="imgInput" class="d-none" type="file" @change="onFileSelected"
                           accept="image/gif, image/jpeg, image/png">
                    <v-btn small class="mx-2" fab dark color="warning" @click="$refs.imgInput.click()">
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card-title class="pa-0 font-weight-bold">
                        <h3 class="card-title pmd-title-separator">
                            {{ $vuetify.lang.t('$vuetify.profileInformationTitle') }}</h3>
                    </v-card-title>
                </v-col>
            </v-row>
            <v-row class="px-md-10">
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        v-model="userInfo.firstname"
                        outlined
                        :label="$vuetify.lang.t('$vuetify.profileFirstname')"
                        :rules="requiredRules"

                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        v-model="userInfo.lastname"
                        outlined
                        :label="$vuetify.lang.t('$vuetify.profileLastname')"
                        :rules="requiredRules"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        v-model="userInfo.cel"
                        outlined
                        style="direction: ltr"
                        :label="$vuetify.lang.t('$vuetify.profileMobile')"
                        :rules="requiredRules"
                    ></v-text-field>
                </v-col>
                <!--                <v-col cols="12" sm="6" md="6">-->
                <!--                    <v-text-field-->
                <!--                            disabled-->
                <!--                            v-model="userInfo.email"-->
                <!--                            outlined-->
                <!--                            :label="$vuetify.lang.t('$vuetify.profileEmail')"-->
                <!--                    ></v-text-field>-->
                <!--                </v-col>-->
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        :readonly="$router.currentRoute.name === 'profile'"
                        v-model="userInfo.email"
                        outlined
                        :label="$vuetify.lang.t('$vuetify.profileEmail')"
                        :rules="emailRules"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="isNew">
                    <v-select
                        :items="speciality"
                        item-value="val"
                        item-text="cap"
                        v-model="userInfo.speciality"
                        outlined
                        :label="$vuetify.lang.t('$vuetify.profileSpeciality')"
                        :rules="requiredRules"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                        append-icon="map"
                        outlined
                        v-model="userInfo.tz"
                        :items="timezones"
                        item-value="value"
                        menu-props="auto"
                        :label="$vuetify.lang.t('$vuetify.profileTZ')"
                        hide-details
                        single-line
                        :rules="requiredRules"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="isNew">
                    <v-text-field
                        v-model="userInfo.password"
                        outlined
                        :label="$vuetify.lang.t('$vuetify.passwordLabel')"
                        :rules="passwordRules"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="isNew">
                    <v-text-field
                        v-model="confirmPassword"
                        outlined
                        :label="$vuetify.lang.t('$vuetify.passwordConfirmationLabel')"
                        :rules="passwordConfirmRules"
                    ></v-text-field>
                </v-col>
            </v-row>
            <!--<v-row>-->
            <!--<v-col cols="12">-->
            <!--<v-card-text class="pa-0 font-weight-bold">-->
            <!--{{$vuetify.lang.t('$vuetify.profileExplanation')}}-->
            <!--</v-card-text>-->
            <!--</v-col>-->
            <!--</v-row>-->
            <!--<v-row class="px-md-10">-->
            <!--<v-col cols="12">-->
            <!--<v-textarea-->
            <!--rows="1"-->
            <!--outlined-->
            <!--v-model="userInfo.description"-->
            <!--&gt;</v-textarea>-->
            <!--</v-col>-->
            <!--</v-row>-->

            <template v-if="!adding">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="pa-0 font-weight-bold">
                            <h3 class="card-title pmd-title-separator">
                                {{ $vuetify.lang.t('$vuetify.profileResume') }}</h3>
                        </v-card-title>
                    </v-col>
                </v-row>

                <v-row class="px-md-10">
                    <v-col cols="12">
                        <v-text-field
                            :label="$vuetify.lang.t('$vuetify.doctorPanel.addToResume')"
                            v-model="newItem.title"
                            outlined
                        >
                            <template v-slot:append>
                                <v-fade-transition leave-absolute>
                                    <v-chip class="mb-3" label color="primary" @click="addToResume">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-chip>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="px-md-10" v-for="(item, index) in resume" :key="`resume-${index}`">
                    <v-col cols="12" class="py-0">
                        <v-card
                            class="grow py-0"
                            flat
                        >
                            <v-card-actions class="py-0">
                                <v-container class="px-0 py-0">
                                    <v-row class="my-0">
                                        <v-col sm="8" md="8">
                                            <p class="text--secondary my-0">{{ item.title }}</p>
                                        </v-col>
                                        <v-col sm="4" md="4" class="text-end">
                                            <v-icon class="mx-2" color="success" @click="editResume(item)">
                                                mdi-square-edit-outline
                                            </v-icon>
                                            <v-icon class="mx-2" color="red" @click="selToDelete(item)">mdi-close
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

            </template>
            <!--            <v-snackbar-->
            <!--                    :color="snackbarType"-->
            <!--                    id="snackbar-calendar"-->
            <!--                    bottom-->
            <!--                    v-model="snackbar"-->
            <!--                    :timeout="timeout"-->
            <!--            >-->
            <!--                {{ text }}-->
            <!--                <v-btn-->
            <!--                        fab-->
            <!--                        :color="snackbarType"-->
            <!--                        @click="snackbar = false"-->
            <!--                >-->
            <!--                    <v-icon>{{ iconType }}</v-icon>-->
            <!--                </v-btn>-->
            <!--            </v-snackbar>-->
            <!--                <Snackbar :type="type" :snackbar="snackbar"></Snackbar>-->
        </v-form>

        <v-dialog
            v-model="dialogDeleteConfirm"
            max-width="500px"
        >
            <v-card>
                <v-card-text>
                    <h3 class="pb-0 pt-5 text-center">از حذف این مورد از رزومه اطمینان دارید؟</h3>
                    <v-spacer></v-spacer>
                </v-card-text>
                <v-card-actions class="justify-center py-5">
                    <v-btn color="primary" @click="removeResume(delResume)">
                        {{ $t('$vuetify.buttons.yes') }}
                    </v-btn>
                    <v-btn color="primary" outlined @click="dialogDeleteConfirm = false">
                        {{ $t('$vuetify.buttons.no') }}
                    </v-btn>
                </v-card-actions>

                <v-checkbox
                    class="py-2 px-2"
                    v-model="notAsk"
                    label="دوباره این سوال را نپرس"
                    color="primary"
                    :value="notAsk"
                    hide-details
                ></v-checkbox>
            </v-card>
        </v-dialog>
        <AlertsComponent></AlertsComponent>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "@/event-bus";
    import countries from "@/data/countries";
    import timezones from "@/data/timezones";
    import moment from 'moment-timezone';
    import AlertsComponent from "@/components/AlertsComponent";
    // import Snackbar from "../../Snackbar";

    export default {
        beforeDestroy() {
            EventBus.$off('saveProfileEvent', this.saveProfile);
            EventBus.$off('resetProfileEvent', this.resetProfile);
        },
        created() {

            this.adding = this.$router.currentRoute.name == 'addCounselors' ? true : false;
            if (this.$router.currentRoute.name == 'profile') {
                this.admin = false;
            } else {
                this.admin = true;
                this.counselorId = this.$router.currentRoute.params.counselorId;
            }

            if (!this.adding) {
                this.getResume();
            }
            this.getUserInfo();

            let x = JSON.parse(JSON.stringify(timezones));
            this.timezones = x.map((item) => {
                item.text = item.text + ' ' + moment.tz(item.text).format('Z');
                return item;
            });

            EventBus.$on('saveProfileEvent', this.saveProfile);
            EventBus.$on('resetProfileEvent', this.resetProfile);
        },
        components: {
            AlertsComponent

            // Snackbar
        },
        data() {
            return {
                emailRules: [
                    v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
                    v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
                ],
                adding: false,
                admin: null,
                mode: 'add',
                newItem: {
                    id: null,
                    title: null
                },
                dialogDeleteConfirm: false,
                notAsk: false,
                avatarImg: '',
                newImage: null,
                genders: ['مرد', 'زن'],
                gender: '',
                countries,
                states: ['Tehran', 'Qazvin', 'Ahvaz'],
                timezones: timezones,
                city: '',
                education: [
                    {value: "none", title: 'فاقد مدرک'},
                    {value: "diploma", title: 'دیپلم'},
                    {value: "associate", title: 'فوق دیپلم'},
                    {value: "bachelor", title: 'لیسانس'},
                    {value: "mastery", title: 'فوق لیسانس'},
                    {value: "Doctorate", title: 'دکتری'}
                ],
                knway: [
                    {value: "google", title: "جست و جو در  گوگل"},
                    {value: "twitter", title: "توییتر"},
                    {value: "instagram", title: "اینستاگرام"},
                    {value: "podcast", title: "پادکست"},
                    {value: "bannerads", title: "تبلیغات بنری"},
                    {value: "friends", title: "معرفی دوستان و آشنایان"},
                    {value: "site", title: "سایت"},
                    {value: "other", title: "موارد دیگر"},
                ],
                speciality: [
                    {cap: "اختلالات شخصیت", val: "اختلالات شخصیت"},
                    {cap: "خانواده، ازدواج، زوج درمانی", val: "خانواده، ازدواج، زوج درمانی"},
                    {cap: "فردی", val: "فردی"},
                    {cap: "کودک و نوجوان، رشد، فرزند پروری", val: "کودک و نوجوان، رشد، فرزند پروری"},
                    {cap: "مهارت های زندگی", val: "مهارت های زندگی"}
                ],
                userInfo: {},
                resume: [],
                delResume: null,
                locale: this.$store.getters.getLocal,
                modal: false,
                snackbar: false,
                type: 'success',
                // timeout: 2000,
                confirmPassword: '',
                counselorId: ''
            };
        },
        computed: {
            passwordRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
                    v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
                ];
            },
            passwordConfirmRules() {
                return [
                    v => !!v || 'پر کردن فیلد تکرار کلمه عبور الزامیست',
                    v => (v === this.userInfo.password) || 'کلمه عبور و تکرار آن یکسان نمی باشند'
                ];
            },
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            isNew() {
                return this.$router.currentRoute.name == 'addCounselors' ? true : false;
            },
            imgUrl() {
                if (!this.userInfo.hasOwnProperty('avatar') && this.newImage == '') {
                    return require('./../../../assets/avatar-default.png');
                } else if (this.newImage) {
                    return this.newImage;
                } else {
                    return this.userInfo.avatar;
                }
            }
        },
        methods: {
            getResume() {
                if (!this.admin) {
                    this.$store.dispatch('counselorGetResume').then((resp) => {
                        this.resume = resp.data;
                        this.newItem.id = '';
                        this.newItem.title = '';
                    })
                } else {

                    this.$store.dispatch('adminCounselorGetResume', this.counselorId).then((resp) => {
                        this.resume = resp.data;
                        this.newItem.id = '';
                        this.newItem.title = '';
                    })
                }
            },
            getUserInfo() {

                if (this.$router.currentRoute.name == 'profile') {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getUserInfo').then(() => {
                        this.userInfo = this.$store.getters.getUserInfo;
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                } else if (this.$router.currentRoute.name == 'counselorProfile') {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getCounselorProfile', {
                        userid: this.$router.currentRoute.params.counselorId
                    }).then((data) => {
                        this.userInfo = data.data.doctor;
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                } else if (this.$router.currentRoute.name == 'addCounselors') {
                    this.userInfo = {}
                }

            },
            saveProfile() {

                let data = {
                    "name": this.userInfo.firstname,
                    "family": this.userInfo.lastname,
                    "mobile": this.userInfo.cel,
                    "timezone": this.userInfo.tz
                };


                if (this.$refs.profileForm.validate()) {

                    if (this.$router.currentRoute.name == 'profile') {

                        // console.log('counselor is saving', this.userInfo);
                        //
                        if (this.newImage != null) data.avatar = this.newImage;
                        this.$store.dispatch('saveCounselorProfile', data).then(() => {
                            this.$store.dispatch('getUserInfo').then(() => {
                                this.userInfo = this.$store.getters.getUserInfo;
                            })
                            this.snackbar = true;
                            this.type = 'success'
                            this.$store.commit('setSnackbar', {show: true, type: 'success'});
                        });

                        this.snackbar = true;
                    } else if (this.$router.currentRoute.name == 'counselorProfile') {
                        console.log('admin is saving', this.userInfo);

                        let data = {
                            "name": this.userInfo.firstname,
                            "family": this.userInfo.lastname,
                            "mobile": this.userInfo.cel,
                            "timezone": this.userInfo.tz
                        };

                        if (this.newImage != null) data.avatar = this.newImage;

                        this.$store.dispatch('AdminSaveCounselorProfile', {
                            userid: this.$router.currentRoute.params.counselorId,
                            data
                        }).then(() => {
                            this.snackbar = true;
                            this.type = 'success'
                            this.$store.commit('setSnackbar', {show: true, type: 'success'});
                        });
                    } else if (this.$router.currentRoute.name == 'addCounselors') {


                        if (this.userInfo.password == this.confirmPassword) {


                            let data = {
                                name: this.userInfo.firstname,
                                family: this.userInfo.lastname,
                                mobile: this.userInfo.cel,
                                email: this.userInfo.email,
                                speciality: this.userInfo.speciality,
                                timezone: this.userInfo.tz,
                                password: this.userInfo.password,
                                password_confirmation: this.confirmPassword,
                                // description: this.userInfo.description,
                                // resume: this.userInfo.resume,
                            };

                            if (this.newImage != null) data.avatar = this.newImage;

                            EventBus.$emit('setLoading', true);
                            this.$store.dispatch('addNewCounselor', data).then((resp) => {
                                console.log("--------------------resp--------------------");
                                console.log(resp);
                                console.log("--------------------resp--------------------");
                            }).finally(() => {
                                EventBus.$emit('setLoading', false);
                            });
                        } else {
                            // EventBus.$emit('notify', 'red', undefined, 'کلمه عبو');
                        }
                    }
                }
            },
            resetProfile() {
                console.log('Reseting');
                this.userInfo = {};
            },
            addToResume() {

                if (!this.admin) {

                    if (this.mode == 'add') {
                        let item = {
                            title: this.newItem.title
                        };
                        EventBus.$emit('setLoading', true);
                        this.$store.dispatch('counselorAddToResume', item).then(() => {
                            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                            this.getResume();
                            this.newItem.id = '';
                            this.newItem.title = '';
                        }).finally(() => {
                            EventBus.$emit('setLoading', false);
                        });
                    } else if (this.mode == 'edit') {
                        let data = {
                            id: this.newItem.id,
                            payload: {
                                title: this.newItem.title
                            }
                        };

                        EventBus.$emit('setLoading', true);
                        this.$store.dispatch('counselorEditResumeItem', data).then(() => {
                            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                        }).finally(() => {
                            this.model = 'add';
                            this.getResume();
                            EventBus.$emit('setLoading', false);
                        });
                    }

                    // if (!this.userInfo.hasOwnProperty('resume')) this.userInfo.resume = [];
                    // if (this.resume !== '') this.userInfo.resume.unshift(this.resume);
                    // this.resume = '';
                } else {

                    if (this.mode == 'add') {
                        let data = {
                            doctorId: this.counselorId,
                            payload: {
                                title: this.newItem.title
                            }
                        };
                        EventBus.$emit('setLoading', true);
                        this.$store.dispatch('adminCounselorAddToResume', data).then(() => {
                            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                            this.getResume();
                            this.newItem.id = '';
                            this.newItem.title = '';
                        }).finally(() => {
                            EventBus.$emit('setLoading', false);
                        });
                    } else if (this.mode == 'edit') {
                        let data = {
                            itemId: this.newItem.id,
                            doctorId: this.counselorId,
                            payload: {
                                title: this.newItem.title
                            }
                        };

                        EventBus.$emit('setLoading', true);
                        this.$store.dispatch('adminCounselorEditResumeItem', data).then(() => {
                            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                        }).finally(() => {
                            this.model = 'add';
                            this.getResume();
                            EventBus.$emit('setLoading', false);
                        });
                    }
                }
            },
            selToDelete(item) {
                if (this.notAsk) {
                    this.removeResume(item);
                } else {
                    this.dialogDeleteConfirm = true;
                    this.delResume = item;
                }
            },
            removeResume(item) {
                if (!this.admin) {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('counselorRemoveFromResume', item.id).then((resp) => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت حذف شد.');
                        this.getResume();
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    });
                    this.dialogDeleteConfirm = false;

                } else {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('adminCounselorRemoveFromResume', {
                        doctorId: this.counselorId,
                        itemId: item.id
                    }).then((resp) => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت حذف شد.');
                        this.getResume();
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    });
                    this.dialogDeleteConfirm = false;
                }

            },
            editResume(item) {
                this.mode = 'edit';
                this.newItem = item;
            },
            onFileSelected(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.createImage(files[0]).then(resp => {
                    this.avatarImg = resp;
                    this.newImage = resp;
                    this.userInfo.avatar = resp;
                });
            },
            createImage(file) {
                return new Promise((resolve) => {
                    let reader = new FileReader();
                    reader.onload = e => {
                        resolve(e.target.result);
                    };
                    reader.readAsDataURL(file);
                });
            },
        }
    }
</script>

<style>
    .v-dialog {
        overflow-y: hidden;
    }
</style>

<style scoped>
    .addImg {
        position: absolute;
        bottom: 0
    }

    @media screen and (max-width: 768px) {
        .addImg {
            right: 150px;
            bottom: 0
        }
    }

    @media screen and (max-width: 500px) {
        .addImg {
            right: auto;
            bottom: 0;
        }
    }
</style>
