<template>
<!--  <v-container fluid class="d-flex h-100 grey lighten-5 bg-login">-->
<!--    <v-row class="align-center justify-center" justify="center" align="center" style="height:100%;">-->

<!--      <v-col cols="11" sm="9" lg="4" md="6">-->
<!--        <v-card class="text-center flex-center">-->
<!--          <v-card-text class="pb-0 pt-2 justify-center">-->
<!--            <v-img class="mx-auto" :src="require('@/assets/simialogopng.png')" height="63" width="110"></v-img>-->
<!--          </v-card-text>-->
<!--          <v-window v-model="window" :reverse="this.$store.getters.isRTL">-->
<!--            <v-window-item :value="1">-->
<!--              <LoginComponent @changeWindow="updateWindow" :email="email"></LoginComponent>-->
<!--            </v-window-item>-->
<!--            <v-window-item :value="2">-->
<!--              <SignupComponent @changeWindow="updateWindow" @successfullyRegistered="fillLogin"></SignupComponent>-->
<!--            </v-window-item>-->
<!--          </v-window>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--  </v-container>-->
  <LoginRegisterModalPage :defaultWindow="1"></LoginRegisterModalPage>
</template>
<script>
/*eslint-disable*/
import LoginComponent from '@/components/LoginComponentHome.vue'
import SignupComponent from '@/components/SignupComponentHome.vue'
import LoginRegisterModalPage from '@/components/LoginRegisterModalPage';


export default {
  components: {
    LoginComponent,
    SignupComponent,
    LoginRegisterModalPage
  },
  data() {
    return {
      inviter: null,
      email: null,
      window: 1,
      pageHeight: 'auto',
      isMobile: false
    }
  },
  computed: {
    form() {
      return {
        // name: this.name,
        // address: this.address,
        // city: this.city,
        // state: this.state,
        // zip: this.zip,
        // country: this.country,
        // email: this.email
      }
    },
  },
  watch: {
    pageHeight() {

    },
  },
  created() {
    // if (this.$route.query.hasOwnProperty('inviter')) {
    //     this.$store.dispatch('setInviter', this.$route.query.inviter);
    //     // this.setCookie('inviter', this.$route.query.inviter, 10);
    //     // this.inviter = this.$route.query.inviter;
    // }

    window.addEventListener("resize", this.myEventHandler);

    if (window.innerWidth < 500) {
      this.isMobile = true;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  methods: {
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;";
    },
    fillLogin() {
      this.window = 1;
    },
    myEventHandler() {
      this.pageHeight = window.innerHeight - 100;

      if (window.innerWidth < 500) {
        this.isMobile = true;
      }
    },
    updateWindow(variable) {
      this.window = variable
    }

  },
  mounted() {
    this.pageHeight = window.innerHeight - 100;
  }
}
</script>
<style>
/*.yekan-font {*/
/*font-family: YekanWeb !important;*/
/*}*/
.big-banner {
  font-size: 3em;
}

.bg-login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./../assets/images/login-bg.jpg");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  color: white;
  padding: 0;
}

.selected-border {
  border: 1px solid rgba(0, 0, 150, .2);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
</style>