<template>
  <div>
    <HeaderLogoArea/>
    <div class="heroHeader">
      <p class="font-weight-bold" style="font-size: 14pt">
        تو دوست خوبی هستی!
      </p>
      <p>
        اگر دوست تو سوگوار، مضطرب یا افسرده است، یا در رابطه با شریک زندگی‌اش مشکل دارد، یا به هر دلیلی احساس می‌کنی نیازمند کمک مشاور است، با ارسال این هدیه او را در برداشتن قدم اول کمک کن.
      </p>
      <div class="d-flex justify-space-around">
        <v-btn class="shareBtn" elevation="0" @click="share">
          <img src="../../../../assets/zeroPayment/gift.png" width="20" class="ml-4"  alt="">
          برای ارسال هدیه کلیک کن
        </v-btn>
      </div>
    </div>

    <br>
    <div class="handArea">

      <img src="@/assets/zeroPayment/invite.png" width="100%" alt="">
    </div>
    <div class="note">
      <p class="titleArea" style="font-size: 14px;">
        به این نکات مهم دقت کن:
      </p>
      <div class="contentArea">
        <div class="item">
          <div class="d-flex">
            <div>
              <v-icon style="margin-top: -14px" small >mdi-check</v-icon>
            </div>
            <p style="font-size: 12px;">
              هدیه‌ای که به دوستت می‌دهی، کمک گرفتن از یک مشاور متخصص و باتجربه است.
            </p>
          </div>
        </div>
        <div class="item">
          <div class="d-flex">
            <div>
              <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
            </div>
            <p style="font-size: 12px;">
              ما برای دوستت ۲ هدیه ۲۵دلاری در نظر گرفته‌ایم که در دو جلسه اول مشاوره قابل استفاده
              است. (به عبارتی با پرداخت هزینه یک جلسه، دو جلسه مشاوره برای او برگزار خواهد شد.)
            </p>
          </div>
        </div>

        <div class="item">
          <div class="d-flex">
            <div>
              <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
            </div>
            <p style="font-size: 12px;">
              این جلسه با مشاوران اصلی سیمیاروم برگزار می‌شود، یعنی بهترین مشاوران فارسی زبان که
              رزومه تمام آن‌ها را می‌توانی در صفحه مشاوران سیمیاروم ببینی.
            </p>
          </div>
        </div>
        <div class="item">
          <div class="d-flex">
            <div>
              <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
            </div>
            <p style="font-size: 12px;">
              هزینه‌های جلسه به طور کامل به مشاور شما پرداخت می‌شود و ایشان هیچ اطلاعی از تخفیف
              جلسه  تو ندارد، بنابراین کوچک‌ترین تغییری در کیفیت مشاوره ایجاد نمی‌شود.
            </p>
          </div>
        </div>
        <div class="item">
          <div class="d-flex">
            <div>
              <v-icon style="margin-top: -14px" small>mdi-check</v-icon>
            </div>
            <p style="font-size: 12px;">
              اولین جلسه درمان اغلب صرف شنیدن حرف‌های مراجعه کننده می‌شود تا مشاور بتواند مشکل را شناسایی و طرح درمان را
              مشخص کند. پس توصیه می‌کنیم حتما در دو جلسه اول شرکت کنی.

            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <v-btn text small @click="refreshPage">بازگشت</v-btn>
    </div>
    <div class="footer">
    </div>
  </div>
</template>

<script>
import HeaderLogoArea from "@/components/campaign/referal-campaign/headerLogoArea";

export default {
  components: {HeaderLogoArea},
  methods: {
    refreshPage() {
      window.location.reload()
    },
    share() {
      this.$emit('stepSwitcher', 1)
    }
  }
}
</script>

<style scoped>
  .heroHeader {
    padding: 30px 50px 40px 50px ;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
  }
  .handArea{
    margin-top: -50px !important;
  }
  .shareArea{
    font-size: 10px;
    padding: 50px 50px 50px 50px ;
    font-weight: 300;
    line-height: 20px;
  }
  .contentArea .item p{
    font-size: 10px;
    font-weight: 300;
  }

  .footer {
    margin-top: 10px !important;
  }

  .inputArea {
    border: solid 1px #707070 !important;
    border-radius: 6px;
    width: 180px;
    height: 35px;
    padding: 0px;
  }
  .note {
    padding: 10px;
    margin: 35px;
  }

  .note .titleArea {
    font-size: 10px;
    text-align: center;
  }
  .note {
    background: rgba(230, 230, 230, 0.5);
    border-radius: 10px;
  }
.handArea{
  padding-bottom: 50px;
}

.handArea{
  background: url("../../../../assets/zeroPayment/pattern.png");
  background-size: cover;
  background-position: center;
}

.footer {
  background: #39b787;
  width: 100%;
  height: 30px;
  margin-top: 60px;
}
.shareBtn{
  width: 230px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #39b787 !important;
  font-size: 14px !important;
  text-align: center ;
  padding-top: 7px ;
  color: white !important;
}
</style>