<template>
  <div class="fill-height">
    <v-window v-model="forMedStepper" touchless>
      <v-window-item :value="0">
        <LandingForMe @stepperHandler="stepperHandler($event)"/>
      </v-window-item>
      <v-window-item :value="1" class="fill-height">
        <template v-if="forMedStepper === 1">
          <div class="referralCampaign-tellFromIran">
            <NumberFromIranForMe @stepperHandler="stepperHandler($event)"/>
          </div>
        </template>
      </v-window-item>
      <v-window-item :value="2">
        <template v-if="forMedStepper === 2">
          <div class="referralCampaign-registerForMe">
            <RegisterAndGetSessionForMe @stepperHandler="stepperHandler($event)"/>
          </div>
        </template>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
/*eslint-disable*/
import {AsYouType, parsePhoneNumberFromString, parsePhoneNumber} from "libphonenumber-js";
import RegisterAndGetSessionForMe from "@/components/campaign/referal-campaign/forMeSection/registerAndGetSessionForMe";
import LandingForMe from "@/components/campaign/referal-campaign/forMeSection/landingForMe";
import NumberFromIranForMe from "@/components/campaign/referal-campaign/forMeSection/numberFromIranForMe";

export default {
  components: {NumberFromIranForMe, LandingForMe, RegisterAndGetSessionForMe},
  data() {
    return {
      phone: '+98 922 203 6125',
      phoneError: null,
      forMedStepper: 0,
    }
  },
  methods: {
    stepperHandler(val){
      this.forMedStepper = val
    }
  },
  computed: {
    phoneRules() {
      return [
        input => {
          let phone = input.replaceAll(' ', '');
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber) {
            if (!phoneNumber.isValid()) {
              return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
            } else {
              return true;
            }
          } else {
            return this.$t('$vuetify.Notify.signupComponent.phoneRequired');
          }
        }
      ]
    },
  },
  watch: {
    phone() {
      // let p = this.phone;

      // if (this.phone.startsWith('00')) {
      //     this.phone = this.phone.substr(2);
      //     this.phone = '+' + this.phone;
      // }
      //
      // if (this.phone.startsWith('098')) {
      //     this.phone = this.phone.substr(1);
      //     this.phone = '+' + this.phone;
      // }
      //
      // if (this.phone.startsWith('09')) {
      //     this.phone = this.phone.substr(1);
      //     this.phone = '+98' + this.phone;
      // }

      // if (this.phone.startsWith(0)) {
      //   this.phone = this.phone.replace(0, '');
      // }
      //
      // let x = new AsYouType().input(this.selectedCountry.code + this.phone);
      // x = x.replace(this.selectedCountry.code, '');
      // this.phone = x;


      if (this.phone.startsWith('00')) {
        this.phone = this.phone.substr(2);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('098')) {
        this.phone = this.phone.substr(1);
        this.phone = '+' + this.phone;
      }

      if (this.phone.startsWith('09')) {
        this.phone = this.phone.substr(1);
        this.phone = '+98' + this.phone;
      }

      this.phone = new AsYouType().input(this.phone);
    }
  }

}
</script>

<style scoped>

</style>