/*eslint-disable*/
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import {i18n} from './plugins/vuetify';
import Axios from "axios";
import VueGtm from 'vue-gtm';
import VueSocialSharing from 'vue-social-sharing';

// import i18n from './i18n';
// import VueTour from 'vue-tour';
// import VueSocketIO from 'vue-socket.io';
// require('vue-tour/dist/vue-tour.css');
// Vue.use(VueTour);
// import VueApexCharts from 'vue-apexcharts'
// Vue.use(VueApexCharts)
// Vue.component('apexchart', VueApexCharts)

import VueSocketIOExt from 'vue-socket.io-extended';
import {io} from 'socket.io-client';
// import LoadScript from "vue-plugin-load-script";

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');


const socket = io('https://app.simiaroom.com:3000', {
    query: `token=${store.getters.getBcCode}`
});

Vue.use(VueSocketIOExt, socket);

Vue.use(require('vue-moment-jalaali'));
// Vue.use(LoadScript)

Vue.use(VueSocialSharing);
Vue.use(VueGtm, {
    // id:'GTM-WBKRP9P',
    enabled: true,
    loadScript: true
});

Vue.config.productionTip = false;

const activeCycle = store.getters.hasValidActiveCycle;
if (activeCycle) {
    Axios.defaults.headers["X-USER-CYCLE"] = activeCycle._id;
}

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
