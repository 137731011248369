// src/plugins/vuetify.js
/*eslint-disable*/
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import messages from './languageLabels.js'
// import {ajaxPost, ajaxGet} from '../utilities.js';


Vue.use(Vuetify)
Vue.use(VueI18n)

let rtl;
let localeLang = localStorage.getItem('local');
// let direction = localStorage.getItem('direction');

if (localeLang != null) {
    rtl = localeLang === 'fa';
} else {
    localeLang = 'fa';
    rtl = true;
}

// if (direction != null) {
//     console.log('NOT NULL');
//     rtl = (direction == 'rtl') ? true : false;
//
// } else {
//     rtl = true;
// }

// console.log('default language');
// console.log(localizationSettings.defaultLanguage);
// Create VueI18n instance with options
export const i18n = new VueI18n({
    // locale: localizationSettings.defaultLanguage, // set locale
    locale: localeLang || 'fa', // set locale
    messages, // set locale messages
});

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    rtl: rtl,
    theme: {
        themes: {
            light: {
                indigo: '#23214b',
                purple: '#34316E',
                'purple-light': '#9998b6',
                'purple-lighten': '#e1e0e9',
                'gray-light': '#f1f2f7',
                'blue-lighten': '#d9f2fd',
                'gray-lighten': '#dddde0',
                'orange-lighten': '#feede1',
                'orange': '#f58634',
                warning: '#f58634',
                'gray': '#1e1e2d',
                'blue': '#03a9f4',
                primary : '#34316E',
                'gray-darker' : '#9e9e9e',
                'red': '#ff4057'
            },
        },
    }
})